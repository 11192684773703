import React from 'react';

import { Container } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import NewSettingsForm from 'src/sections/admin/settingsNew';

function SettingsPage() {
  const settings = useSettingsContext();
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="Settings"
        links={[{ name: 'Dashboard', href: paths.dashboard.root }, { name: 'Settings' }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {/* <SettingsForm /> */}
      <NewSettingsForm />
    </Container>
  );
}

export default SettingsPage;
