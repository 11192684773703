/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import Stack from '@mui/material/Stack';
import { Box, Button, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import bionium from 'src/assets/logo/Bionium.png';
import coin from 'src/assets/logo/coin-2bionium.png';
import fileIcon from 'src/assets/other/file-white.png';
// import bioniumLogo from 'src/assets/logo/Bionium Logo.jpg';

// ----------------------------------------------------------------------

export default function BioniumCard({ settingsData, MemberProfileDetails }) {
  const isMobile = useResponsive('down', 'md');
  const navigate = useNavigate();
  const role = useGetRoles()?.role;
  // console.log(MemberProfileDetails)

  const handleSinglePageView = () => {
    navigate(paths?.dashboard?.bionium_singleView);
  };
  return (
    <Stack
      spacing={4}
      // sx={{ p: 2 }}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      // border='2px solid orange'
      borderRadius="16px"
      backgroundColor="#0D0902"
      p={1.5}
      width="100%"
    >
      <img
        src={bionium}
        alt="bioniumLogo"
        style={{
          width: 'auto',
          height: 'auto',
          borderRadius: '16px',
          objectFit: 'contain',
          alignItems: 'center',
          justifyContent: 'center',
          // border:'2px solid orange',
          alignSelf: 'center',
          padding: '1%',
        }}
      />

      <Box marginTop="2%" marginBottom="1%" alignSelf="center">
        <Typography
          variant="h4"
          sx={{
            background:
              'linear-gradient(93.99deg, #B70047 -6.14%, #B13F90 18.08%, #583FC5 40.66%, #EEB400 90.53%, #FFFFFF 108.6%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 'bold',
            fontSize: '24px',
            // border: "2px solid red",
            width: isMobile ? 'auto' : 'fit-content',
            textAlign: isMobile && 'center',
          }}
        >
          {isMobile ? (
            <>
              Bionium AI :<br />
              <Typography
                sx={{
                  fontSize: '16px',
                }}
                // color='#FFDD74'
              >
                Healthcare currency
              </Typography>
            </>
          ) : (
            'Bionium AI : Healthcare currency'
          )}
        </Typography>
        {!isMobile && (
          <Typography variant="body2" color="#FFFFFF" fontSize="12px">
            BIONIUM AI Coin is a pioneering digital currency revolutionizing healthcare
            transactions. Supported by leading Al hospital equipment manufacturers, this coin
            facilitates seamless payments for a range of healthcare services, including dental,
            wellness, cosmetic care, and insurance. BIONIUM AI Coin simplifies the payment process,
            making it easier and more convenient to manage healthcare expenses with modern
            technology.
          </Typography>
        )}
        <Stack pt={1}>
          <Button
            onClick={handleSinglePageView}
            color="inherit"
            variant="outlined"
            sx={{
              width: 'fit-content',
              color: '#FFFFFF',
            }}
          >
            <CardMedia
              component="img"
              src={fileIcon}
              sx={{
                objectFit: 'contain',
                height: '20px',
                width: '20px',
                mr: 0.5,
              }}
            />{' '}
            Whitepaper
          </Button>
        </Stack>
      </Box>

      <Box
        width={isMobile ? '100%' : '70%'}
        height={isMobile ? '75px' : '177px'}
        backgroundColor="#141414"
        borderRadius="16px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        // border='2px solid orange'
        alignSelf="center"
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          flexDirection={isMobile && 'row'}
          spacing={1}
        >
          <Stack flexDirection="row" spacing={1} alignItems="center">
            {/* <Iconify
              icon="healthicons:coins"
              width="28px"
              height="28px"
              style={{ color: 'gold' }}
            /> */}

            <img
              src={coin}
              alt="bioniumLogo"
              style={{
                width: '28px',
                height: '28px',
                objectFit: 'cover',
              }}
            />

            {!isMobile && (
              <Typography
                sx={{
                  background:
                    'linear-gradient(93.99deg, #B70047 -6.14%, #B13F90 18.08%, #583FC5 40.66%, #EEB400 90.53%, #FFFFFF 108.6%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontWeight: 'bold',
                  fontSize: '12px',
                }}
                // variant="h6"
              >
                Bionium AI
              </Typography>
            )}
          </Stack>
          <Typography variant="h5" color="#FFFFFF">
            {role === 'admin'
              ? settingsData?.bionium_total || 0
              : MemberProfileDetails?.member?.bionium_coin || 0}{' '}
            Tokens
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
}

BioniumCard.propTypes = {
  settingsData: PropTypes.object,
  MemberProfileDetails: PropTypes.object,
};
