import React from 'react';

import { Container } from '@mui/material';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import W2WTransferListAdmin from './AdminW2WTransferPage';
import W2WTransferListMember from './MemberW2WTransferPage';

function AllW2WTransferMember() {
  const settings = useSettingsContext();
  const { role } = useGetRoles();
  console.log(role);

  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="W2W Transfers" />
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {role === 'admin' ? <W2WTransferListAdmin /> : <W2WTransferListMember />}
      </Container>
    </>
  );
}

export default AllW2WTransferMember;
