import PropTypes from 'prop-types';

import {
  Box,
  Tab,
  Tabs,
  Stack,
  alpha,
  useTheme,
  TextField,
  Autocomplete,
  InputAdornment,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function AdminW2WToolbar({
  onSearch,
  date,
  setDate,
  setPage,
  optionBeneficiaryMembers,
  handleAutocompleteChangeBeneficiary,
  handleTextFieldChangeBeneficiary,
  optionMembers,
  handleAutocompleteChangeMember,
  handleTextFieldChangeMember,
  sort,
  setSort,
  listCounts,
}) {
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();
  const TABS = [
    {
      value: '',
      label: 'All',
      color: 'default',
      count: listCounts?.allWalletTransferCount,
    },
    {
      value: 'PROCESSING',
      label: 'Pending',
      color: 'warning',
      count: listCounts?.processingWalletTransferCount,
    },
    {
      value: 'SUCCESS',
      label: 'Success',
      color: 'success',
      count: listCounts?.successWalletTransferCount || 0,
    },
    {
      value: 'FAILED',
      label: 'Failed',
      color: 'error',
      count: listCounts?.failedWalletTransferCount,
    },
  ];

  return (
    <>
      <Box mb={2}>
        <Tabs
          value={sort}
          onChange={(e, newValue) => {
            setSort(newValue);
            setPage();
          }}
          sx={{
            px: 2.5,
            boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {TABS?.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              iconPosition="end"
              icon={
                <Label
                  variant={((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'}
                  color={tab.color}
                >
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>
      </Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <TextField
          sx={{ width: '100%', pt: isMobile && 1 }}
          onChange={(e) => {
            onSearch(e.target.value);
            setPage();
          }}
          placeholder="Search by Transfer ID"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        <Stack width="70%">
          <Autocomplete
            id="country-select-demo"
            sx={{ width: '100%' }}
            options={optionMembers}
            autoHighlight
            // size="small"
            // className="small-select"
            getOptionLabel={(option) =>
              `${option?.label} ${option?.member_id ? '-' : ''} ${option?.member_id ?? ''} ${option?.name ? '-' : ''} ${option?.name ?? ''} ${option?.username ? '-' : ''} ${option?.username ?? ''}`
            }
            onChange={handleAutocompleteChangeMember}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Member"
                onChange={handleTextFieldChangeMember}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-member',
                }}
              />
            )}
          />
        </Stack>
        <Stack width="70%">
          <Autocomplete
            id="country-select-demo"
            sx={{ width: '100%' }}
            options={optionBeneficiaryMembers}
            autoHighlight
            // size="small"
            // className="small-select"
            getOptionLabel={(option) =>
              `${option?.label} ${option?.member_id ? '-' : ''} ${option?.member_id ?? ''} ${option?.name ? '-' : ''} ${option?.name ?? ''} ${option?.username ? '-' : ''} ${option?.username ?? ''}`
            }
            onChange={handleAutocompleteChangeBeneficiary}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Beneficiary Member"
                onChange={handleTextFieldChangeBeneficiary}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-member',
                }}
              />
            )}
          />
        </Stack>
        <Stack direction="row" width="100%">
          <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', minWidth: isMobile && 150, mr: 2 }}
            size="medium"
            type="date"
            variant="outlined"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                from: e.target.value,
              });
              setPage();
            }}
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%', minWidth: isMobile && 147 }}
            size="medium"
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                to: e.target.value,
              });
              setPage();
            }}
          />
        </Stack>
      </Stack>
      {/* <Stack direction="row" alignItems="center" spacing={2}>
        
      </Stack> */}
    </>
  );
}

AdminW2WToolbar.propTypes = {
  onSearch: PropTypes.any,
  date: PropTypes.any,
  setDate: PropTypes.any,
  setPage: PropTypes.any,
  sort: PropTypes.any,
  setSort: PropTypes.any,
  listCounts: PropTypes.any,
  optionMembers: PropTypes.any,
  handleAutocompleteChangeMember: PropTypes.any,
  handleTextFieldChangeMember: PropTypes.any,
  optionBeneficiaryMembers: PropTypes.any,
  handleAutocompleteChangeBeneficiary: PropTypes.any,
  handleTextFieldChangeBeneficiary: PropTypes.any,
};
