import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableRow, TableCell, Typography, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getAllAdminW2WTransfer } from 'src/server/api/W2WTransfer';
import { getAllMembers, getAllBeneficiaryMembersForAdmin } from 'src/server/api/member';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import AdminW2WToolbar from 'src/sections/W2WTransfer/admin/AdminW2WTransferToolbar ';
import AdminW2WTransferTableRow from 'src/sections/W2WTransfer/admin/AdminW2WTransferTableRow';

// ----------------------------------------------------------------------

export default function W2WTransferListAdmin({ memberSingle }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const role = useGetRoles()?.role;
  const router = useRouter();

  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: role === 'admin' ? 25 : 5,
  });

  const confirm = useBoolean();
  // const isMobile = useResponsive('down', 'md');
  const [sort, setSort] = useState('');
  const [date, setDate] = useState('');

  const {
    loading,
    allW2WTransfer,
    listCount,
    beneficiaryMembersList,
    membersList,
    // memberWalletAddress: memberWalletAddressDetails,
    // memberWalletAddressList,
  } = useSelector((state) => ({
    loading: state.W2WTransfer.loading,
    allW2WTransfer: state?.W2WTransfer?.w2wTransferAdmin,
    listCount: state?.W2WTransfer?.w2wTransferAdmin?.walletTransferCounts,
    memberProfileDetails: state.member.memberProfileDetails.member,
    settingsData: state.settings.settingsDetails,
    memberWalletAddress: state.walletAddress?.walletAddresses?.memberWalletAddresses,
    memberWalletAddressList: state.withdrawalRequest?.memberWalletAddressList,
    beneficiaryMembersList: state.member.beneficiaryMembersForAdmin?.members,
    membersList: state.member.members?.members,
  }));

  const identifier = 'withdrawal_request';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
  const [searchText, setSearchText] = useState();
  const [tableData, setTableData] = useState([]);
  const totalPage = allW2WTransfer?.total;

  const [selectedBeneficiaryMember, setSelectedBeneficiaryMember] = useState('');
  const [searchBeneficiaryMember, setSearchBeneficiaryMember] = useState('');

  const [selectedMember, setSelectedMember] = useState('');
  const [searchMember, setSearchMember] = useState('');

  const TABLE_HEAD = [
    { id: 'created', label: 'Date' },
    { id: 'member_id', label: 'Transfer ID' },
    { id: 'member_name', label: 'Member Details' },
    { id: 'name', label: 'Beneficiary Details' },
    { id: 'amount', label: 'Amount' },
    // { id: 'particulars', label: 'Particulars' },
    // { id: 'remarks', label: 'Remarks' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: '' },
  ];

  // const handleChangeSortOrder = (value) => {
  //   setSortOrder(value);
  // };

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: storedRowsPerPage || table.rowsPerPage,
      sort,
      date,
      search: searchText || '',
      dispatch,
      beneficiary_member: selectedBeneficiaryMember?.value,
      member: selectedMember?.value,
      // member: memberSingle,
      // sort_order: sortOrder,
    };

    dispatch(getAllAdminW2WTransfer(credentials));
    // }
  }, [
    date,
    dispatch,
    searchText,
    sort,
    role,
    storedRowsPerPage,
    table.page,
    table.rowsPerPage,
    memberSingle,
    selectedBeneficiaryMember?.value,
    selectedMember?.value,
  ]);
  // console.log(searchText);
  useEffect(() => {
    setTableData(allW2WTransfer?.walletTransfers);
  }, [allW2WTransfer]);

  console.log(allW2WTransfer);
  console.log(tableData);

  // useEffect(() => {
  //   const credentials = {
  //     page: '',
  //     search: '',
  //     limit: 5,
  //     dispatch,
  //   };
  //   if (role !== 'admin') {
  //     dispatch(memberWalletAddress(credentials));
  //   }
  // }, [dispatch, role]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  // const getWidth = () => {
  //   if (memberSingle) {
  //     return '100%';
  //   }
  //   if (isMobile) {
  //     return '100%';
  //   }
  //   return '90%';
  // };

  const setPage = () => {
    table.setPage(0);
  };

  useEffect(() => {
    const data = {
      page: '',
      search: searchBeneficiaryMember,
      dispatch,
      limit: 10,
      // sort: 'VERIFIED',
    };
    dispatch(getAllBeneficiaryMembersForAdmin(data));
  }, [dispatch, role, searchBeneficiaryMember]);

  const optionBeneficiaryMembers = [
    { label: 'All', value: null },
    ...(beneficiaryMembersList || []).map((item) => ({
      name: item?.name,
      member_id: item?.member_id,
      username: item?.username,
      label: `${item?.name} - ${item?.member_id}`,
      value: item?._id,
    })),
  ];

  const handleTextFieldChangeBeneficiaryMember = (event) => {
    setSearchBeneficiaryMember(event.target.value);
    setPage();
  };

  const handleAutocompleteChangeBeneficiaryMember = (e, newValue) => {
    setSelectedBeneficiaryMember(newValue || '');
    setPage();
  };
  useEffect(() => {
    const data = {
      page: '',
      search: searchMember,
      dispatch,
      limit: 10,
      // sort: 'VERIFIED',
    };
    dispatch(getAllMembers(data));
  }, [dispatch, role, searchMember]);

  const optionMembers = [
    { label: 'All', value: null },
    ...(membersList || []).map((item) => ({
      name: item?.name,
      member_id: item?.member_id,
      username: item?.username,
      label: `${item?.name} - ${item?.member_id}`,
      value: item?._id,
    })),
  ];

  const handleTextFieldChangeMember = (event) => {
    setSearchMember(event.target.value);
    setPage();
  };

  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedMember(newValue || '');
    setPage();
  };

  return (
    <>
      <CustomBreadcrumbs
        heading="W2W Transfers"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'W2W Transfer',
          },

          {
            name: 'List',
          },
        ]}
        // action={
        //   <Button
        //     variant="outlined"
        //     color="success"
        //     onClick={() => setOpenActivateMemberDialog(true)}
        //   >
        //     Activate New Member
        //   </Button>
        // }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack
        spacing={3}
        // width={isMobile ? '100%' : '80%'}
        // direction={role !== 'admin' ? 'row' : 'column'}
        // direction={role === 'admin' || (role !== 'admin' && isMobile) ? 'column' : 'row'}
        // width={
        //   role === 'admin'
        //     ? getWidth()
        //     : '100%                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         '
        // }
      >
        <Card variant="outlined" sx={{ boxShadow: 'unset', flex: 7 }}>
          <Stack pt={2} px={2}>
            <Typography variant="h6">Transfer History</Typography>
          </Stack>

          <Stack mx={2} mb={2} mt={1}>
            <AdminW2WToolbar
              sort={sort}
              setSort={setSort}
              listCounts={listCount}
              setPage={setPage}
              onSearch={handleSearch}
              date={date}
              setDate={setDate}
              optionBeneficiaryMembers={optionBeneficiaryMembers}
              handleAutocompleteChangeBeneficiary={handleAutocompleteChangeBeneficiaryMember}
              handleTextFieldChangeBeneficiary={handleTextFieldChangeBeneficiaryMember}
              optionMembers={optionMembers}
              handleAutocompleteChangeMember={handleAutocompleteChangeMember}
              handleTextFieldChangeMember={handleTextFieldChangeMember}
            />
          </Stack>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {(tableData || []).map((row, index) => (
                      <AdminW2WTransferTableRow
                        index={index}
                        page={table.page + 1}
                        rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                        key={row.id}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        loading={loading}
                        colSpan={TABLE_HEAD?.length}
                        memberSingle={memberSingle}
                      />
                    ))}
                  </TableBody>
                )}

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            customRowsPerPage={role === 'admin' && !memberSingle && 25}
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

W2WTransferListAdmin.propTypes = {
  memberSingle: PropTypes.string,
};
