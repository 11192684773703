/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

// @mui
import {
  Stack,
  Dialog,
  Button,
  CardMedia,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import coin from 'src/assets/other/Coin.png';

CreateMemberAccountModalSuccessDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function CreateMemberAccountModalSuccessDialog({ open, setOpen }) {
  const isMobile = useResponsive('down', 'md');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="add-member-success-modal"
      >
        <DialogContent
          className="crud-modal"
          sx={{
            minWidth: isMobile ? '200px' : '444px',
            padding: '0px',
          }}
        >
          <Stack textAlign="center" spacing={2} pt={3}>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <CardMedia
                component="img"
                src={coin}
                sx={{
                  height: '80px',
                  width: '90px',
                  objectFit: 'contain',
                }}
              />
            </Stack>
            <Stack>
              <Typography fontSize="24px" fontWeight={600} color="#212B36" lineHeight="28px">
                Member creation successfully initiated.{' '}
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                color="text.disabled"
                pt={2}
                px={6}
                pb={3}
              >
                Please wait a few moments for the process to complete and reflect in the system.
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack>
            <Button
              fullWidth
              sx={{
                backgroundColor: '#007867',
                color: 'white',
                py: 1,
                '&:hover': {
                  backgroundColor: '#007867',
                },
              }}
              onClick={handleClose}
            >
              Got it
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}
