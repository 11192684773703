import * as React from 'react';
import PropTypes from 'prop-types';

// @mui
import { Box, Card, Stack, Button, TextField, Typography, Autocomplete } from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';

import { decimalLimit } from 'src/server/api/http';

import { useSettingsContext } from 'src/components/settings';

export const FromTo = ({ data }) => {
  const { themeMode } = useSettingsContext();

  const {
    buttons,
    fromActiveButton,
    toActiveButton,
    handleFromButton,
    handleToButton,
    selectedFrom,
    handleChangeSearchFrom,
    handleAutocompleteChangeFrom,
    handleSearchChangeFrom,
    handleRenderOptionsFrom,
    selectedTo,
    handleChangeSearchTo,
    handleAutocompleteChangeTo,
    handleSearchChangeTo,
    handleRenderOptionsTo,
  } = data;

  return (
    <Stack px={2} pb={2}>
      <Stack py={2}>
        <Typography variant="caption" color="text.primary" fontWeight={700}>
          From
        </Typography>
        <Stack direction="row" spacing={1} pt={1}>
          {buttons?.map((i, k) => (
            <Button
              key={k}
              sx={{
                borderRadius: '20px',
                fontWeight: 400,
                fontSize: 11,
                backgroundColor: fromActiveButton === i?.value ? '#007867' : '',
                color: themeMode && fromActiveButton === i?.value ? 'white' : 'dark',
                '&:hover': {
                  backgroundColor: fromActiveButton === i?.value ? '' : '#007867',
                  color: 'white',
                },
              }}
              variant={fromActiveButton === i?.value ? 'contained' : 'outlined'}
              onClick={() => handleFromButton(i?.value)}
            >
              {i?.label}
            </Button>
          ))}
        </Stack>
        <Stack pt={1.5}>
          <Autocomplete
            id="country-select-demo1"
            options={handleRenderOptionsFrom()}
            autoHighlight
            size="medium"
            onFocus={handleChangeSearchFrom}
            getOptionLabel={(option) => option.label}
            // getOptionLabel={(option) =>
            //   `${option.label} | ${option.member_id || ''} | ${option.username || ''} | ${option.email || ''} | ${option.phone || ''}`
            // }
            filterOptions={(options, state) => {
              const inputValue = state.inputValue.toLowerCase();
              return options.filter((option) =>
                [
                  option?.label,
                  option?.member_id,
                  option?.username,
                  option.email,
                  option.phone,
                ].some((attr) => attr?.toLowerCase().includes(inputValue))
              );
            }}
            onChange={handleAutocompleteChangeFrom}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option?.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={fromActiveButton === 'MEMBER' ? 'Member' : 'Company'}
                onChange={(e) => handleSearchChangeFrom(e.target.value)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Stack>
        {selectedFrom?.value ? (
          <Stack pt={1}>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: '#00A76F14',
                px: 1,
                py: '2px',
                borderRadius: '6px',
                boxShadow: 'none',
              }}
            >
              <Typography color="#118D57" variant="body2" fontSize={13}>
                BALANCE
                <Typography
                  component="span"
                  pl={0.5}
                  color="text.primary"
                  fontWeight={500}
                  fontSize="13px"
                >
                  {selectedFrom?.wallet_balance?.toFixed(decimalLimit)} {USDT}
                </Typography>
              </Typography>
            </Card>
          </Stack>
        ) : (
          ''
        )}
      </Stack>
      <Stack pt={1}>
        <Typography variant="caption" color="text.primary" fontWeight={700}>
          To
        </Typography>
        <Box display="flex" gap={1} pt={1}>
          {buttons.map((i) => (
            <Box key={i.value}>
              <Button
                sx={{
                  borderRadius: '20px',
                  backgroundColor: toActiveButton === i.value ? '#007867' : '',
                  color: themeMode && toActiveButton === i.value ? 'white' : 'dark',
                  '&:hover': {
                    backgroundColor: toActiveButton === i.value ? '' : '#007867',
                    color: 'white',
                  },
                }}
                variant={toActiveButton === i.value ? 'contained' : 'outlined'}
                onClick={() => handleToButton(i.value)}
              >
                <Typography variant="caption">{i.label}</Typography>
              </Button>
            </Box>
          ))}
        </Box>
        <Stack pt={1.5}>
          <Autocomplete
            id="country-select-demo1"
            options={handleRenderOptionsTo()}
            autoHighlight
            size="medium"
            onFocus={handleChangeSearchTo}
            getOptionLabel={(option) => option.label}
            filterOptions={(options, state) => {
              const inputValue = state.inputValue.toLowerCase();
              return options.filter((option) =>
                [
                  option?.label,
                  option?.member_id,
                  option?.username,
                  option.email,
                  option.phone,
                ].some((attr) => attr?.toLowerCase().includes(inputValue))
              );
            }}
            onChange={handleAutocompleteChangeTo}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={toActiveButton === 'MEMBER' ? 'Member' : 'Company'}
                onChange={(e) => handleSearchChangeTo(e.target.value)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Stack>
        <Stack pt={1}>
          {selectedTo?.value ? (
            <Card
              variant="outlined"
              sx={{
                backgroundColor: '#00A76F14',
                px: 1,
                py: 0.5,
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                boxShadow: 'none',
              }}
            >
              <Typography color="#118D57" fontWeight={500} fontSize="13px">
                BALANCE
              </Typography>
              <Typography pl={0.5} color="text.primary" fontWeight={500} fontSize="13px">
                {selectedTo?.wallet_balance?.toFixed(decimalLimit)} {USDT}
              </Typography>
            </Card>
          ) : (
            ''
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

FromTo.propTypes = {
  data: PropTypes.any,
};
