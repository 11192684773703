/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  Box,
  Grid,
  Paper,
  TableCell,
  CardMedia,
  Typography,
  CircularProgress,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import money from 'src/assets/other/money.png';
import { getAllMembers } from 'src/server/api/member';
import { getAllTransactionsAdmin } from 'src/server/api/transaction';
import { getAllMemberClubsWalletTransaction } from 'src/server/api/memberClub';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import TransactionToolbar from 'src/sections/transaction/TransactionToolbar';
import TransactionTableRow from 'src/sections/transaction/TransactionTableRow';

// ----------------------------------------------------------------------

export default function TransactionMemberSingleView({
  memberSingle,
  filterMember,
  type,
  earnings,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { themeMode } = useSettingsContext();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 5 });
  const confirm = useBoolean();
  const role = useGetRoles()?.role;
  const [selectedWalletType, setSelectedWalletType] = useState('');

  // console.log('category =>', selectedCategory);

  const [selectedmember, setSelectedmember] = useState();
  const [searchmember, setSearchmember] = useState('');

  const handleTextFieldChangeMember = (event) => {
    setSearchmember(event.target.value);
    setPage();
  };
  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedmember(newValue || '');
    setPage();
  };

  const { loading, transactionList, membersList, memberClubWalletTransaction } = useSelector(
    (state) => ({
      loading: state.transaction.loading,
      transactionList: state.transaction.transactions,
      membersList: state.member.members?.members,
      // MemberProfileDetails: state.member.memberProfileDetails.member,
      memberClubWalletTransaction: state.memberClub.memberClubWalletTransaction,
    })
  );
  // console.log(transactionList);
  const identifier = 'transaction';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [searchText, setSearchText] = useState();
  // const [sort, setSort] = useState('');
  const [date, setDate] = useState('');
  console.log(searchText);
  const [tableData, setTableData] = useState([]);
  const totalPage =
    type?.value === 'MINT' || type?.value === 'TRIP'
      ? memberClubWalletTransaction?.total
      : transactionList?.total;

  const TABLE_HEAD =
    role === 'admin'
      ? [
          { id: 'date', label: 'Date' },
          // { id: 'member', label: 'Member' },
          ...(memberSingle ? [] : [{ id: 'name', label: 'Member' }]),

          { id: 'description', label: 'Description' },
          // { id: 'remarks', label: 'Remarks' },
          { id: 'wallet', label: 'Wallet Type' },
          { id: 'credit', label: 'Credit' },
          { id: 'debit', label: 'Debit' },
        ]
      : [
          { id: 'date', label: 'Date' },
          { id: 'description', label: 'Description' },
          { id: 'wallet', label: 'Wallet' },
          { id: 'credit', label: 'Credit' },
          { id: 'debit', label: 'Debit' },
        ];

  const handleTableHead = () => {
    if (type && Array.isArray(TABLE_HEAD)) {
      return TABLE_HEAD.filter((item) => item?.id !== 'wallet');
    }
    return TABLE_HEAD;
  };

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: storedRowsPerPage || table.rowsPerPage,
      transaction_type: '',
      date,
      dispatch,
      member: memberSingle || selectedmember?.value,
      wallet_type: selectedWalletType?.value || type?.value,
    };
    const memberClubCredentials = {
      page: table.page + 1,
      limit: storedRowsPerPage || table.rowsPerPage,
      date,
      memberClubWalletType: type?.value,
      dispatch,
    };
    if (type?.value === 'MINT' || type?.value === 'TRIP') {
      dispatch(getAllMemberClubsWalletTransaction(memberClubCredentials));
    } else {
      dispatch(getAllTransactionsAdmin(credentials));
    }
  }, [
    date,
    dispatch,
    storedRowsPerPage,
    table.page,
    table.rowsPerPage,
    selectedmember,
    selectedWalletType,
    memberSingle,
    type,
  ]);

  useEffect(() => {
    if (type?.value === 'MINT' || type?.value === 'TRIP') {
      setTableData(memberClubWalletTransaction?.MemberWalletTransactions);
    } else {
      setTableData(transactionList?.walletTransactions);
    }
  }, [transactionList, memberClubWalletTransaction, type?.value]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    if (filterMember) {
      // eslint-disable-next-line no-unused-expressions
      ('');
    } else {
      const credentials = {
        page: '',
        search: searchmember,
        limit: '',
        sort: '',
        date: '',
      };
      if (role === 'admin') {
        dispatch(getAllMembers(credentials));
      }
    }
  }, [role, searchmember, dispatch, filterMember]);

  const optionMember = [
    { label: 'All', value: '' },
    ...(membersList || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const wallet_type = [
    { label: 'Catalyst', value: 'CATALYST' },
    { label: 'Company', value: 'COMPANY' },
    { label: 'Club', value: 'CLUB' },
    { label: 'Connect', value: 'CONNECT' },
    { label: 'Member', value: 'MEMBER' },
    { label: 'Maintenance', value: 'MAINTENANCE' },
    { label: 'Fourth', value: 'FOURTH' },
    { label: 'Incentive', value: 'INCENTIVE' },
  ];

  const optionWalletType = [
    { label: 'All', value: '' },
    ...(wallet_type || []).map((results) => ({
      label: results?.label,
      value: results?.value,
    })),
  ];

  const handleAutocompleteChangeWalletType = (e, newValue) => {
    setSelectedWalletType(newValue || '');
    setPage();
  };

  const setPage = () => {
    table.setPage(0);
  };

  return (
    <>
      {!type?.value && earnings ? (
        <Grid container>
          <Grid item md={12} lg={12} xs={12}>
            <Paper
              variant="outlined"
              sx={{
                backgroundColor: themeMode === 'dark' ? '' : 'rgba(245, 246, 255, 1)',
                borderRadius: '9px',
                p: 2,
                height: 360,
              }}
            >
              <Box>
                <Grid display="flex" alignItems="center" justifyContent="center" pt={4} pb={1}>
                  <CardMedia
                    src={money}
                    sx={{
                      objectFit: 'contain',
                    }}
                    component="img"
                    alt="pointer"
                    height={172}
                    width={265}
                  />
                </Grid>
                <Stack textAlign="center" pt={1} pb={2.5}>
                  <Typography
                    color={themeMode === 'dark' ? '' : 'rgba(33, 43, 54, 1)'}
                    fontSize="20px"
                    fontWeight={600}
                    lineHeight="23.5px"
                  >
                    Let’s grow together
                  </Typography>
                  <Typography
                    pt={0.5}
                    color="rgba(69, 79, 91, 1)"
                    fontSize="14px"
                    lineHeight="18px"
                    fontWeight={400}
                  >
                    Keep exploring opportunities, track your progress, and make the most out
                    <br /> of your journey with us. Your growth is our priority!
                  </Typography>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Stack width="100%">
          <Card variant="outlined">
            {type && (
              <Box p={2}>
                <Typography variant="h6" fontWeight={700}>
                  {type?.label} Earning Transactions
                </Typography>
              </Box>
            )}
            <Stack mx={2} my={3}>
              <TransactionToolbar
                role={role}
                walletTypeSelect={handleAutocompleteChangeWalletType}
                walletTypeOption={optionWalletType}
                onSearchMember={handleTextFieldChangeMember}
                onChange={handleAutocompleteChangeMember}
                memberOption={optionMember}
                onSearch={handleSearch}
                date={date}
                setDate={setDate}
                memberSingle={memberSingle}
                setPage={setPage}
                type={type}
              />
            </Stack>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected?.length}
                rowCount={tableData?.length}
                onSelectAllRows={(checked) => {
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  );
                }}
              />
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={handleTableHead()}
                    rowCount={tableData?.length}
                    numSelected={table.selected?.length}
                    onSort={table.onSort}
                  />

                  {loading ? (
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Paper>
                    </TableCell>
                  ) : (
                    <TableBody>
                      {(tableData || []).map((row, index) => (
                        <TransactionTableRow
                          index={index}
                          page={table.page + 1}
                          rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                          key={row.id}
                          row={row}
                          selected={table?.selected?.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onViewRow={() => handleViewRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          loading={loading}
                          colSpan={TABLE_HEAD?.length}
                          memberSingle={memberSingle}
                          type={type}
                        />
                      ))}
                    </TableBody>
                  )}

                  {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                  <TableNoData notFound={notFound} />
                </Table>
              </Scrollbar>
            </TableContainer>
            <TablePaginationCustom
              count={totalPage || 0}
              customRowsPerPage={5}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
              identifier={identifier}
            />
          </Card>
        </Stack>
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

TransactionMemberSingleView.propTypes = {
  memberSingle: PropTypes.string,
  filterMember: PropTypes.bool,
  type: PropTypes.any,
  earnings: PropTypes.any,
};
