// import { useState } from 'react';

import Stack from '@mui/material/Stack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Card, Grid, Link, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import img1 from 'src/assets/other/Image-1.png';
import img2 from 'src/assets/other/Image-2.png';
import img3 from 'src/assets/other/Image-3.png';
import fileIcon from 'src/assets/other/file.png';
// eslint-disable-next-line import/no-unresolved

import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function NewLearningPage() {
  const learningPageData = [
    {
      id: 1,
      img: img1,
      title: 'Your Guide to Getting Started',
      description:
        'Follow this step-by-step tour to discover all the tools and options available to you. Learn how to make the most of your experience by exploring all the app`s tools and features.',
    },
    {
      id: 2,

      img: img2,
      title: 'Your Journey Begins Here',
      description: 'Follow the guide to unlock all the app’s features and get the most out of it.',
    },
    {
      id: 3,
      img: img3,
      title: 'Master the Winbuks in Minutes',
      description:
        'ake a quick tour to understand the features and start using the app like a pro.',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs
        heading="Learnings"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Learnings',
          },
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack>
        <Grid container spacing={2}>
          {learningPageData?.map((value, key) => (
            <Grid item md={4} lg={4} xs={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                  boxShadow: 'none',
                  height: '100%',
                }}
              >
                <Stack direction="column" justifyContent="space-between" height="100%">
                  <Stack>
                    <CardMedia
                      component="img"
                      src={value?.img}
                      sx={{
                        objectFit: 'contain',
                        // height: '220px',
                        // width: '330px',
                        borderRadius: '15px',
                      }}
                    />
                    <Stack px={1} pb={2}>
                      <Typography py={2} variant="subtitle2" fontWeight={600} color="text.primary">
                        {value?.title}
                      </Typography>
                      <TextMaxLine
                        line={3}
                        // py={2}
                        variant="caption"
                        fontWeight={400}
                        color="text.secondary"
                      >
                        {value?.description}
                      </TextMaxLine>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="start" pb={1.5} px={1}>
                    <Label
                      sx={{
                        backgroundColor: '#C8FAD6',
                        color: '#007867',
                        borderRadius: '12px',
                        cursor: 'pointer',
                        px: 1,
                      }}
                    >
                      <AttachFileIcon
                        sx={{
                          fontSize: 14,
                        }}
                      />{' '}
                      <Typography variant="caption">Module {key + 1}</Typography>
                    </Label>
                    <Link href={`${paths?.dashboard?.learning_singleView(value?.id)}`}>
                      <Label
                        sx={{
                          backgroundColor: '#F0F0F0',
                          color: 'text.secondary',
                          borderRadius: '12px',
                          cursor: 'pointer',
                          ml: 1,
                          px: 1,
                        }}
                      >
                        <CardMedia
                          component="img"
                          src={fileIcon}
                          sx={{
                            objectFit: 'contain',
                            height: '16px',
                            width: '16px',
                          }}
                        />{' '}
                        <Typography variant="caption" pl={1}>
                          PDF
                        </Typography>
                      </Label>
                    </Link>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
}
