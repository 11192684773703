import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import {
  getAllTransactions,
  getAllTransactionsAdmin,
  getAllManualTransactions,
  createManualTransactions,
  getAllClubIncomeTransactions,
} from '../api/transaction';

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    loading: false,
    error: {},
    transactions: {},
    transactionDetails: {},
    latestTransactions: {},
    ClubIncomeTransactions: {},

    manualWalletTransaction: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all transactions
    [getAllTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getAllTransactions.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactions = action.payload;
      state.error = {};
    },
    [getAllTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllTransactionsAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getAllTransactionsAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactions = action.payload;
      state.error = {};
    },
    [getAllTransactionsAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all club income transactions
    [getAllClubIncomeTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getAllClubIncomeTransactions.fulfilled]: (state, action) => {
      state.loading = false;
      state.ClubIncomeTransactions = action.payload;
      state.error = {};
    },
    [getAllClubIncomeTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // manual wallet transactions

    [createManualTransactions.pending]: (state) => {
      state.loading = true;
    },
    [createManualTransactions.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.manualWalletTransaction;
      const modifiedNotificationList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list)
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            [action.payload, ...jsonState?.list]
          : [action.payload],
      };
      state.loading = false;
      state.manualWalletTransaction = modifiedNotificationList;
      state.error = {};
    },
    [createManualTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all manual transactions
    [getAllManualTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getAllManualTransactions.fulfilled]: (state, action) => {
      state.loading = false;
      state.manualWalletTransaction = action.payload;
      state.error = {};
    },
    [getAllManualTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update notification details
    // [updateNotificationDetails.pending]: (state) => {
    //   state.loading = true;
    // },
    // [updateNotificationDetails.fulfilled]: (state, action) => {
    //   state.loading = false;

    //   const jsonState = covertToJSON(state)?.notificationList;

    //   const modifiedNotificationList = {
    //     ...jsonState,
    //     list: jsonState.list?.map((i) => (i?._id === action?.payload?._id ? action?.payload : i)),
    //   };

    //   console.log(action.payload);
    //   console.log(modifiedNotificationList);

    //   state.notificationList = modifiedNotificationList;
    //   state.error = {};
    // },

    // [updateNotificationDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },

    // [deleteNotification.pending]: (state) => {
    //   state.loading = true;
    // },
    // [deleteNotification.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.notificationList;
    //   const modifiedList = {
    //     ...jsonState,
    //     list: jsonState.list?.filter((notification) => notification._id !== action.payload),
    //   };
    //   state.loading = false;
    //   state.notificationList = modifiedList;
    //   state.error = {};
    // },
    // [deleteNotification.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },
  },
});
export const { clearStaffError } = transactionSlice.actions;

export default transactionSlice.reducer;
