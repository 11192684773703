import * as React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Box,
  Card,
  Stack,
  Dialog,
  CardMedia,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';

import { fDateTime } from 'src/utils/format-time';

import company from 'src/assets/other/compsny.png';
import calander from 'src/assets/other/calander.png';
import member from 'src/assets/other/UserCircle-grn.png';

import Label from 'src/components/label';
import { useSettingsContext } from 'src/components/settings';
// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

TransactionViewModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.any,
};

export default function TransactionViewModal({ open, setOpen, data }) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const { themeMode } = useSettingsContext;

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatusValues = () => {
    if (data?.status === 'SUCCESS') {
      return { label: 'Success', fg_color: '#118D57', bg_color: '#22C55E29' };
    }
    if (data?.status === 'FAILED') {
      return { label: 'Failed', bg_color: '#FF563029', fg_color: '#B71D18' };
    }
    if (data?.status === 'PROCESSING') {
      return { label: 'Processing', bg_color: '#FFAB0029', fg_color: '#B76E00' };
    }
    return '';
  };

  const getCompanyLabel = (type) => {
    switch (type) {
      case 'FUTURE_PROJECTS':
        return 'Future Projects';
      case 'MAINTENANCE':
        return 'Maintenance';
      case 'COMPANY':
        return 'Company';
      default:
        return type;
    }
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="W2W-single-view-modal"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle>
          Transaction Details
          <Stack mt={1} direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box>
                <Label
                  sx={{
                    backgroundColor: handleChangeStatusValues()?.bg_color,
                    color: handleChangeStatusValues()?.fg_color,
                    fontWeight: 400,
                    borderRadius: '8px',
                  }}
                >
                  <Typography color={themeMode === 'dark' ? '' : 'text.primary'} variant="caption">
                    Status
                  </Typography>{' '}
                  : {handleChangeStatusValues()?.label}
                </Label>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack width="auto">
                <CardMedia
                  component="img"
                  src={calander}
                  sx={{
                    objectFit: 'contain',
                    height: '28px',
                    width: '28px',
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  {fDateTime(data?.createdAt) || ''}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ minWidth: isMobile ? '250px' : '600px', padding: '0px', p: 3 }}>
          <Box
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
            mb={4.5}
          >
            <Box height="100%">
              <Typography variant="body2" fontWeight={600} color="text.secondary" pb={0.5}>
                From
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  height: '100%',
                  px: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  boxShadow: '0px 2px 2px 0px #0000000A',
                  borderRadius: '7px',
                }}
              >
                <CardMedia
                  component="img"
                  src={data?.from_wallet_type === 'COMPANY' ? company : member}
                  sx={{
                    objectFit: 'contain',
                    height: 50,
                    width: 50,
                  }}
                />
                <Stack>
                  <Typography variant="subtitle2" color="text.primary">
                    {data?.from_wallet_type === 'COMPANY'
                      ? getCompanyLabel(data?.from_company_wallet)
                      : data?.from_member?.name || '---'}
                    {data?.from_wallet_type === 'MEMBER' && data?.from_member?.member_id && (
                      <Label
                        sx={{
                          backgroundColor: '#00B8D929',
                          color: '#006C9C',
                          ml: 1,
                          px: 1.5,
                          py: 0,
                          borderRadius: '3px',
                        }}
                      >
                        {' '}
                        {data?.from_member?.member_id || '--'}
                      </Label>
                    )}
                  </Typography>
                  <Typography variant="subtitle2" color="text.disabled" fontWeight={400}>
                    {data?.from_wallet_type === 'COMPANY' ? 'Company' : 'Member' || '--'}
                  </Typography>
                </Stack>
              </Card>
            </Box>
            <Box>
              <Typography variant="body2" fontWeight={600} color="text.secondary" pb={0.5}>
                To
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  height: '100%',
                  px: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  boxShadow: '0px 2px 2px 0px #0000000A',
                  borderRadius: '7px',
                }}
              >
                <CardMedia
                  component="img"
                  src={data?.to_wallet_type === 'COMPANY' ? company : member}
                  sx={{
                    objectFit: 'contain',
                    height: 50,
                    width: 50,
                  }}
                />
                <Stack>
                  <Typography variant="subtitle2" color="text.primary">
                    {data?.to_wallet_type === 'COMPANY'
                      ? getCompanyLabel(data?.to_company_wallet)
                      : data?.to_member?.name || '---'}
                    {data?.to_wallet_type === 'MEMBER' && data?.to_member?.member_id && (
                      <Label
                        sx={{
                          backgroundColor: '#00B8D929',
                          color: '#006C9C',
                          ml: 1,
                          px: 1.5,
                          py: 0,
                          borderRadius: '3px',
                        }}
                      >
                        {' '}
                        {data?.to_member?.member_id || '--'}
                      </Label>
                    )}
                  </Typography>
                  <Typography variant="subtitle2" color="text.disabled" fontWeight={400}>
                    {data?.to_wallet_type === 'COMPANY' ? 'Company' : 'Member' || '--'}
                  </Typography>
                </Stack>
              </Card>
            </Box>
          </Box>
          <Box width="50%" mt={2}>
            <Typography pb={1} fontSize="14px" fontWeight={600} color="text.secondary">
              Amount
            </Typography>
            <Card
              variant="outlined"
              sx={{
                boxShadow: '0px 2px 2px 0px #0000000A',
                p: 2,
                borderRadius: '7px',
                minHeight: '69px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle2" color="text.primary">
                {data?.amount} {USDT}
              </Typography>
            </Card>
          </Box>
          {data?.particulars && (
            <Stack pt={3}>
              <Typography fontSize="14px" fontWeight={600} color="text.secondary" pb={1}>
                Particulars
              </Typography>
              <Typography variant="body2" color="text.primary">
                {data?.particulars}
              </Typography>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
