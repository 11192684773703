import * as React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Box,
  Card,
  Stack,
  Dialog,
  CardMedia,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';

import { fDateTime } from 'src/utils/format-time';

import calander from 'src/assets/other/calander.png';
import userIcon from 'src/assets/other/UserCircle.png';
import userIconGrn from 'src/assets/other/UserCircle-grn.png';

import Label from 'src/components/label';
import { useSettingsContext } from 'src/components/settings';
// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

W2WTransferAdminViewModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.any,
};

export default function W2WTransferAdminViewModal({ open, setOpen, data }) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const { themeMode } = useSettingsContext;

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatusValues = () => {
    if (data?.status === 'SUCCESS') {
      return { label: 'Success', fg_color: '#118D57', bg_color: '#22C55E29' };
    }
    if (data?.status === 'FAILED') {
      return { label: 'Failed', bg_color: '#FF563029', fg_color: '#B71D18' };
    }
    if (data?.status === 'PROCESSING') {
      return { label: 'Processing', bg_color: '#FFAB0029', fg_color: '#B76E00' };
    }
    return '';
  };
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="W2W-single-view-modal"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle>
          Transfer Details
          <Stack mt={1} direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box>
                <Label
                  sx={{
                    backgroundColor: '#FFF5CC',
                    color: '#7A0916',
                  }}
                >
                  <Typography color={themeMode === 'dark' ? '' : 'text.primary'} variant="caption">
                    Transfer ID
                  </Typography>{' '}
                  : {data?.transfer_id}
                </Label>
              </Box>
              <Box>
                <Label
                  sx={{
                    backgroundColor: handleChangeStatusValues()?.bg_color,
                    color: handleChangeStatusValues()?.fg_color,
                  }}
                >
                  <Typography color={themeMode === 'dark' ? '' : 'text.primary'} variant="caption">
                    Status
                  </Typography>{' '}
                  : {handleChangeStatusValues()?.label}
                </Label>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack width="auto">
                <CardMedia
                  component="img"
                  src={calander}
                  sx={{
                    objectFit: 'contain',
                    height: '28px',
                    width: '28px',
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="body2" color="text.disabled">
                  {fDateTime(data?.createdAt) || '12 JUN 2024, 02:43 PM'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ minWidth: isMobile ? '250px' : '600px', padding: '0px', p: 3 }}>
          <Stack pt={1.5} direction="row" alignContent="center" justifyContent="center" spacing={2}>
            <Box width="100%">
              <Typography fontSize="14px" fontWeight={600} color="text.secondary" pb={1}>
                Beneficiary
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  boxShadow: 'none',
                  p: 1,
                  borderRadius: '7px',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Stack width="auto">
                    <CardMedia
                      component="img"
                      src={userIcon}
                      sx={{
                        objectFit: 'contain',
                        height: '51px',
                        width: '51px',
                      }}
                    />
                  </Stack>
                  <Stack pl={1}>
                    <Typography variant="subtitle2" color="text.primary">
                      {data?.beneficiary_member?.name || '---'}
                    </Typography>
                    <Typography variant="subtitle2" color="text.disabled" fontWeight={400}>
                      {data?.beneficiary_member?.member_id || '--'}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            </Box>
            <Box width="100%">
              <Typography fontSize="14px" fontWeight={600} color="text.secondary" pb={1}>
                Member
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  boxShadow: 'none',
                  p: 1,
                  borderRadius: '7px',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Stack width="auto">
                    <CardMedia
                      component="img"
                      src={userIconGrn}
                      sx={{
                        objectFit: 'contain',
                        height: '51px',
                        width: '51px',
                      }}
                    />
                  </Stack>
                  <Stack pl={1}>
                    <Typography variant="subtitle2" color="text.primary">
                      {data?.member?.name || '---'}
                    </Typography>
                    <Typography variant="subtitle2" color="text.disabled" fontWeight={400}>
                      {data?.member?.member_id || '--'}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            </Box>
          </Stack>
          <Box width="50%" mt={2}>
            <Typography pb={1} fontSize="14px" fontWeight={600} color="text.secondary">
              Amount
            </Typography>
            <Card
              variant="outlined"
              sx={{
                boxShadow: 'none',
                p: 2,
                borderRadius: '7px',
                minHeight: '69px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle2" color="text.primary">
                {data?.amount} {USDT}
              </Typography>
            </Card>
          </Box>
          {data?.particulars && (
            <Stack pt={3}>
              <Typography fontSize="14px" fontWeight={600} color="text.secondary" pb={1}>
                Particulars
              </Typography>
              <Typography variant="body2" color="text.primary">
                {data?.particulars}
              </Typography>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
