import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import { Box, Grid, Stack, Button, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';

// import { fShortenNumber } from 'src/utils/format-number';

import wallet from 'src/assets/other/wallet.png';
import { _analyticOrderTimeline } from 'src/_mock';
import pointer from 'src/assets/other/archive.png';
import dollarBag from 'src/assets/other/dollarBag.svg';
import archivedBgPng from 'src/assets/other/archived-bg-img.png';
import { getAllMemberClubsForMember } from 'src/server/api/memberClub';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import WalletTransferDialog from 'src/sections/income/wallet-Transfer';
import IncomeCurrentBalance from 'src/sections/income/income-current-balance';
import AnalyticsIncomeTimeline from 'src/sections/income/analytics-income-timeline';
import WalletTransferSuccess from 'src/sections/income/WalletTransfer-success-modal';
import WalletRequestsConfirmDialog from 'src/sections/income/Wallet-requests-confirm';

import ClubIncomeTransaction from '../ClubIncomeTransaction/index';
// ----------------------------------------------------------------------

export default function IncomePage() {
  // const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');
  const settings = useSettingsContext();
  const { themeMode } = useSettingsContext();

  const { MemberProfileDetails, memberClubForMember } = useSelector((state) => ({
    loading: state.referrals.loading,
    MemberProfileDetails: state.member.memberProfileDetails,
    memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
  }));

  // const ClubName = MemberProfileDetails?.member?.current_club?.club?.title;
  const [openWalletTransferDialog, setOpenWalletTransferDialog] = useState(false);
  const [openWalletTransferSuccess, setOpenWalletTransferSuccess] = useState(false);
  const [openClubId, setOpenClubId] = useState('');
  const [confirmDialog, setConfirmDialog] = useState(false);
  // const navigate = useNavigate();

  // console.log(MemberProfileDetails);
  // console.log(memberClubForMember);
  useEffect(() => {
    const state = {
      page: '',
      search: '',
      dispatch,
      limit: '',
    };
    dispatch(getAllMemberClubsForMember(state));
  }, [dispatch]);
  const earnings = [
    {
      label: 'Catalyst Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.catalyst_total,
    },
    {
      label: 'Connect Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.line_total,
    },
    {
      label: 'Fourth Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.fourth_total,
    },
    {
      label: 'Club Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.club_total,
    },
    {
      label: 'Incentive Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.incentive_total,
    },
    { label: 'Bionium Earnings', value: MemberProfileDetails?.member?.bionium_coin },
  ];
  // const HandlePage = () => {
  //   navigate(paths.dashboard.withdrawal_requests, {});
  // };
  return (
    <>
      <WalletRequestsConfirmDialog
        open={confirmDialog}
        setOpen={() => setConfirmDialog()}
        memberProfileDetails={MemberProfileDetails}
        setOpenWalletTransferDialog={setOpenWalletTransferDialog}
      />
      {openWalletTransferDialog && (
        <WalletTransferDialog
          setOpen={setOpenWalletTransferDialog}
          open={openWalletTransferDialog}
          setOpenWalletTransferSuccess={setOpenWalletTransferSuccess}
          openClubId={openClubId}
        />
      )}
      {openWalletTransferSuccess && (
        <WalletTransferSuccess
          setOpen={setOpenWalletTransferSuccess}
          open={openWalletTransferSuccess}
        />
      )}
      <CustomBreadcrumbs
        heading="My Earnings"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'My Earnings',
          },

          {
            name: 'Archived',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card
        sx={{
          backgroundColor: themeMode === 'dark' ? '' : 'rgba(235, 248, 244, 1)',
          borderRadius: '4px',
          p: isMobile ? 1.2 : 0.9,
          boxShadow: 'none',
        }}
      >
        <Grid display="flex" alignItems="center" justifyContent="flex-start">
          <Grid px={1}>
            <CardMedia src={pointer} component="img" alt="pointer" height={25} width={25} />
          </Grid>
          <Grid>
            <Typography
              variant="body2"
              color="rgba(0, 120, 103, 1)"
              fontSize={isMobile ? 13 : 16}
              fontWeight={600}
              lineHeight="20px"
            >
              All club earnings have been successfully migrated to a new format.
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Stack
        sx={{
          filter: 'grayscale(1)',
          position: 'relative',
        }}
        mt={3}
        flexDirection={isMobile ? 'column' : 'row'}
        spacing={3}
      >
        <CardMedia
          component="img"
          src={archivedBgPng}
          width="auto"
          sx={{
            objectFit: 'contain',
            position: 'absolute',
            top: 170,
            zIndex: 1,
            px: 5,
            // height: '50%',
          }}
        />
        <Box flex={2.1}>
          <Card variant="outlined" sx={{ boxShadow: 'unset', mb: 3 }}>
            <Stack
              borderBottom={
                settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea '
              }
            >
              <Stack
                direction="column"
                // alignItems="center"
                gap={2}
                sx={{
                  p: 4,
                  flex: 1,
                }}
              >
                <Stack
                  p={1}
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="50px"
                  height="50px"
                >
                  <CardMedia
                    component="img"
                    src={wallet}
                    alt="wallet"
                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="baseline" justifyContent="center">
                  <Typography
                    sx={{
                      typography: 'h5',
                    }}
                  >
                    {MemberProfileDetails?.member?.wallet_balance?.toFixed(2) || 0} {USDT}
                  </Typography>
                  <Typography variant="subtitle2">Wallet balance </Typography>
                </Stack>
                <Stack width="fit-content">
                  <Button
                    variant="outlined"
                    size="medium"

                    // onClick={HandlePage}
                  >
                    Create Withdrawal Request
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Stack
                direction="column"
                // alignItems="center"
                gap={2}
                sx={{
                  p: 4,
                  flex: 1,
                }}
              >
                <Stack
                  p={1}
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="50px"
                  height="50px"
                >
                  <CardMedia
                    component="img"
                    src={dollarBag}
                    alt="wallet"
                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="baseline" justifyContent="center">
                  <Typography
                    sx={{
                      typography: 'h5',
                    }}
                  >
                    {MemberProfileDetails?.member?.earnings_statistics?.net_earnings_total?.toFixed(
                      2
                    ) || 0}{' '}
                    {USDT}
                  </Typography>
                  <Typography variant="subtitle2">Net Earnings </Typography>
                </Stack>
                <Stack width="fit-content">
                  <Grid container spacing={2}>
                    {earnings.map((item, index) => (
                      <Grid
                        display="flex"
                        justifyContent="left"
                        mt={isMobile ? 1.5 : 3.4}
                        item
                        xs={6}
                        sm={6}
                        md={4}
                        key={index}
                      >
                        <Stack alignItems="baseline">
                          {item.label === 'Bionium Earnings' ? (
                            <Typography variant="body2" fontWeight="bold">
                              {item.value || 0} Tokens
                            </Typography>
                          ) : (
                            <Typography variant="body2" fontWeight="bold">
                              {item.value?.toFixed(2) || 0} {USDT}
                            </Typography>
                          )}

                          <Typography sx={{ typography: 'caption', opacity: 0.68 }}>
                            {item.label}
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>{' '}
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <ClubIncomeTransaction disabled="disable" />
        </Box>
        <Box flex={1}>
          <Stack mb={3}>
            {' '}
            <IncomeCurrentBalance
              ActiveClubBalance={MemberProfileDetails?.member?.current_club?.wallet_balance}
              setOpenWalletTransferDialog={setOpenWalletTransferDialog}
              setOpenClubId={setOpenClubId}
              setConfirmDialog={setConfirmDialog}
              MemberProfileDetails={MemberProfileDetails}
              disabled="disable"
            />
          </Stack>
          <Stack>
            <AnalyticsIncomeTimeline
              clubs={memberClubForMember}
              MemberProfileDetails={MemberProfileDetails}
              title={`Welcome to ${MemberProfileDetails?.member?.current_club?.club?.title} club! To boost your productivity and unlock access to other clubs, you can work towards achieving higher club incomes. Let's strive for success together!`}
              list={_analyticOrderTimeline}
              setOpenWalletTransferDialog={setOpenWalletTransferDialog}
              setOpenClubId={setOpenClubId}
              setConfirmDialog={setConfirmDialog}
              disabled="disabled"
            />
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
