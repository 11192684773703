/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Grid, Skeleton } from '@mui/material';

import { useParams } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllClubs } from 'src/server/api/club';
// import coverImg from 'src/assets/other/set-role.jpg';
// import memberProfileImg from 'src/assets/other/profile.png';
import {
  getAllMembers,
  getMemberDetails,
  getWithdrawalsList,
  getMemberClubIncomeTransactions,
} from 'src/server/api/member';

import { useTable } from 'src/components/table';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import ContactShow from 'src/sections/Member/MemberSingleView/ContactShow';
import PaymentShow from 'src/sections/Member/MemberSingleView/PaymentShow';
// import MemberCover from 'src/sections/Member/MemberSingleView/member-cover';
import WalletAddressDetailsAdmin from 'src/sections/Member/wallet-address-details';
import SingleMemberSort from 'src/sections/Member/MemberSingleView/singleMemberSort';
import UpdateAdminModalDialog from 'src/sections/Member/MemberSingleView/updateAdminModal';
// import DashboardTransaction from 'src/sections/app/admin/dashboard/Tables/TransactionPage';
import ReferralMember from 'src/sections/Member/MemberSingleView/miniTables/ReferralMembersTable';
// import ReferredOnPaymentDetails from 'src/sections/Member/MemberSingleView/ReferredViewOnpayment';
// import ClubMemberTransactionsTable from 'src/sections/Member/MemberSingleView/miniTables/ClubMemberTransactionsTable';

import { getAllTransactionsAdmin } from 'src/server/api/transaction';

import { useSettingsContext } from 'src/components/settings';

import MemberAccountsAdmin from 'src/sections/Member/MemberAccounts';
import UpdateMemberEmailDialog from 'src/sections/Member/MemberSingleView/update-member-email-modal';
import UpdateMemberPasswordModalDialog from 'src/sections/Member/MemberSingleView/updatememberPassWordModal';
import UpdateMemberEmailSuccessDialog from 'src/sections/Member/MemberSingleView/update-member-email-success-modal';

import MemberLoadingPage from '../MemberLoading';
import MemberCard from '../../Auth/profile/MemberCard';
import EarningsCard from '../../Auth/profile/EarningsCards';
import PaymentAndVerified from '../../Auth/profile/PaymentAndVerified';
import MemberRefiredCardNew from '../../Auth/profile/MemberRefferedCardNew';
import TransactionMemberSingleView from '../../Transaction/TransactionPage';
import WithdrawalRequestList from '../../withdrawalRequest/WithdrawalRequestsPage';

// ----------------------------------------------------------------------

export default function SingleView() {
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const isMobile = useResponsive('down', 'md');

  const [sortValue, setSortValue] = useState('referral');
  const [state1, setState1] = useState({});

  // console.log(sortValue);

  const params = useParams();
  const [date, setDate] = useState();
  const tableClubIncome = useTable({ defaultOrderBy: 'createdAt' });
  const limitValueClub = sessionStorage?.getItem(`rowsPerPage_${'referralMember'}`);
  const [openModalDialog, setOpenModalDialog] = useState(false);
  const [openModalPasswordDialog, setOpenModalPasswordDialog] = useState(false);
  const [openUpdateMemberEmailSuccess, setOpenUpdateMemberEmailSuccess] = useState(false);
  const [openUpdateMemberEmail, setOpenUpdateMemberEmail] = useState(false);
  const selectedRangeFormatted = date?.map((dates) => dayjs(dates).format('YYYY-MM-DD'));

  const {
    memberDetails,
    club,
    membersList,
    clubIncomeTransactions: transactionCounts,
    memberClubWithdrawalList,
    loading,
  } = useSelector((state) => ({
    loading: state.member.loading,
    memberDetails: state.member.memberDetails,
    club: state.club.clubs?.clubs,
    membersList: state.member.members?.members,
    clubIncomeTransactions: state.transaction.transactions?.walletTransactionCounts,
    memberClubWithdrawalList: state.memberClub?.memberClubWithdrawalList,
  }));

  // console.log('---------->>>', memberDetails);

  const [searchText, setSearchText] = useState('');

  // const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const credentials = {
      memberId: params?.id,
      dispatch,
    };
    dispatch(getMemberDetails(credentials));
  }, [dispatch, params?.id]);

  // useEffect(() => {
  //   setTableData(memberDetails);
  // }, [memberDetails, setTableData]);

  const handleSearchClub = (value) => {
    setSearchText(value);
  };

  const [selectedClub, setSelectedClub] = useState('');
  const [searchClub, setSearchClub] = useState('');

  const handleTextFieldChangeClub = (event) => {
    setSearchClub(event.target.value);
  };
  const handleAutocompleteChangeClub = (e, newValue) => {
    setSelectedClub(newValue || '');
  };

  const optionClub = [
    { label: 'All', value: '' },
    ...(club || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    if (memberDetails?.is_verified) {
      const clubValues = {
        page: '',
        search: searchClub,
        dispatch,
        limit: '',
      };
      dispatch(getAllClubs(clubValues));
    }
  }, [dispatch, memberDetails?.is_verified, searchClub]);

  useEffect(() => {
    if (memberDetails?.is_verified && params?.id) {
      const incomeTransactionValues = {
        page: tableClubIncome.page + 1,
        dispatch,
        date,
        selectedClub: selectedClub?.value,
        limit: tableClubIncome?.rowsPerPage || limitValueClub,
        id: params?.id,
      };
      dispatch(getMemberClubIncomeTransactions(incomeTransactionValues));
    }
  }, [
    memberDetails,
    dispatch,
    date,
    selectedClub?.value,
    tableClubIncome?.page,
    tableClubIncome?.rowsPerPage,
    limitValueClub,
    params?.id,
  ]);

  const limitValue = sessionStorage?.getItem(`rowsPerPage_${'referralMember'}`);

  const table = useTable({ defaultOrderBy: 'createdAt' });
  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText,
      dispatch,
      limit: table?.rowsPerPage || limitValue,
      referralMember: params?.id,
    };
    dispatch(getAllMembers(credentials));
  }, [dispatch, table.page, table?.rowsPerPage, limitValue, searchText, params?.id]);

  // withdrawals
  useEffect(() => {
    const credentials = {
      dispatch,
      memberId: params?.id,
    };
    dispatch(getWithdrawalsList(credentials));
  }, [dispatch, params?.id]);

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: 5,
      transaction_type: '',
      date: '',
      dispatch,
      member: memberDetails?._id,
      wallet_type: '',
      selectedCategory: '',
    };

    dispatch(getAllTransactionsAdmin(credentials));
  }, [date, table.page, dispatch, memberDetails?._id]);

  // membersList?.length,
  // clubIncomeTransactions?.length,
  // memberClubWithdrawalList?.withdrawalRequests?.length,
  return (
    <>
      {openUpdateMemberEmail && (
        <UpdateMemberEmailDialog
          setOpen={setOpenUpdateMemberEmail}
          open={openUpdateMemberEmail}
          setOpenUpdateMemberEmailSuccess={setOpenUpdateMemberEmailSuccess}
          memberDetails={memberDetails}
          tabValue={0}
        />
      )}

      {openUpdateMemberEmailSuccess && (
        <UpdateMemberEmailSuccessDialog
          setOpen={setOpenUpdateMemberEmailSuccess}
          open={openUpdateMemberEmailSuccess}
          memberDetails={memberDetails}
        />
      )}
      {openModalDialog && (
        <UpdateAdminModalDialog
          setOpen={setOpenModalDialog}
          open={openModalDialog}
          isUpdate={memberDetails}
          memberDetails={memberDetails}
          // setIsUpdate={setInvestorData}
        />
      )}
      {openModalPasswordDialog && (
        <UpdateMemberPasswordModalDialog
          setOpen={setOpenModalPasswordDialog}
          open={openModalPasswordDialog}
          isUpdate={memberDetails}
          memberDetails={memberDetails}
          // setIsUpdate={setInvestorData}
        />
      )}
      <CustomBreadcrumbs
        heading="Member Details"
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'Member', href: paths.dashboard?.member },
          { name: memberDetails?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {loading ? (
        <MemberLoadingPage />
      ) : (
        <>
          <Grid container>
            {/* <Grid item md={8} xs={12}>
          <Card
            sx={{
              mb: 3,
              height: isMobile ? 'auto' : 230,
            }}
          >
            <MemberCover
              country_code={memberDetails?.country_code}
              username={memberDetails?.user?.username}
              phone={memberDetails?.user?.phone}
              email={memberDetails?.user?.email}
              avatarUrl={memberDetails?.profilePic?.url || memberProfileImg}
              coverUrl={memberDetails.coverUrl || coverImg}
              verified={memberDetails?.is_verified}
              paid={memberDetails?.is_paid}
            />
          </Card>
        </Grid>

        <Grid md={4} pl={isMobile ? 0 : 2} xs={12} height='100%'>
          {memberDetails.is_verified  && (
            <ReferredOnPaymentDetails memberDetails={memberDetails} />
          )}
          {!memberDetails?.is_verified && memberDetails?.is_paid && (
            <PaymentShow memberDetails={memberDetails} />
          )}
          {!memberDetails.is_paid&& <ContactShow memberDetails={memberDetails} />}
        </Grid> */}
            <Grid
              className="custom-height-100"
              display="flex"
              direction={isMobile ? 'column' : 'row'}
              mb={3}
              item
              md={12}
              gap={2}
              width="100%"
            >
              <Card
                sx={{
                  flex: 2.3,
                  borderRadius: '15px',
                }}
              >
                {/* <MemberCover
              createdAt={memberDetails?.createdAt}
              setOpenModalDialog={setOpenModalDialog}
              country_code={memberDetails?.country_code}
              memberID={memberDetails?.member_id}
              username={memberDetails?.user?.username}
              name={memberDetails?.user?.name}
              phone={memberDetails?.user?.phone}
              email={memberDetails?.user?.email}
              avatarUrl={memberDetails?.profile_photo?.url || memberProfileImg}
              coverUrl={memberDetails.coverUrl || coverImg}
              verified={memberDetails?.is_verified}
              paid={memberDetails?.is_paid}
              memberDetails={memberDetails}
            /> */}
                {loading ? (
                  <Skeleton variant="rounded" width="100%" height="100%" />
                ) : (
                  <MemberCard
                    memberDetails={memberDetails}
                    createdAt={memberDetails?.createdAt}
                    expiry_date={memberDetails?.expiry_date}
                    name={memberDetails?.name}
                    username={memberDetails?.user?.username}
                    cardImg={memberDetails?.current_member_incentive?.incentive_level?.rank_name}
                  />
                )}
              </Card>
              <Stack flex={4} height="100%">
                {loading ? (
                  <Skeleton variant="rounded" width="100%" height="100%" />
                ) : (
                  <>
                    {memberDetails.is_verified && (
                      <>
                        {/* <ReferredOnPaymentDetails memberDetails={memberDetails} /> */}

                        <Card
                          variant="outlined"
                          sx={{
                            flex: 7.9,
                            borderRadius: '15px',
                            boxShadow: 'unset',
                          }}
                        >
                          <MemberRefiredCardNew
                            memberDetails={memberDetails}
                            setOpenModalPasswordDialog={setOpenModalPasswordDialog}
                            setOpenModalDialog={setOpenModalDialog}
                            data={memberDetails}
                            memberID={memberDetails?.member_id}
                            setOpenUpdateMemberEmail={setOpenUpdateMemberEmail}
                          />
                        </Card>
                        {/* <Card sx={{ flex: 7.9, borderRadius: '15px' }}>
                  <MemberRefferedCard
                    memberDetails={memberDetails}
                    setOpenModalPasswordDialog={setOpenModalPasswordDialog}
                    setOpenModalDialog={setOpenModalDialog}
                    data={memberDetails}
                    memberID={memberDetails?.member_id}
                  />
                </Card> */}
                      </>
                    )}
                    {!memberDetails?.is_verified && memberDetails?.is_paid && (
                      <PaymentShow
                        memberDetails={memberDetails}
                        setOpenUpdateMemberEmail={setOpenUpdateMemberEmail}
                      />
                    )}
                    {!memberDetails.is_paid && (
                      <ContactShow
                        memberDetails={memberDetails}
                        setOpenUpdateMemberEmail={setOpenUpdateMemberEmail}
                      />
                    )}
                  </>
                )}
              </Stack>
            </Grid>
            {/* <Stack
          flex={4}
          height="100%"
          direction="row"
          // border='2px solid orange'
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <AdminPermission />
          <MemberEarnings />
        </Stack> */}
            <PaymentAndVerified memberDetails={memberDetails} />
            {/* <MiniWidgets memberDetails={memberDetails} /> */}
            <EarningsCard memberDetails={memberDetails} />
            <Grid
              md={12}
              py={3}
              xs={12}
              gap={3}
              sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
            >
              <Stack flex={5}>
                <MemberAccountsAdmin memberDetails={memberDetails} />
              </Stack>
              <Stack flex={7} height="100%">
                <WalletAddressDetailsAdmin memberDetails={memberDetails} />

                {/* {memberDetails?.is_verified  ? (
              <VerifiedDetails memberDetails={memberDetails} />
            ) : (
              <>
                {memberDetails?.referral_member?.name && (
                  <Stack pb={2} height="100%">
                    <ReferredByDetails memberDetails={memberDetails} />
                  </Stack>
                )}
                <Stack height="100%">
                  <JoinedOnDetails memberDetails={memberDetails} />
                </Stack>{' '}
              </>
            )} */}
              </Stack>
            </Grid>
            {/* <Grid md={8} py={isMobile ? 1 : 3} pl={!isMobile && 3} xs={12}>
        </Grid> */}

            {/* <Grid
          md={12}
          xs={12}
          pb={isMobile && 1}
          sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
          gap={3}
        >
          <Stack flex={8}>
            <UpdateMember memberDetails={memberDetails} />
          </Stack> */}
            {/* <Stack flex={4} height="100%">
            {memberDetails?.is_verified? (
              <VerifiedDetails memberDetails={memberDetails} />
            ) : (
              <>
                {memberDetails?.referral_member?.name && (
                  <Stack pb={2} height="100%">
                    <ReferredByDetails memberDetails={memberDetails} />
                  </Stack>
                )}
                <Stack height="100%">
                  <JoinedOnDetails memberDetails={memberDetails} />
                </Stack>{' '}
              </>
            )}
          </Stack> */}
            {/* </Grid> */}
            {/* <Grid md={8} xs={12} pb={isMobile && 1}>
          <UpdateMember memberDetails={memberDetails} />
        </Grid>

        <Grid md={4} pl={!isMobile && 3} xs={12} height="100%">
          {memberDetails?.is_verified ? (
            <VerifiedDetails memberDetails={memberDetails} />
          ) : (
            <>
              <Stack>
                {memberDetails?.referral_member?.name && (
                  <ReferredByDetails memberDetails={memberDetails} />
                )}
              </Stack>{' '}
              <Stack pt={2}>
                <JoinedOnDetails memberDetails={memberDetails} />
              </Stack>{' '}
            </>
          )}
        </Grid> */}
          </Grid>

          {/* {memberDetails?.is_verified && (
        <Stack py={3}>
          <Grid container>
            <Grid md={6} lg={6} xs={12}>
              <Grid className="table-remove-scroll">
                <ReferralMember
                  search={setSearchText}
                  table={table}
                  title="Referral Members"
                  subheader=""
                  tableData={membersList}
                  tableLabels={[
                    { id: 'date', label: 'Date' },
                    { id: 'member', label: 'Member' },
                    { id: 'contact', label: 'Contact' },
                    { id: '' },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid md={6} lg={6} pl={!isMobile && 3.5} xs={12} pt={isMobile && 1}>
              <Grid className="table-remove-scroll-club-member">
                <ClubMemberTransactionsTable
                  date={date}
                  setDate={setDate}
                  dateFormatted={selectedRangeFormatted}
                  clubSearch={handleSearchClub}
                  handleTextFieldChangeClub={handleTextFieldChangeClub}
                  handleAutocompleteChangeClub={handleAutocompleteChangeClub}
                  optionClub={optionClub}
                  table={tableClubIncome}
                  title="Club Income Transaction"
                  subheader=""
                  tableData={clubIncomeTransactions}
                  tableLabels={[
                    { id: 'date', label: 'Date' },
                    { id: 'description', label: 'Description' },
                    { id: 'amount', label: 'Amount ' },
                    { id: 'club', label: 'Club' },
                    // { id: '' },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      )} */}

          {memberDetails?.is_verified && (
            <>
              <SingleMemberSort
                sort={sortValue}
                setSort={setSortValue}
                state={state1}
                setState={setState1}
                memberCount={[
                  membersList?.length,
                  transactionCounts?.AllWalletTransactionCount,
                  memberClubWithdrawalList?.withdrawalRequests?.length,
                ]}
              />
              <Stack py={3}>
                <Grid container>
                  {sortValue === 'referral' && (
                    <Grid xl={12} md={12} lg={12} sm={12} xs={12}>
                      <Grid className="table-remove-scroll">
                        <ReferralMember
                          search={setSearchText}
                          memberID={memberDetails?.referral_id || memberDetails?.member_id}
                          table={table}
                          title="Referral Members"
                          subheader=""
                          tableData={membersList}
                          tableLabels={[
                            { id: 'date', label: 'Date', width: 400 },
                            { id: 'member', label: 'Member', width: 400 },
                            { id: 'contact', label: 'Contact', width: 400 },
                            { id: '' },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {sortValue === 'transaction' && (
                    <Grid xl={12} md={12} lg={12} sm={12} xs={12} pt={isMobile && 1}>
                      <Grid className="table-remove-scroll-club-member">
                        {/* <ClubMemberTransactionsTable
                    date={date}
                    setDate={setDate}
                    dateFormatted={selectedRangeFormatted}
                    clubSearch={handleSearchClub}
                    handleTextFieldChangeClub={handleTextFieldChangeClub}
                    handleAutocompleteChangeClub={handleAutocompleteChangeClub}
                    optionClub={optionClub}
                    table={tableClubIncome}
                    title="Club Income Transaction"
                    subheader=""
                    tableData={clubIncomeTransactions}
                    tableLabels={[
                      { id: 'date', label: 'Date' },
                      { id: 'description', label: 'Description' },
                      { id: 'amount', label: 'Amount ' },
                      { id: 'club', label: 'Club' },
                      // { id: '' },
                    ]}
                  /> */}
                        <TransactionMemberSingleView
                          memberSingle={memberDetails?._id}
                          filterMember
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* WITHDRAWALS */}
                  {sortValue === 'withdrawals' && (
                    <Grid xl={12} md={12} lg={12} sm={12} xs={12} pt={isMobile && 1}>
                      <Grid className="table-remove-scroll-club-member">
                        {/* <ClubMemberWithdrawalsTable
                      date={date}
                      setDate={setDate}
                      dateFormatted={selectedRangeFormatted}
                      clubSearch={handleSearchClub}
                      handleTextFieldChangeClub={handleTextFieldChangeClub}
                      handleAutocompleteChangeClub={handleAutocompleteChangeClub}
                      optionClub={optionClub}
                      table={tableClubIncome}
                      title="Club Income Transaction"
                      subheader=""
                      tableData={memberClubWithdrawalList?.withdrawalRequests}
                      tableLabels={[
                        { id: 'date', label: 'Date' },
                        // { id: 'description', label: 'Walet Address' },
                        { id: 'deduction_amount', label: 'Deduction Amount ' },
                        { id: 'amount', label: 'Amount ' },
                        { id: 'club', label: 'Status' },
                        // { id: '' },
                      ]}
                    /> */}
                        <WithdrawalRequestList memberSingle={memberDetails?._id} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </>
          )}
        </>
      )}
    </>
  );
}
