import { useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { USDT } from 'src/hooks/use-USDT';

import { fDate, fTime } from 'src/utils/format-time';

import { decimalLimit } from 'src/server/api/http';

import Label from 'src/components/label/label';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

import W2WTransferMemberViewModal from './member-W2W-singleview-modal';

// ----------------------------------------------------------------------

export default function W2WTransferTableRow({ row, selected, memberSingle }) {
  const [isOpen, setIsOpen] = useState();

  const {
    createdAt,
    amount,
    // remarks,
    transfer_id,
    beneficiary_member,
    status,

    particulars,
  } = row;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getStatusDetails = () => {
    switch (status) {
      case 'PROCESSING':
        return { label: 'Processing', color: 'warning' };
      case 'SUCCESS':
        return { label: 'Success', color: 'success' };
      case 'FAILED':
        return { label: 'Failed', color: 'error' };

      default:
        return { label: '', color: '' };
    }
  };

  return (
    <>
      {isOpen && <W2WTransferMemberViewModal open={isOpen} setOpen={setIsOpen} data={row} />}

      <TableRow hover selected={selected}>
        <TableCell>
          <ListItemText
            primary={fDate(createdAt)}
            secondary={fTime(createdAt)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>{transfer_id}</TableCell>
        <TableCell>
          {' '}
          <ListItemText
            primary={`${beneficiary_member?.name || '--'}`}
            secondary={`${beneficiary_member?.member_id || '--'}`}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell sx={{ wordWrap: 'break-word' }}>
          <TextMaxLine line={2}>
            <Typography fontSize="14px" fontWeight={400}>
              {amount?.toFixed(decimalLimit)} {USDT}
            </Typography>
          </TextMaxLine>
        </TableCell>
        {/* {role === 'admin' && (
          <TableCell variant="subtitle2" sx={{ wordWrap: 'break-word' }}>
            <TextMaxLine line={2}>
              <Typography variant="subtitle2">
                {deduction_amount?.toFixed(2)} {USDT}
              </Typography>
            </TextMaxLine>
          </TableCell>
        )}
        {/* {role === 'admin' && (
          <TableCell>
            <TextMaxLine line={2} variant="body2" sx={{ maxWidth: 300 }}>
              {remarks || '--'}
            </TextMaxLine>
          </TableCell>
        )} */}
        <TableCell>
          {/* <Label variant="soft" color={getColor()}>
            {handleStatusValues()}
          </Label> */}
          <Typography fontSize="14px" fontWeight={400}>
            {particulars || '--'}
          </Typography>
        </TableCell>
        {/* <TableCell>{status}</TableCell> */}
        <TableCell>
          <Label variant="soft" color={getStatusDetails()?.color}>
            {getStatusDetails()?.label}
          </Label>
        </TableCell>
        <TableCell>
          <Stack flexDirection="row" alignItems="center">
            <Tooltip title="View Details" onClick={() => setIsOpen(true)}>
              <VisibilityIcon sx={{ fontSize: '20px', cursor: 'pointer', ml: 2 }} />
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}

W2WTransferTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  memberSingle: PropTypes.bool,
};
