import React from 'react';

import { Container } from '@mui/material';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import ManualWalletTransactions from './ManualTransactionsPage';

function AllManualWalletTransactions() {
  const settings = useSettingsContext();
  const { role } = useGetRoles();
  console.log(role);

  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Manual Wallet Transactions" />
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <ManualWalletTransactions />
      </Container>
    </>
  );
}

export default AllManualWalletTransactions;
