import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { Box, Card, Button, Tooltip, CardMedia, Typography } from '@mui/material';

import { useParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { fDateTime } from 'src/utils/format-time';

import user from 'src/assets/other/user-box.png';
import { memberBlock } from 'src/server/api/member';
import copyWhite from 'src/assets/other/copygreen.png';
import calander from 'src/assets/other/big-calander.png';

import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';

import FlagDialog from 'src/sections/Member/MemberSingleView/flagDialog';
import SuspendDialog from 'src/sections/Member/MemberSingleView/suspendDialog';

export default function PaymentAndVerifiedMember({ memberDetails }) {
  const settings = useSettingsContext();
  const isShow = useBoolean();

  const { isBlocked, isSuspented } = useSelector((state) => ({
    flagMessage: state.member.memberDetails?.note,
    isBlocked: state.member.memberDetails?.is_blocked,
    isSuspented: state.member.memberDetails?.is_withdrawal_suspended,
  }));
  // console.log(memberDetails);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const confirm = useBoolean();
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = useResponsive('down', 'md');

  // Block_Unblock_Member
  const Block_Unblock_Member = () => {
    const credentials = {
      dispatch,
      memberId: params?.id,
      state: {
        is_blocked: !isBlocked,
      },
    };
    dispatch(memberBlock(credentials));
  };

  const handleControlPaymentType = () => {
    if (memberDetails?.payment_type === 'DIRECT') {
      return {
        label: 'Direct',
        bg_color: '#00A76F29',
        fg_color: '#007867',
      };
    }
    if (memberDetails?.payment_type === 'W2W') {
      return {
        label: 'W2W',
        bg_color: '#8E33FF29',
        fg_color: '#5119B7',
      };
    }
    return {
      label: '',
      bg_color: '',
      fg_color: '',
    };
  };

  return (
    <>
      <Stack width="100%" direction={isMobile ? 'column' : 'row'} gap={2}>
        {memberDetails?.verified_at && (
          <Card
            variant="outlined"
            sx={{ flex: 4.3, p: 2, boxShadow: 'unset', borderRadius: '15px' }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography fontSize="14px" color="text.primary">
                {' '}
                Membership Payment Details
              </Typography>
              <Label
                sx={{
                  backgroundColor: handleControlPaymentType()?.bg_color,
                  color: handleControlPaymentType()?.fg_color,
                  fontWeight: 500,
                }}
              >
                {handleControlPaymentType()?.label}
              </Label>
            </Stack>
            {memberDetails?.paid_at ? (
              <Stack pt={2} direction="row" alignItems="end" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Box>
                    <CardMedia
                      component="img"
                      src={calander}
                      sx={{
                        objectFit: 'contain',
                        height: '44px',
                        width: '44px',
                      }}
                    />
                  </Box>
                  <Box pl={1.5}>
                    <Typography fontSize="14px" fontWeight={600} color="text.primary">
                      Paid at
                    </Typography>
                    <Stack>
                      <Typography fontSize="14px" fontWeight={400} color="text.secondary">
                        {fDateTime(memberDetails?.paid_at)}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
                {isShow.value === false && (
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="#007867"
                      onClick={() => isShow.onTrue()}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      Show more
                    </Typography>
                  </Box>
                )}
              </Stack>
            ) : (
              <Box mt={1}>
                <Typography variant="caption" textAlign="start" sx={{ color: 'text.secondary' }}>
                  Transaction hash
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={0.3}>
                  <Stack width="100%">
                    <Stack direction="row" gap={1}>
                      <Card
                        sx={{
                          px: 1,
                          py: 0.7,
                          borderRadius: '10px',
                          boxShadow: 'unset',
                          border:
                            settings?.themeMode === 'dark'
                              ? '1px solid #616161'
                              : '1px solid #e9ecee',
                          textAlign: 'start',
                          width: '100%',
                        }}
                      >
                        <TextMaxLine line={1}>
                          <Typography
                            sx={{
                              wordWrap: 'break-word',
                              overflowWrap: 'anywhere',
                              textAlign: 'start',
                            }}
                            variant="caption"
                          >
                            {memberDetails?.payment_transaction_id}
                          </Typography>
                        </TextMaxLine>
                      </Card>
                      <CopyToClipboard
                        text={`${memberDetails?.payment_transaction_id}`}
                        onCopy={handleCopy}
                      >
                        <Tooltip title="Copy Transaction hash">
                          <Button
                            size="small"
                            variant="contained"
                            startIcon={
                              <CardMedia
                                component="img"
                                src={copyWhite}
                                alt="copy"
                                sx={{ width: '15px' }}
                              />
                            }
                            sx={{
                              width: '110px',
                              backgroundColor: '#e1efea',
                              height: '34px',
                              color: '#007867',
                              '&:hover': { backgroundColor: '#e1efea' },
                            }}
                          >
                            Copy
                          </Button>
                        </Tooltip>
                      </CopyToClipboard>
                    </Stack>
                  </Stack>
                  {/* <Typography
                    variant="subtitle2"
                    color="#007867"
                    onClick={() => isShow.onFalse()}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    Show less
                  </Typography> */}
                </Box>
              </Box>
            )}
            <>
              {isShow.value === true && (
                <>
                  {memberDetails?.payment_type === 'DIRECT' ? (
                    <Box mt={1}>
                      <Typography
                        variant="caption"
                        textAlign="start"
                        sx={{ color: 'text.secondary' }}
                      >
                        Transaction hash
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mt={0.3}
                      >
                        <Stack width="70%">
                          <Stack direction="row" gap={1}>
                            <Card
                              sx={{
                                px: 1,
                                py: 0.7,
                                borderRadius: '10px',
                                boxShadow: 'unset',
                                border:
                                  settings?.themeMode === 'dark'
                                    ? '1px solid #616161'
                                    : '1px solid #e9ecee',
                                textAlign: 'start',
                                width: '100%',
                              }}
                            >
                              <TextMaxLine line={1}>
                                <Typography
                                  sx={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'anywhere',
                                    textAlign: 'start',
                                  }}
                                  variant="caption"
                                >
                                  {memberDetails?.payment_transaction_id}
                                </Typography>
                              </TextMaxLine>
                            </Card>
                            <CopyToClipboard
                              text={`${memberDetails?.payment_transaction_id}`}
                              onCopy={handleCopy}
                            >
                              <Tooltip title="Copy Transaction hash">
                                <Button
                                  size="small"
                                  variant="contained"
                                  startIcon={
                                    <CardMedia
                                      component="img"
                                      src={copyWhite}
                                      alt="copy"
                                      sx={{ width: '15px' }}
                                    />
                                  }
                                  sx={{
                                    width: '110px',
                                    backgroundColor: '#e1efea',
                                    height: '34px',
                                    color: '#007867',
                                    '&:hover': { backgroundColor: '#e1efea' },
                                  }}
                                >
                                  Copy
                                </Button>
                              </Tooltip>
                            </CopyToClipboard>
                          </Stack>
                        </Stack>
                        <Typography
                          variant="subtitle2"
                          color="#007867"
                          onClick={() => isShow.onFalse()}
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          Show less
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <>
                        {/* {memberDetails?.payment_member?._id ? ( */}
                        <Stack
                          pt={2}
                          direction="row"
                          alignItems="end"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <Box>
                              <CardMedia
                                component="img"
                                src={user}
                                sx={{
                                  objectFit: 'contain',
                                  height: '44px',
                                  width: '44px',
                                }}
                              />
                            </Box>
                            <Box pl={1.5}>
                              <Typography fontSize="14px" fontWeight={600} color="text.primary">
                                Paid by
                              </Typography>
                              <Stack>
                                <Typography fontSize="14px" fontWeight={400} color="text.secondary">
                                  {memberDetails?.paid_member?.name}
                                  <Label
                                    sx={{
                                      marginLeft: 1,
                                      backgroundColor: '#00B8D929',
                                      color: '#006C9C',
                                      fontWeight: 400,
                                      px: 1,
                                      borderRadius: '3px',
                                    }}
                                  >
                                    {memberDetails?.paid_member?.member_id}
                                  </Label>
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              color="#007867"
                              onClick={() => isShow.onFalse()}
                              sx={{
                                cursor: 'pointer',
                              }}
                            >
                              Show less
                            </Typography>
                          </Box>
                        </Stack>
                      </>
                      {/* )} */}
                    </>
                  )}
                </>
              )}
            </>
            {/* ) : ( */}
            {/* <Stack>
                <Typography
                  variant="caption"
                  mt={1}
                  textAlign="start"
                  sx={{ color: 'text.secondary' }}
                >
                  Transaction hash
                </Typography>
                <Stack direction="row" gap={1}>
                  <Card
                    sx={{
                      px: 1,
                      py: 0.7,
                      borderRadius: '10px',
                      boxShadow: 'unset',
                      border:
                        settings?.themeMode === 'dark' ? '1px solid #616161' : '1px solid #e9ecee',
                      textAlign: 'start',
                      width: '100%',
                    }}
                  >
                    <TextMaxLine line={1}>
                      <Typography
                        sx={{
                          wordWrap: 'break-word',
                          overflowWrap: 'anywhere',
                          textAlign: 'start',
                        }}
                        variant="caption"
                      >
                        {memberDetails?.payment_transaction_id}
                      </Typography>
                    </TextMaxLine>
                  </Card>
                  <CopyToClipboard
                    text={`${memberDetails?.payment_transaction_id}`}
                    onCopy={handleCopy}
                  >
                    <Tooltip title="Copy Transaction hash">
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={
                          <CardMedia
                            component="img"
                            src={copyWhite}
                            alt="copy"
                            sx={{ width: '15px' }}
                          />
                        }
                        sx={{
                          width: '110px',
                          backgroundColor: '#e1efea',
                          height: '34px',
                          color: '#007867',

                          '&:hover': { backgroundColor: '#e1efea' },
                        }}
                      >
                        Copy
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                </Stack>
              </Stack> */}
            {/* )} */}
          </Card>
        )}
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        // open='true'
        onClose={confirm.onFalse}
        title={isBlocked ? 'Unblock Member' : 'Block Member'}
        content={<>Are you sure want to {isBlocked ? 'unblock member' : 'block member'}?</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              Block_Unblock_Member();
              confirm.onFalse();
            }}
          >
            {isBlocked ? 'Unblock' : 'Block'}
          </Button>
        }
      />

      <FlagDialog
        open={openFlagDialog}
        setOpen={setOpenFlagDialog}
        flagMessage={memberDetails?.note}
        is_flagged={memberDetails?.is_flagged}
      />
      <SuspendDialog
        open={openSuspendDialog}
        setOpen={setOpenSuspendDialog}
        isSuspented={isSuspented}
      />
    </>
  );
}

PaymentAndVerifiedMember.propTypes = {
  memberDetails: PropTypes.string,
};
