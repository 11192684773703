import { createSlice } from '@reduxjs/toolkit';

import { deleteWallet, getAllMemberWalletAddress } from '../api/Wallet';
import {
  activateNewMember,
  getAllAdminW2WTransfer,
  getAllMemberW2WTransfer,
  createWallet2WalletTransferMember,
} from '../api/W2WTransfer';

const covertToJSON = (data) => JSON.parse(JSON.stringify(data));

export const W2WTransferSlice = createSlice({
  name: 'wallet',
  initialState: {
    loading: false,
    error: {},
    // wallet
    w2wTransferMember: {},
    w2wTransferAdmin: {},
    countLoading: false,
  },
  reducers: {
    clearWalletError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create wallet details
    [activateNewMember.pending]: (state) => {
      state.loading = true;
    },
    [activateNewMember.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletList;
      const modifiedWalletList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list)
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            [...jsonState?.list, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.walletDetails = action.payload;
      state.w2wTransferMember = modifiedWalletList;
      state.error = {};
    },
    [activateNewMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all wallet
    [getAllMemberW2WTransfer.pending]: (state) => {
      state.loading = true;
    },
    [getAllMemberW2WTransfer.fulfilled]: (state, action) => {
      state.loading = false;
      state.w2wTransferMember = action.payload;
      state.error = {};
    },
    [getAllMemberW2WTransfer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // admin
    [getAllAdminW2WTransfer.pending]: (state) => {
      state.loading = true;
    },
    [getAllAdminW2WTransfer.fulfilled]: (state, action) => {
      state.loading = false;
      state.w2wTransferAdmin = action.payload;
      state.error = {};
    },
    [getAllAdminW2WTransfer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // create wallet details
    [createWallet2WalletTransferMember.pending]: (state) => {
      state.loading = true;
    },
    [createWallet2WalletTransferMember.fulfilled]: (state, action) => {
      state.loading = false;
      state.w2wTransfer = action.payload;
      state.error = {};
    },
    [createWallet2WalletTransferMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteWallet.pending]: (state) => {
      state.loading = true;
    },
    [deleteWallet.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletList;
      const modifiedWalletList = {
        ...jsonState,
        list: jsonState.list?.filter((wallet) => wallet._id !== action.payload),
      };
      state.loading = false;
      state.walletList = modifiedWalletList;
      state.error = {};
    },
    [deleteWallet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // member wallet  address
    [getAllMemberWalletAddress.pending]: (state) => {
      state.loading = true;
    },
    [getAllMemberWalletAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletAddress = action.payload;
      state.error = {};
    },
    [getAllMemberWalletAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearWalletError } = W2WTransferSlice.actions;

export default W2WTransferSlice.reducer;
