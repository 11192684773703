import PropTypes from 'prop-types';

import { Step, Stepper, StepLabel } from '@mui/material';

// ----------------------------------------------------------------------

const W2WBoardingStepper = ({ steps, activeStep }) => (
  <Stepper activeStep={activeStep} alternativeLabel className="w2w-stepper">
    {steps.map((label, index) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);
export default W2WBoardingStepper;
W2WBoardingStepper.propTypes = {
  steps: PropTypes.any,
  activeStep: PropTypes.any,
};
