import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'; // components
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
// @mui
import {
  Box,
  Card,
  Stack,
  Dialog,
  Button,
  Divider,
  TextField,
  Typography,
  DialogTitle,
  Autocomplete,
  DialogContent,
} from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { decimalLimit } from 'src/server/api/http';
import { getBeneficiaryMembers } from 'src/server/api/member';
import { activateNewMember } from 'src/server/api/W2WTransfer';
import { ResentOtpApi, verifyOtpApi } from 'src/server/api/auth';

import Label from 'src/components/label';
import W2WBoardingStepper from 'src/components/W2Wstepper';
import { useSettingsContext } from 'src/components/settings';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

ActivateNewMember.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenSuccessModal: PropTypes.func,
};

export default function ActivateNewMember({ open, setOpen, setOpenSuccessModal }) {
  // const theme = useTheme();
  const dispatch = useDispatch();
  const { themeMode } = useSettingsContext();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Choose Member ', 'Amount', 'Verification'];
  const [selectedMember, setSelectedMember] = useState('');
  const [searchMember, setSearchMember] = useState('');
  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const {
    membersList,
    settingsData,
    memberProfileDetails,
    otpLoading,
    loading,
    // memberWalletAddress: memberWalletAddressDetails,
    // memberWalletAddressList,
  } = useSelector((state) => ({
    loading: state.W2WTransfer.loading,
    membersList: state.member?.beneficiaryMembers?.members,
    memberProfileDetails: state.member.memberProfileDetails.member,
    settingsData: state.settings.settingsDetails,
    otpLoading: state.auth?.loading,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowSuccess = () => {
    setOpenSuccessModal(true);
  };

  const isVerified = useBoolean();

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 1) {
      const otpResetCrud = {
        state: {
          email: memberProfileDetails?.email,
        },
        type: 'resend_otp',

        dispatch,
      };
      dispatch(ResentOtpApi(otpResetCrud));
    }
  };
  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    isVerified?.onFalse();
  };
  // const router = useRouter();

  useEffect(() => {
    const data = {
      page: '',
      search: searchMember,
      dispatch,
      limit: '',
      sort: 'UNPAID',
    };
    dispatch(getBeneficiaryMembers(data));
  }, [dispatch, searchMember]);

  const optionMembers = [
    { label: 'All', value: null },
    ...(membersList || []).map((item) => ({
      name: item?.name,
      member_id: item?.member_id,
      username: item?.username,
      label: item?.name,
      value: item?._id,
    })),
  ];

  const handleTextFieldChangeMember = (event) => {
    setSearchMember(event.target.value);
    // setPage();
  };

  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedMember(newValue || '');
    // setPage();
  };
  // const OtpSchema = Yup.object().shape({
  //   otp: Yup.string().min(4, 'OTP must be at least 4 digits').required('OTP is required'),
  // });

  const schema = Yup.object().shape({
    beneficiary_member: Yup.string(),
    amount: Yup.number(),
  });

  const defaultValues = useMemo(
    () => ({
      beneficiary_member: '',
      amount: '',
    }),
    []
  );
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  console.log(errors);

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
  });

  const handleSubmitMember = () => {
    if (isVerified?.value === true) {
      const credentialsUpdate = {
        state: {
          // memberId: memberProfileDetails?.member_id,
          beneficiary_member: selectedMember?.value,
          amount: settingsData?.membership_amount,
        },
        dispatch,
        handleClose,
        handleShowSuccess,
      };
      dispatch(activateNewMember(credentialsUpdate));
    }
  };

  const otpField = watch('OTP');

  const handleVerifyOtp = () => {
    const otpCrud = {
      state: {
        otp: String(otpField),
        userId: memberProfileDetails?.user?._id,
      },
      dispatch,
      isVerified,
      enqueueSnackbar,
    };
    dispatch(verifyOtpApi(otpCrud));
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="activate-member-modal"
      >
        {/* <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton> */}
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ textAlign: 'center', color: '#22C55E', px: 0 }}
        >
          <W2WBoardingStepper activeStep={activeStep} steps={steps} />
        </DialogTitle>
        <Divider
          sx={{
            width: '100%',
            // border: '1px solid rgb(207 207 207 / 20%)',
            // borderColor: 'red',
          }}
        />
        <DialogContent
          sx={{
            minWidth: isMobile ? 'auto' : '444px',
            maxWidth: isMobile ? 'auto' : '444px',
            padding: '0px',
            p: 2,
          }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {activeStep === 0 && (
              <Stack pt={0} minHeight="250px">
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: '100%' }}
                  options={optionMembers}
                  autoHighlight
                  // size="small"
                  // className="small-select"
                  defaultValue={selectedMember?.value && selectedMember}
                  getOptionLabel={(option) => option.label}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    return options.filter((option) =>
                      [
                        option?.label,
                        option?.member_id,
                        option?.username,
                        option.email,
                        option.phone,
                      ].some((attr) => attr?.toLowerCase().includes(inputValue))
                    );
                  }}
                  onChange={handleAutocompleteChangeMember}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label} {option.member_id && `(${option.member_id})`}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Member"
                      placeholder="Select..."
                      onChange={handleTextFieldChangeMember}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-member',
                      }}
                    />
                  )}
                />
              </Stack>
            )}
            {activeStep === 1 && (
              <Stack minHeight="250px" pt={0.5}>
                <Box
                  sx={{
                    backgroundColor: '#F8F4F2',
                    p: 2,
                    borderRadius: '9px',
                  }}
                >
                  <Stack direction="row" alignItems="center" justifyContent="center">
                    <InfoIcon
                      sx={{
                        color: '#B76E00',
                      }}
                    />
                    <Typography ml={2} variant="body2" color="#212B36">
                      You’re activating a new member by paying the activation fee of 77 USDT from
                      your buks wallet.
                    </Typography>
                  </Stack>
                </Box>
                <Stack
                  pt="40px"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Stack>
                    <Typography
                      variant="body2"
                      color={themeMode === 'dark' ? '' : '#212B36'}
                      pb={1}
                    >
                      Activation Fee
                    </Typography>
                    <Typography fontSize="36px" fontWeight={700} color="black">
                      {settingsData?.membership_amount} {USDT}
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                      <Label
                        sx={{
                          width: 'fit-content',
                          textAlign: 'center',
                          backgroundColor: '#00A76F29',
                          color: '#007867',
                          fontWeight: 500,
                        }}
                      >
                        Bucks Wallet Balance:{' '}
                        {memberProfileDetails?.buks_wallet_balance?.toFixed(decimalLimit)} {USDT}
                      </Label>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
            {activeStep === 2 && (
              <Box>
                <Typography variant="subtitle2" color="text.secondary" pb={0.5}>
                  Activation Details
                </Typography>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: themeMode === 'dark' ? '' : '#F4F6F8',
                    boxShadow: 'none',
                    px: 2,
                    py: 1.5,
                  }}
                >
                  <Stack direction="column">
                    <Typography variant="caption" color="text.secondary">
                      Member
                    </Typography>
                    <Typography pt={0.3} variant="subtitle2" color="text.primary" fontWeight={600}>
                      {selectedMember?.name}
                    </Typography>
                    <Label
                      sx={{
                        mt: 0.5,
                        backgroundColor: '#00B8D929',
                        color: '#006C9C',
                        borderRadius: '5px',
                        width: 'fit-content',
                      }}
                    >
                      {selectedMember?.member_id}
                    </Label>
                  </Stack>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: themeMode === 'dark' ? '' : '#F4F6F8',
                    boxShadow: 'none',
                    p: 2,
                    mt: 2,
                  }}
                >
                  <Stack direction="column">
                    <Typography variant="caption" color="text.secondary" pb={0.3}>
                      Amount
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary">
                      {settingsData?.membership_amount} {USDT}
                    </Typography>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    backgroundColor: themeMode === 'dark' ? '' : '#F2F8F6',
                    boxShadow: 'none',
                    p: '12px',
                    mt: 4,
                    borderRadius: '9px',
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    {' '}
                    <InfoIcon
                      sx={{
                        color: '#00A76F',
                      }}
                    />
                    <Typography pl={1} color="text.secondary" variant="body2">
                      An OTP has been sent to your email
                    </Typography>
                  </Stack>
                </Card>
                <Stack py={2}>
                  <Stack direction="row" alignItems="center" justifyContent="start">
                    <Stack width="100%">
                      <RHFTextField
                        name="OTP"
                        label="Enter OTP here"
                        type="text"
                        sx={{
                          width: '100%',
                        }}
                        // placeholder="Transaction Hash"
                        // defaultValue={isUpdate?.member?.payment_transaction_id}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{
                        //   readOnly: !!view,
                        // }}
                      />
                    </Stack>
                    <Stack
                      sx={{
                        width: 'fit-content',
                        ml: 1,
                      }}
                    >
                      <LoadingButton
                        size="large"
                        disabled={!otpField || isVerified?.value === true}
                        onClick={handleVerifyOtp}
                        variant="contained"
                        loading={otpLoading}
                        sx={{
                          backgroundColor: isVerified?.value !== true ? '#007867' : '#22C55E14',
                          color: isVerified?.value !== true ? 'white' : '#118D57',
                          '&:hover': {
                            backgroundColor: isVerified?.value !== true ? '#007867' : '#22C55E28',
                            color: isVerified?.value !== true ? 'white' : '#0A6E43',
                          },
                        }}
                      >
                        {isVerified?.value === true ? 'Verified' : 'Verify'}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            )}
          </FormProvider>
        </DialogContent>
        <Divider
          sx={{
            width: '100%',
            borderStyle: 'dashed',
          }}
        />
        {/* <DialogActions> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={0}
          spacing={1}
          p={2}
          py={3}
        >
          <Stack>
            {activeStep > 0 && (
              <Button
                onClick={handlePreviousStep}
                sx={{
                  width: 'fit-content',
                }}
                startIcon={
                  <KeyboardArrowLeftIcon
                    sx={{
                      fontSize: 12,
                    }}
                  />
                }
              >
                Back
              </Button>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="end" spacing={1}>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Cancel
            </Button>

            {activeStep === 2 ? (
              <LoadingButton
                // type="submit"
                disabled={!isVerified?.value === true}
                onClick={handleSubmitMember}
                variant="contained"
                loading={loading}
                sx={{
                  color: 'white',
                  width: 'fit-content',
                  marginLeft: 'auto',
                  backgroundColor: '#007867',
                  '&:hover': {
                    backgroundColor: '#007867',
                  },
                }}
              >
                Activate
              </LoadingButton>
            ) : (
              <Button
                disabled={activeStep === 0 && !selectedMember?.value}
                onClick={handleNextStep}
                variant="outlined"
                sx={{
                  backgroundColor: '#007867',
                  color: 'white',
                  width: '60px',
                  '&:hover': {
                    backgroundColor: '#007867',
                  },
                }}
              >
                Next
              </Button>
            )}
          </Stack>
        </Stack>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
