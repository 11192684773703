import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
// components
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, Button, Divider, DialogTitle, DialogContent } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllMembers } from 'src/server/api/member';
import { createManualTransactions } from 'src/server/api/transaction';

import FormProvider from 'src/components/hook-form/form-provider';

import { FromTo } from './fromTo';
import { SummaryData } from './summaryData';

ManualWalletTransactionModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.func,
};

export default function ManualWalletTransactionModalDialog({ open, setOpen, isUpdate }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const [tab, setTab] = useState(0);
  const { loading, membersList, settingsData } = useSelector((state) => ({
    loading: state?.transaction?.loading,
    membersList: state.member.members?.members,
    settingsData: state.settings.settingsDetails,
  }));

  const [fromActiveButton, setFromActiveButton] = useState('COMPANY');
  const [toActiveButton, setToActiveButton] = useState('MEMBER');

  const handleClose = () => {
    setOpen(false);
  };

  const transactionSchema = Yup.object().shape({
    amount: Yup.string(),
    particulars: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      amount: '',
      particulars: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(transactionSchema),
    defaultValues,
  });

  const { handleSubmit, reset, watch } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        ...data,
        amount: Number(data?.amount),
        from_member: fromActiveButton === 'MEMBER' ? selectedFrom?.value : null,
        from_wallet_type: fromActiveButton,
        to_member: toActiveButton === 'MEMBER' ? selectedTo?.value : null,
        to_wallet_type: toActiveButton,
        ...(fromActiveButton === 'COMPANY' && { from_company_wallet: selectedFrom?.value }),
        ...(toActiveButton === 'COMPANY' && { to_company_wallet: selectedTo?.value }),
      },
      dispatch,
      handleClose,
      reset,
    };

    dispatch(createManualTransactions(credentials));
  });

  const handleNextStep = () => {
    setTab((prevActiveStep) => prevActiveStep + 1);
  };

  const companyValues = [
    {
      label: 'Future Projects',
      value: 'FUTURE_PROJECTS',
      balance: settingsData?.company_wallets?.future_project_wallet_amount,
    },
    {
      label: 'Maintenance',
      value: 'MAINTENANCE',
      balance: settingsData?.company_wallets?.maintenance_wallet_amount,
    },
    {
      label: 'Company',
      value: 'COMPANY',
      balance: settingsData?.company_wallets?.company_wallet_amount,
    },
  ];

  const [selectedFrom, setSelectedFrom] = useState();
  const [searchFrom, setSearchFrom] = useState('');

  const handleAutocompleteChangeFrom = (e, newValue) => {
    setSelectedFrom(newValue || {});
  };

  const handleSearchChangeFrom = (value) => {
    setSearchFrom(value);
  };

  const handleRenderOptionsFrom = () => {
    const optionCompany = [
      ...(companyValues || []).map((results) => ({
        label: results?.label,
        value: results?.value,
        wallet_balance: results?.balance,
      })),
    ];
    const optionMember = [
      ...(membersList || []).map((results) => ({
        label: results?.name,
        value: results?._id,
        wallet_balance: results?.buks_wallet_balance,
        member_id: results?.member_id,
        email: results?.email,
        phone: results?.phone,
        username: results?.user?.username,
      })),
    ];
    if (fromActiveButton === 'MEMBER') {
      return optionMember;
    }
    return optionCompany;
  };

  const [selectedTo, setSelectedTo] = useState();
  const [searchTo, setSearchTo] = useState('');

  const handleAutocompleteChangeTo = (e, newValue) => {
    setSelectedTo(newValue || {});
  };

  const handleSearchChangeTo = (value) => {
    setSearchTo(value);
  };

  const handleRenderOptionsTo = () => {
    const optionCompany = [
      ...(companyValues || []).map((results) => ({
        label: results?.label,
        value: results?.value,
        wallet_balance: results?.balance,
      })),
    ];
    const optionMember = [
      ...(membersList || []).map((results) => ({
        label: results?.name,
        value: results?._id,
        wallet_balance: results?.buks_wallet_balance,
        member_id: results?.member_id,
        email: results?.email,
        phone: results?.phone,
        username: results?.user?.username,
      })),
    ];
    if (toActiveButton === 'MEMBER') {
      return optionMember;
    }
    return optionCompany;
  };

  useEffect(() => {
    if (fromActiveButton === 'MEMBER' || toActiveButton === 'MEMBER') {
      const credentials = {
        page: 1,
        search: searchFrom || searchTo,
        limit: 10,
      };
      dispatch(getAllMembers(credentials));
    }
  }, [dispatch, searchFrom, fromActiveButton, searchTo, toActiveButton]);

  const handleChangeSearchFrom = () => {
    setSearchFrom('');
  };

  const handleChangeSearchTo = () => {
    setSearchTo('');
  };

  const buttons = [
    { label: 'Company', value: 'COMPANY' },
    { label: 'Member', value: 'MEMBER' },
  ];

  const amount = watch('amount');

  const handleFromButton = (value) => {
    setFromActiveButton(value);
    setSelectedFrom();
  };

  const handleToButton = (value) => {
    setToActiveButton(value);
    setSelectedTo();
  };

  const fromToData = {
    buttons,
    fromActiveButton,
    toActiveButton,
    handleFromButton,
    handleToButton,
    selectedFrom,
    handleChangeSearchFrom,
    handleAutocompleteChangeFrom,
    handleSearchChangeFrom,
    handleRenderOptionsFrom,
    selectedTo,
    handleChangeSearchTo,
    handleAutocompleteChangeTo,
    handleSearchChangeTo,
    handleRenderOptionsTo,
  };

  const summaryData = {
    amount,
    selectedFrom,
    selectedTo,
    fromActiveButton,
    toActiveButton,
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="manual-wallet-transactions-modal"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          {isUpdate?._id ? 'Edit Manual Transaction' : 'New Manual Transaction'}
        </DialogTitle>
        <Divider
          sx={{
            mt: 1,
            width: '100%',
            borderStyle: 'dashed',
          }}
        />
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '444px', padding: '0px' }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {tab === 0 && <FromTo data={fromToData} />}
            {tab === 1 && <SummaryData data={summaryData} />}
            <Divider
              sx={{
                width: '100%',
                borderStyle: 'dashed',
              }}
            />
            <Stack p={2} direction="row" alignItems="center" justifyContent="end" spacing={1}>
              <Button color="inherit" variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              {tab === 1 ? (
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  disabled={!amount || amount > selectedFrom?.wallet_balance}
                  sx={{
                    backgroundColor: '#007867',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#007867',
                      color: 'white',
                    },
                  }}
                  type="submit"
                >
                  Transfer
                </LoadingButton>
              ) : (
                <Button
                  onClick={handleNextStep}
                  disabled={!(selectedFrom?.value && selectedTo?.value)}
                  sx={{
                    backgroundColor: '#007867',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#007867',
                      color: 'white',
                    },
                  }}
                  variant="contained"
                >
                  Next
                </Button>
              )}
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
