/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'; // components
// eslint-disable-next-line import/no-extraneous-dependencies
import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';

import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// @mui
import {
  Box,
  Card,
  Stack,
  Dialog,
  Button,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { ResentOtpApi, verifyOtpApi } from 'src/server/api/auth';
// import { countryCodes } from 'src/assets/data/countryCodes';
import { referralIdCheckApi, createMemberAccountMember } from 'src/server/api/member';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { RHFPhoneInput } from 'src/components/hook-form/rhf-phone-input';

CreateMemberAccountModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleShow: PropTypes.any,
};

export default function CreateMemberAccountModalDialog({ open, setOpen, handleShow }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useResponsive('down', 'md');
  const isVerified = useBoolean();

  console.log(isVerified);

  const { loadingAccount, ReferralId, MemberProfileDetails, otpLoading } = useSelector((state) => ({
    ReferralId: state.member.ReferralId,
    loadingAccount: state.member.loadingAccount,
    MemberProfileDetails: state.member.memberProfileDetails.member,
    otpLoading: state.auth?.loading,
  }));
  // console.log(loadingAccount);
  const [referralIdCheck, setReferralIdCheck] = useState('');
  const [selectedCode, setSelectedCode] = useState('91');

  // const [selectedCountryCode, setSelectedCountryCode] = useState('');
  console.log(MemberProfileDetails);
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    referral_id: Yup.string(),
    phone: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      referral_id: MemberProfileDetails?.member_id || '',
    }),
    [MemberProfileDetails?.member_id]
  );
  console.log(defaultValues);
  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
    setValue,
  } = methods;
  const isChecked = watch('is_direct_wallet_transfer');

  // const optionCountry = [
  //   ...(countryCodes || []).map((results) => ({
  //     label: `${results?.code} ${results?.name}`,
  //     // label: results?.code,
  //     value: results?.code,
  //   })),
  // ];
  // const handleAutocompleteChangePhone = (e, newValue) => {
  //   setSelectedCountryCode(newValue?.value || '');
  // };

  const onSubmit = handleSubmit(async (data) => {
    const memberDataCredential = {
      state: {
        // ...data,
        name: data?.name,
        referral_id: referralIdCheck,
        phone: parsePhoneNumberFromString(data?.phone)?.nationalNumber,

        country_code: selectedCode || '',
        is_direct_wallet_transfer: data?.is_direct_wallet_transfer,
      },
      dispatch,
      enqueueSnackbar,
      handleClose,
      setCurrentTab,
      currentTab,
      isChecked,
      handleShow,
    };

    dispatch(createMemberAccountMember(memberDataCredential));
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleReferralIdChange = (event) => {
    const { value } = event.target;
    // console.log('User Name Value:', value);
    setReferralIdCheck(value);
  };
  console.log(referralIdCheck);
  useEffect(() => {
    if (MemberProfileDetails?.member_id) {
      setReferralIdCheck(MemberProfileDetails?.member_id);
    }
  }, [MemberProfileDetails?.member_id]);
  useEffect(() => {
    if (referralIdCheck && referralIdCheck?.length >= 6) {
      const credential = {
        state: {
          referral_id: referralIdCheck.toUpperCase(),
        },
      };
      dispatch(referralIdCheckApi(credential));
    }
  }, [dispatch, referralIdCheck]);
  const handlePhoneInputChange = (value, inputName) => {
    const country_code = getCountryCallingCode(value);
    setSelectedCode(country_code);
  };

  const phone = watch('phone');
  const phoneNumberCheck = phone && parsePhoneNumberFromString(phone)?.nationalNumber;
  // console.log('phone', phone&& parsePhoneNumberFromString(phone)?.nationalNumber)

  const [currentTab, setCurrentTab] = useState(0);

  // const handleChangeTab = useCallback((event, newValue) => {
  //   setCurrentTab(newValue);
  // }, []);

  console.log('currentTa b=>', currentTab);

  const handlePreviousStep = () => {
    setCurrentTab((prevActiveStep) => prevActiveStep - 1);
    isVerified?.onFalse();
    setValue('OTP', '');
  };

  const handleChangeTab = (value) => {
    const newTab = value + 1; // Calculate the new tab value
    setCurrentTab(newTab);

    // Check the tab condition using the new value
    if (newTab === 1) {
      const otpResetCrud = {
        state: {
          email: MemberProfileDetails?.email,
        },
        type: 'resend_otp',
        dispatch,
      };
      dispatch(ResentOtpApi(otpResetCrud));
    }
  };

  const otpField = watch('OTP');

  const handleVerifyOtp = () => {
    if (!isVerified?.value === true) {
      const otpCrud = {
        state: {
          otp: String(otpField),
          userId: MemberProfileDetails?.user?._id,
        },
        dispatch,
        isVerified,
        enqueueSnackbar,
      };
      dispatch(verifyOtpApi(otpCrud));
    }
  };
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        // className="new-member-modal"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>

        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          Add New Member{' '}
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{
            minWidth: isMobile ? '200px' : currentTab === 1 ? '342px' : '450px',
            padding: '0px',
          }}
        >
          {/* {currentTab === 2 ? (
            <Stack textAlign="center" spacing={2} pt={3}>
              <Stack direction="row" alignItems="center" justifyContent="center">
                <CardMedia
                  component="img"
                  src={coin}
                  sx={{
                    height: '80px',
                    width: '90px',
                    objectFit: 'contain',
                  }}
                />
              </Stack>
              <Stack>
                <Typography fontSize="24px" fontWeight={600} color="#212B36" lineHeight="28px">
                  Member creation successfully initiated.{' '}
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight={400}
                  color="text.disabled"
                  pt={2}
                  px={6}
                  pb={3}
                >
                  Please wait a few moments for the process to complete and reflect in the system.
                </Typography>
              </Stack>
            </Stack>
          ) : (
            ''
          )} */}

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
              <Stack>
                <Card sx={{ p: 3, display: 'flex', spacing: 2, flexDirection: 'column' }}>
                  {currentTab === 0 && (
                    <>
                      <Stack flexDirection="column">
                        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={3}>
                          <RHFTextField
                            name="name"
                            label="Name"
                            required
                            placeholder="Enter full name"
                            InputLabelProps
                          />
                          <Stack>
                            <RHFTextField
                              name="referral_id"
                              label="Referral Id"
                              placeholder="Enter Referral Id"
                              InputLabelProps
                              required
                              onChange={handleReferralIdChange}
                              value={referralIdCheck}
                              inputProps={{ style: { textTransform: 'uppercase' } }}
                            />
                            {referralIdCheck && (
                              <>
                                {ReferralId === false && referralIdCheck?.length >= 6 && (
                                  <Typography
                                    color="#FF5630"
                                    fontSize={12}
                                    fontWeight={400}
                                    display="flex"
                                    alignItems="center"
                                    ml={2}
                                  >
                                    <WarningIcon
                                      sx={{
                                        fontSize: '14px',
                                        marginRight: '4px',
                                        color: '#FF5630',
                                      }}
                                    />{' '}
                                    Invalid Referral ID{' '}
                                  </Typography>
                                )}
                                {ReferralId === true && referralIdCheck?.length >= 6 && (
                                  <Typography
                                    sx={{
                                      color: 'success.main',
                                      fontSize: '10px',
                                      fontWeight: '600',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    Verified{' '}
                                    <CheckCircleIcon sx={{ fontSize: '14px', marginLeft: '4px' }} />
                                  </Typography>
                                )}
                              </>
                            )}
                          </Stack>
                          {/* <Stack spacing={1} flexDirection="row">
                    <Autocomplete
                      id="country-select-demo"
                      sx={{ width: '50%' }}
                      options={optionCountry}
                      autoHighlight
                      size="medium"
                      required
                      // className="small-select"
                      getOptionLabel={(option) => option.label}
                      onChange={handleAutocompleteChangePhone}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          required
                          // onChange={handleTextFieldChangeCourse}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />
                      )}
                    />
                    <Stack width="100%">
                      <RHFTextField
                        sx={{ width: '100%' }}
                        name="phone"
                        type="number"
                        label="Phone"
                        placeholder="Enter phone number"
                        onInput={(e) => {
                          const input = e.target.value.replace(/\D/g, '');
                          const limitedInput = input.slice(0, 10);
                          e.target.value = limitedInput;
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Stack>
                  </Stack> */}
                          <RHFPhoneInput
                            name="phone"
                            label="Phone"
                            placeholder="Enter phone number"
                            required
                            setSelectedCode={(value) => handlePhoneInputChange(value, 'phone')}
                          />
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        pt={1}
                        pl={0.5}
                        className="add-member-checkbox"
                      >
                        <RHFCheckbox
                          name="is_direct_wallet_transfer"
                          label="Pay from my bucks wallet"
                        />

                        {/* <Typography variant="body2" color="text.primary">
                          Pay from my bucks wallet
                        </Typography> */}
                      </Stack>
                    </>
                  )}
                  {currentTab === 1 && isChecked === true ? (
                    <>
                      <Card
                        sx={{
                          backgroundColor: '#F2F8F6',
                          boxShadow: 'none',
                          p: '12px',
                          mt: 0,
                          borderRadius: '9px',
                        }}
                      >
                        <Stack direction="row" alignItems="center">
                          {' '}
                          <InfoIcon
                            sx={{
                              color: '#00A76F',
                            }}
                          />
                          <Typography pl={1} variant="body2">
                            Enter the 4-digit OTP sent to your
                            <br /> email.
                          </Typography>
                        </Stack>
                      </Card>
                      <Stack py={2}>
                        <Stack direction="row" alignItems="center" justifyContent="start">
                          <Stack width="100%">
                            <RHFTextField
                              name="OTP"
                              label="Enter OTP here"
                              type="text"
                              sx={{
                                width: '100%',
                              }}
                              // placeholder="Transaction Hash"
                              // defaultValue={isUpdate?.member?.payment_transaction_id}
                              InputLabelProps={{ shrink: true }}
                              // InputProps={{
                              //   readOnly: !!view,
                              // }}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              width: 'fit-content',
                              ml: 1,
                            }}
                          >
                            <LoadingButton
                              size="large"
                              disabled={!otpField}
                              onClick={handleVerifyOtp}
                              variant="contained"
                              loading={otpLoading}
                              sx={{
                                backgroundColor:
                                  isVerified?.value === false ? '#007867' : '#22C55E14',
                                color: isVerified?.value === false ? 'white' : '#118D57',
                                '&:hover': {
                                  backgroundColor:
                                    isVerified?.value === false ? '#007867' : '#22C55E14',
                                  color: isVerified?.value === false ? 'white' : '#118D57',
                                },
                              }}
                            >
                              {isVerified?.value === true ? 'Verified' : 'Verify'}
                            </LoadingButton>
                          </Stack>
                        </Stack>
                      </Stack>
                    </>
                  ) : (
                    ''
                  )}

                  <Stack spacing={3} sx={{ mt: 3 }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" pt={3}>
                      <Box>
                        {currentTab > 0 && isChecked === true ? (
                          <Button
                            onClick={handlePreviousStep}
                            sx={{
                              width: 'fit-content',
                            }}
                            startIcon={
                              <KeyboardArrowLeftIcon
                                sx={{
                                  fontSize: 12,
                                }}
                              />
                            }
                          >
                            Back
                          </Button>
                        ) : (
                          ''
                        )}
                      </Box>
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          sx={{
                            mr: 1,
                            px: isMobile ? 0 : 2,
                            backgroundColor: '#fff',
                            '&:hover': {
                              backgroundColor: '#fff',
                            },
                            color: '#000',
                          }}
                        >
                          Close
                        </Button>
                        {currentTab === 0 && isChecked === true ? (
                          <LoadingButton
                            onClick={() => handleChangeTab(0)}
                            variant="contained"
                            disabled={!phoneNumberCheck}
                            loading={isSubmitting || loadingAccount}
                            sx={{
                              color: 'white',
                              width: 'fit-content',
                              marginLeft: 'auto',
                              backgroundColor: '#007867',
                              '&:hover': {
                                backgroundColor: '#08443b',
                              },
                            }}
                          >
                            Next
                          </LoadingButton>
                        ) : (
                          <>
                            {isChecked === true ? (
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                disabled={!isVerified?.value === true}
                                loading={isSubmitting || loadingAccount}
                                sx={{
                                  color: 'white',
                                  width: 'fit-content',
                                  marginLeft: 'auto',
                                  backgroundColor: '#007867',
                                  '&:hover': {
                                    backgroundColor: '#08443b',
                                  },
                                }}
                              >
                                Add Member{' '}
                              </LoadingButton>
                            ) : (
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                disabled={!phoneNumberCheck}
                                loading={isSubmitting || loadingAccount}
                                sx={{
                                  color: 'white',
                                  width: 'fit-content',
                                  marginLeft: 'auto',
                                  backgroundColor: '#007867',
                                  '&:hover': {
                                    backgroundColor: '#08443b',
                                  },
                                }}
                              >
                                Add Member{' '}
                              </LoadingButton>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Stack>
                </Card>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
        {/* {currentTab === 2 ? (
          <DialogActions>
            <Stack>
              <Button
                fullWidth
                sx={{
                  backgroundColor: '#007867',
                  color: 'white',
                  py: 1,
                  '&:hover': {
                    backgroundColor: '#007867',
                  },
                }}
                onClick={handleClose}
              >
                Got it
              </Button>
            </Stack>
          </DialogActions>
        ) : (
          ''
        )} */}
      </Dialog>
    </div>
  );
}
