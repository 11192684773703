import PropTypes from 'prop-types';

import { Box, Stack, TextField, Autocomplete, InputAdornment } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function MemberW2WToolbar({
  onSearch,
  date,
  setDate,
  setPage,
  optionMembers,
  handleAutocompleteChangeMember,
  handleTextFieldChangeMember,
}) {
  const isMobile = useResponsive('down', 'md');

  return (
    <>
      <Stack direction={isMobile ? 'column' : 'row'} alignItems="center" spacing={2}>
        <TextField
          sx={{ width: '100%', pt: isMobile && 1 }}
          onChange={(e) => {
            onSearch(e.target.value);
            setPage();
          }}
          placeholder="Search by Transfer ID"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        <Stack width={isMobile ? '100%' : '50%'}>
          <Autocomplete
            id="country-select-demo"
            sx={{ width: '100%' }}
            options={optionMembers}
            autoHighlight
            getOptionLabel={(option) => option.label}
            filterOptions={(options, state) => {
              const inputValue = state.inputValue.toLowerCase();
              return options.filter((option) =>
                [
                  option?.label,
                  option?.member_id,
                  option?.username,
                  option.email,
                  option.phone,
                ].some((attr) => attr?.toLowerCase().includes(inputValue))
              );
            }}
            onChange={handleAutocompleteChangeMember}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label} {option.member_id && `(${option.member_id})`}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Beneficiary Member"
                onChange={handleTextFieldChangeMember}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-member',
                }}
              />
            )}
          />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'}>
          <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', minWidth: isMobile && 150, mr: 2 }}
            size="medium"
            type="date"
            variant="outlined"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                from: e.target.value,
              });
              setPage();
            }}
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%', minWidth: isMobile && 147 }}
            size="medium"
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                to: e.target.value,
              });
              setPage();
            }}
          />
        </Stack>
      </Stack>
      {/* <Stack direction="row" alignItems="center" spacing={2}>
        
      </Stack> */}
    </>
  );
}

MemberW2WToolbar.propTypes = {
  onSearch: PropTypes.any,
  date: PropTypes.any,
  setDate: PropTypes.any,
  setPage: PropTypes.any,
  handleAutocompleteChangeMember: PropTypes.any,
  optionMembers: PropTypes.any,
  handleTextFieldChangeMember: PropTypes.any,
};
