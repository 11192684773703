import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import {
  memberWalletAddress,
  getAllWithdrawalRequest,
  createWithdrawalRequest,
  updateWithdrawalRequest,
  getWithdrawalListPublic,
  createWithdrawalRequestV3,
  getAllWithdrawalRequestAdmin,
  updateWithdrawalRequestAdminV3,
  updateWithdrawalRequestValuesV3,
} from '../api/withdrawalRequest';

export const withdrawalRequestReducerSlice = createSlice({
  name: 'withdrawalRequestReducer',
  initialState: {
    loading: false,
    error: {},
    withdrawalRequests: {},
    adminWithdrawalRequests: {},
    memberWalletAddressList: {},
    withdrawalListPublic: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllWithdrawalRequest.pending]: (state) => {
      state.loading = true;
    },
    [getAllWithdrawalRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.withdrawalRequests = action.payload;
      state.error = {};
    },
    [getAllWithdrawalRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [createWithdrawalRequest.pending]: (state) => {
      state.loading = true;
    },
    [createWithdrawalRequest.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.withdrawalRequests;
      const newList = jsonState?.withdrawalRequests;
      const modifiedList = {
        ...jsonState,
        withdrawalRequests: Array.isArray(newList)
          ? [action.payload, ...newList]
          : [action.payload],
      };
      state.loading = false;
      state.withdrawalRequests = modifiedList;
      state.error = {};
    },
    [createWithdrawalRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // admin withdrawal request

    [getAllWithdrawalRequestAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getAllWithdrawalRequestAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.withdrawalRequests = action.payload;
      state.error = {};
    },
    [getAllWithdrawalRequestAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateWithdrawalRequest.pending]: (state) => {
      state.loading = true;
    },
    [updateWithdrawalRequest.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.withdrawalRequests;
      const modifiedList = {
        ...jsonState,
        withdrawalRequests: jsonState.withdrawalRequests?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.withdrawalRequests = modifiedList;
      state.error = {};
    },
    [updateWithdrawalRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // memberwalletaddress
    [memberWalletAddress.pending]: (state) => {
      state.loading = true;
    },
    [memberWalletAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberWalletAddressList = action.payload;
      state.error = {};
    },
    [memberWalletAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // v3 apiss
    [createWithdrawalRequestV3.pending]: (state) => {
      state.loading = true;
    },
    [createWithdrawalRequestV3.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.withdrawalRequests;
      const newList = jsonState?.withdrawalRequests;
      const modifiedList = {
        ...jsonState,
        withdrawalRequests: Array.isArray(newList)
          ? [action.payload, ...newList]
          : [action.payload],
      };
      state.loading = false;
      state.withdrawalRequests = modifiedList;
      state.error = {};
    },
    [createWithdrawalRequestV3.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update admin
    [updateWithdrawalRequestAdminV3.pending]: (state) => {
      state.loading = true;
    },
    [updateWithdrawalRequestAdminV3.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.withdrawalRequests;
      const modifiedList = {
        ...jsonState,
        withdrawalRequests: jsonState.withdrawalRequests?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.withdrawalRequests = modifiedList;
      state.error = {};
    },
    [updateWithdrawalRequestAdminV3.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // admin withdrawal request values
    [updateWithdrawalRequestValuesV3.pending]: (state) => {
      state.loading = true;
    },
    [updateWithdrawalRequestValuesV3.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.withdrawalRequests;
      const modifiedList = {
        ...jsonState,
        withdrawalRequests: jsonState.withdrawalRequests?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.withdrawalRequests = modifiedList;
      state.error = {};
    },
    [updateWithdrawalRequestValuesV3.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // withdrawal-list public
    [getWithdrawalListPublic.pending]: (state) => {
      state.loading = true;
    },
    [getWithdrawalListPublic.fulfilled]: (state, action) => {
      state.loading = false;
      state.withdrawalListPublic = action.payload;
      state.error = {};
    },
    [getWithdrawalListPublic.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = withdrawalRequestReducerSlice.actions;

export default withdrawalRequestReducerSlice.reducer;
