import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import {
  Card,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from '@mui/material';

import { getAllMemberAccountAdmin } from 'src/server/api/member';

// ----------------------------------------------------------------------

export default function MemberAccountsAdmin({ memberDetails }) {
  const { memberAccounts } = useSelector((state) => ({
    memberAccounts: state.member.memberAccounts,
  }));
  const dispatch = useDispatch();
  // const params = useParams();

  // console.log(memberDetails?.user?._id);
  useEffect(() => {
    if (memberDetails?.user?._id) {
      const credentials = {
        memberId: memberDetails?.user?._id,
        dispatch,
      };
      dispatch(getAllMemberAccountAdmin(credentials));
    }
  }, [dispatch, memberDetails?.user?._id]);
  // useEffect(() => {
  //   const credentials = {
  //     memberId: memberDetails?.user?._id,
  //     dispatch,
  //   };
  //   dispatch(getAllMemberAccountAdmin(credentials));
  // }, [dispatch, memberDetails?.user?._id, params.id]);

  // const data = [
  //   { account: 'Jase smit', id: '1', primary: true },
  //   { account: 'jake ube', id: '2' },
  //   { account: 'mohan ravi', id: '3' },
  // ];
  const getInitials = (name) => {
    const names = name?.split(' ');
    return names?.map((n) => n.charAt(0).toUpperCase()).join('');
  };
  const accounts = Array.isArray(memberAccounts) ? memberAccounts : [];

  return (
    <>
      <Stack
        spacing={3}
        mb={2}
        direction={{ xs: 'row', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        justifyContent="space-between"
      >
        <Typography variant="h6">Member Accounts</Typography>
      </Stack>
      <Card
        variant="outlined"
        sx={{
          py: 0,
          // minHeight: 300,
          // border: settings?.themeMode === 'dark' ? '' : '1px solid #e3e7ea',
          boxShadow: 'unset',
          height: '100%',
        }}
      >
        <TableContainer
          sx={{
            maxHeight: 280,
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Accounts</TableCell>
                <TableCell>ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ p: 1 }}>
                    <Stack direction="row" alignItems="center" gap={1} pl={2}>
                      <Avatar
                        sx={{
                          backgroundColor: item?.is_primary ? '#8431AD' : '#31AD4C',
                          width: 30,
                          height: 30,
                          borderRadius: 1,
                        }}
                        src={item?.profile_photo?.url || 'default-profile_photo-url'}
                      >
                        <Typography variant="caption">{getInitials(item?.name)}</Typography>
                      </Avatar>
                      <Typography variant="body2">{item?.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ p: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                        maxWidth: 150,
                      }}
                    >
                      {item?.member_id}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

MemberAccountsAdmin.propTypes = {
  memberDetails: PropTypes.any,
};
