import * as React from 'react';
import PropTypes from 'prop-types';

// @mui
import {
  Stack,
  Dialog,
  Button,
  CardMedia,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import coin from 'src/assets/other/Coin.png';
// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

W2WTransferSuccessModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleClose: PropTypes.func,
};

export default function W2WTransferSuccessModal({ open, setOpen, handleClose }) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="activate-member-success-modal"
      >
        {/* <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton> */}

        <DialogContent sx={{ minWidth: isMobile ? '250px' : '444px', padding: '0px', p: 3 }}>
          <Stack textAlign="center" spacing={2} pt={3}>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <CardMedia
                component="img"
                src={coin}
                sx={{
                  height: '80px',
                  width: '90px',
                  objectFit: 'contain',
                }}
              />
            </Stack>
            <Stack>
              <Typography fontSize="24px" fontWeight={600} color="#212B36" lineHeight="28px">
                Your transfer has been initiated successfully.{' '}
              </Typography>
              <Typography variant="subtitle2" fontWeight={400} color="text.disabled" pt={2} px={2}>
                Please allow a few moments for the transfer to be processed and reflect in your
                account.
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack>
            <Button
              fullWidth
              sx={{
                backgroundColor: '#007867',
                color: 'white',
                py: 1,
                '&:hover': {
                  backgroundColor: '#007867',
                },
              }}
              onClick={handleClose}
            >
              Got it
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}
