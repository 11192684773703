/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { Box, Card, Button, CardMedia, Typography } from '@mui/material';

import { useParams } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
// import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { bgGradient } from 'src/theme/css';
import msg from 'src/assets/other/msg.png';
import edit from 'src/assets/other/edit.png';
// import club1 from 'src/assets/club/CLUB.png';
// import club2 from 'src/assets/club/UJIN.png';
// import club3 from 'src/assets/club/CIAN.png';
// import club4 from 'src/assets/club/KIAH.png';
import user from 'src/assets/other/user.png';
// import club5 from 'src/assets/club/SAYAN.png';
import gmail from 'src/assets/other/gmail.png';
import refer from 'src/assets/other/reffer.png';
import lock from 'src/assets/other/lock-new.png';
import wallet from 'src/assets/other/buks-img.png';
import phone from 'src/assets/other/phone-icon.png';
import { memberBlock } from 'src/server/api/member';
import { frontEndUrl, decimalLimit } from 'src/server/api/http';

import TextMaxLine from 'src/components/text-max-line';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';

import FlagDialog from 'src/sections/Member/MemberSingleView/flagDialog';
import SuspendDialog from 'src/sections/Member/MemberSingleView/suspendDialog';

export default function MemberRefiredCardNew({
  memberDetails,
  setOpenModalPasswordDialog,
  setOpenModalDialog,
  data,
  memberID,
  setOpenUpdateMemberEmail,
}) {
  const { isBlocked, isSuspented, MemberProfileDetails } = useSelector((state) => ({
    flagMessage: state.member.memberDetails?.note,
    isBlocked: state.member.memberDetails?.is_blocked,
    isSuspented: state.member.memberDetails?.is_withdrawal_suspended,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  // console.log(memberDetails?.member_id);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);

  const role = useGetRoles()?.role;
  // const clubImages = [club1, club2, club3, club4, club5];
  // const clubImages = {
  //   BENE: club1,
  //   UJIN: club2,
  //   CIAN: club3,
  //   KIAH: club4,
  //   SAYAN: club5,
  // };
  // const currentClubTitle = memberDetails?.current_club?.club?.title;

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const settings = useSettingsContext();

  const confirm = useBoolean();
  const dispatch = useDispatch();
  const params = useParams();
  // const isMobile = useResponsive('down', 'md');

  // Block_Unblock_Member
  const Block_Unblock_Member = () => {
    const credentials = {
      dispatch,
      memberId: params?.id,
      state: {
        is_blocked: !isBlocked,
      },
    };
    dispatch(memberBlock(credentials));
  };

  const handleClick = () => {
    const memberId = memberDetails?.member_id;
    const link = `${paths.auth.register}?ref_id=${memberId}`;
    if (memberId) {
      window.open(link, '_blank');
    } else {
      console.warn('Member ID not available for referral link.');
    }
  };

  const handleIsPrimaryChecking = () => {
    if (role === 'admin') {
      const value = memberDetails?.is_primary ? 'Primary' : 'Secondary';
      return value;
    }
    const value = MemberProfileDetails?.is_primary ? 'Primary' : 'Secondary';
    return value;
  };

  return (
    <>
      {role === 'member' && (
        // <Stack
        //   direction={{ xs: 'column', md: 'row' }}
        //   sx={{
        //     ...bgGradient({
        //       color: alpha('#0a0909cc', 0.7),
        //       imgUrl: coverUrl,
        //     }),
        //     // backgroundColor: '#FFE0A3',
        //     // height: '201px',
        //     // height: isMobile ? '458px' : '100%',
        //     height: '100%',
        //     width: '100%',
        //     color: 'common.white',
        //     // border: '2px solid orange',
        //     borderRadius: '15px',
        //   }}
        //   justifyContent="space-between"
        // >
        //   <Stack
        //     // border='2px solid orange'
        //     justifyContent="space-between"
        //     p={2}
        //   >
        //     <Stack
        //       sx={{
        //         // position: 'absolute',
        //         // top: 60,
        //         // left: 16,
        //         color: 'common.white',
        //       }}
        //       // direction='row'
        //     >
        //       <Typography variant="body2">Current Club</Typography>
        //       <Typography
        //         variant="body2"
        //         sx={{
        //           opacity: 0.48,
        //         }}
        //       >
        //         {/* CIAN (Active Member) */}
        //         {memberDetails?.current_club?.club?.title || ''}
        //       </Typography>
        //       {/* <Typography
        //       sx={{
        //         typography: 'caption',
        //         opacity: 0.48,
        //       }}
        //     >
        //       You can only withdraw
        //       {memberDetails?.current_club?.club?.limit_amount || ''} {USDT}
        //     </Typography> */}
        //     </Stack>

        //     <Stack
        //       sx={{
        //         // position: 'absolute',
        //         // bottom: 57,
        //         // left: 16,
        //         color: 'common.white',
        //       }}
        //       // direction='row'
        //     >
        //       <Typography variant="body2">Wallet Balance</Typography>
        //       <Typography
        //         sx={{
        //           typography: 'caption',
        //           opacity: 0.68,
        //         }}
        //       >
        //         {memberDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
        //       </Typography>
        //     </Stack>

        //     <Stack direction="row">
        //       <IconButton
        //         onClick={() => setOpenModalDialog(true)}
        //         sx={{
        //           // position: 'absolute',
        //           // top: 16,
        //           // left: 16,
        //           color: 'common.white',
        //           width: '40px',
        //           // border: '2px solid orange'
        //         }}
        //       >
        //         <Tooltip title="Update Info">
        //           <EditRoundedIcon sx={{ fontSize: '20px' }} />
        //         </Tooltip>
        //       </IconButton>
        //       <IconButton
        //         onClick={() => setOpenModalPasswordDialog(true)}
        //         sx={{
        //           color: 'common.white',
        //           width: '40px',
        //         }}
        //       >
        //         <Tooltip title="Update Password">
        //           <KeyIcon />
        //         </Tooltip>
        //       </IconButton>
        //     </Stack>
        //   </Stack>

        //   <Stack
        //     // border='2px solid orange'
        //     justifyContent="center"
        //     flexDirection=""
        //     // alignItems="start"
        //     // justifyContent="start"
        //     sx={{
        //       // position: 'absolute',
        //       // bottom: 57,
        //       // right: 66,
        //       color: 'common.white',
        //     }}
        //     p={2}
        //   >
        //     {data?.referral_member ? (
        //       <>
        //         <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>

        //         <Stack gap={1} ml={1}>
        //           <Stack flexDirection="row" alignItems="center">
        //             <DeviceHubIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
        //             <Typography variant="body2" sx={{ color: 'white' }}>
        //               {data?.referral_member?.member_id}
        //             </Typography>
        //           </Stack>
        //           <Stack flexDirection="row" alignItems="center">
        //             <PersonIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
        //             <Typography variant="body2" sx={{ color: 'white' }}>
        //               {data?.referral_member?.name}
        //             </Typography>
        //           </Stack>
        //           <Stack flexDirection="row" alignItems="center">
        //             <MarkEmailUnreadRoundedIcon
        //               sx={{ fontSize: 14, color: 'white' }}
        //               fontSize="small"
        //             />{' '}
        //             &nbsp;
        //             <Typography variant="body2" sx={{ color: 'white' }}>
        //               {' '}
        //               {data?.referral_member?.email}
        //             </Typography>
        //           </Stack>
        //           <Stack flexDirection="row" alignItems="center">
        //             <LocalPhoneRoundedIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" />{' '}
        //             &nbsp;
        //             <Typography variant="body2" sx={{ color: 'white' }}>
        //               {data?.referral_member?.phone}
        //             </Typography>
        //           </Stack>
        //         </Stack>
        //       </>
        //     ) : (
        //       <Stack
        //         // border='2px solid orange'
        //         mr={3}
        //       >
        //         <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>

        //         <Typography
        //           mt={8}
        //           sx={{ color: '#919EAB', fontSize: 13, pb: 1, textAlign: 'center' }}
        //         >
        //           Nil
        //         </Typography>
        //       </Stack>
        //     )}
        //   </Stack>
        // </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{
            ...bgGradient({
              // color: alpha('#0a0909cc', 0.7),
              // imgUrl: coverUrl,
            }),
            height: '100%',
            width: '100%',
            // color: 'common.white',
            borderRadius: '15px',
          }}
        >
          {/* {memberDetails?.current_club?.club?.title ? ( */}
          <Stack flex={6}>
            {/* Wallet Balance Section */}
            {/* <Stack
              direction="row"
              alignItems="center"
              gap={2}
              sx={{
                py: isMobile ? 1 : 2,
                px: 2,
                flex: 1,
                borderBottom: '1px solid gray',
              }}
            >
              <Stack
                p={1.5}
                backgroundColor="#f0f0f0"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                width="60px"
                height="60px"
              >
                <CardMedia
                  component="img"
                  src={wallet}
                  alt="wallet"
                  style={{ width: '30px', height: '30px', objectFit: 'contain' }}
                />
              </Stack>
              <Stack spacing={0.5} justifyContent="center">
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                  }}
                >
                  {memberDetails?.buks_wallet_balance?.toFixed(decimalLimit) || 0} {USDT}
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.6 }}>
                  Buks Wallet Balance
                </Typography>
              </Stack>
            </Stack> */}
            <Box
              display="flex"
              alignItems="center"
              p={3}
              sx={{
                borderBottom:
                  settings?.themeMode === 'dark'
                    ? '1px solid #404141'
                    : '1px solid rgba(223, 227, 232, 1)',
              }}
            >
              <Stack
                p={1.5}
                backgroundColor="#f0f0f0"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '60px',
                  width: '60px',
                }}
              >
                <CardMedia
                  component="img"
                  src={wallet}
                  alt="wallet"
                  style={{ width: '30px', height: '30px', objectFit: 'contain' }}
                />
              </Stack>
              <Box pl={1.5}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                  }}
                >
                  {memberDetails?.buks_wallet_balance?.toFixed(decimalLimit) || 0} {USDT}
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.6, pt: 0, lineHeight: '22px' }}>
                  Bucks Wallet
                </Typography>
              </Box>
            </Box>

            {/* User Info Section */}
            <Box display="flex" flexDirection="column" gap={0.5} my="auto" px={2}>
              {/* User Name */}
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap={0.5}>
                  <CardMedia
                    component="img"
                    src={user}
                    sx={{
                      height: 15,
                      width: 15,
                      objectFit: 'contain',
                    }}
                  />
                  <Typography fontSize="13px" color="text.primary" pl={0.5}>
                    {MemberProfileDetails?.user?.name}
                  </Typography>
                </Box>
                {/* <Label
                  size="small"
                  color={handleIsPrimaryChecking() === 'Primary' ? 'info' : 'success'}
                >
                  {handleIsPrimaryChecking()}
                </Label> */}
                <Box
                  sx={{
                    backgroundColor:
                      handleIsPrimaryChecking() === 'Primary'
                        ? 'rgba(238, 223, 246, 1)'
                        : 'rgba(225, 246, 223, 1)',
                    color:
                      handleIsPrimaryChecking() === 'Primary'
                        ? 'rgba(79, 29, 104, 1)'
                        : 'rgba(0, 132, 88, 1)',
                    borderRadius: '3px',
                    px: '4px',
                    py: '2px',
                  }}
                >
                  <Typography fontSize="10px" fontWeight="bold">
                    {handleIsPrimaryChecking() === 'Primary' ? 'Primary' : 'Secondary'}
                  </Typography>
                </Box>
              </Box>

              {/* Email */}
              <Box display="flex" alignItems="center" gap={0.5}>
                <CardMedia
                  component="img"
                  src={msg}
                  sx={{
                    height: 15,
                    width: 15,
                    objectFit: 'contain',
                  }}
                />
                <Typography fontSize="13px" color="text.primary" pl={0.5}>
                  {MemberProfileDetails?.user?.email}
                </Typography>
              </Box>

              {/* Phone Number */}
              {MemberProfileDetails?.user?.phone && (
                <Box display="flex" alignItems="center" gap={0.5}>
                  <CardMedia
                    component="img"
                    src={phone}
                    sx={{
                      height: 15,
                      width: 15,
                      objectFit: 'contain',
                    }}
                  />
                  <Typography fontSize="13px" color="text.primary" pl={0.5}>
                    {MemberProfileDetails?.user?.phone}
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>

          {/* ) : (
              <Stack
                flex={5}
                justifyContent="space-between"
                // p={2}
              >
                <Stack
                  direction="column"
                  gap={2}
                  sx={{
                    p: 3,
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Stack
                    p={2}
                    backgroundColor="#f0f0f0"
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    width="60px"
                    height="60px"
                  >
                    <CardMedia
                      component="img"
                      src={wallet}
                      alt="wallet"
                      style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                    />
                  </Stack>
                  <Stack alignItems="baseline" justifyContent="center">
                    <Typography variant="body2" fontWeight="bold">
                      {memberDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
                    </Typography>
                    <Typography
                      sx={{
                        typography: 'caption',
                        opacity: 0.68,
                      }}
                    >
                      Wallet Balance
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )} */}
          <Stack
            flex={7}
            sx={{
              borderLeft:
                settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea ',
              p: 2,
              gap: 1.5,
            }}
          >
            <Stack
              flexDirection=""
              sx={{
                color: 'common.white',
              }}
            >
              {memberDetails?.referral_member ? (
                <Card
                  sx={{
                    boxShadow: 'unset',
                    border: '1px solid #e3e7ea',
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                  }}
                >
                  <Stack direction="row" gap={1}>
                    <Stack direction="row" flex={8} gap={1.5}>
                      <Stack
                        p={1}
                        borderRadius="50%"
                        justifyContent="center"
                        alignItems="center"
                        border="1px solid #F5F5F5"
                        width="50px"
                      >
                        <CardMedia
                          component="img"
                          src={refer}
                          alt="wallet"
                          style={{ width: '50px', height: '25px', objectFit: 'cover' }}
                        />
                      </Stack>

                      <Stack>
                        <Typography
                          fontSize="14px"
                          color={settings?.themeMode === 'dark' ? '#75838f' : '#75838f'}
                        >
                          Referred by
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color={settings?.themeMode === 'dark' ? '#75838f' : ''}
                        >
                          {data?.referral_member?.name}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack flex={4}>
                      <Card
                        boxShadow="unset"
                        sx={{
                          borderRadius: '2px',
                          borderLeft: '2px solid #004b50',
                          px: 1,
                          backgroundColor: '#d8d9f5',
                          width: 'fit-content',
                        }}
                      >
                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          color={settings?.themeMode === 'dark' ? '#75838f' : ''}
                        >
                          ID : {data?.referral_member?.member_id}
                        </Typography>
                      </Card>
                    </Stack>
                  </Stack>
                  <Stack ml={6.5} mt={1}>
                    <Stack direction="row" alignItems="center">
                      <CardMedia
                        component="img"
                        src={msg}
                        sx={{
                          height: 15,
                          width: 15,
                          objectFit: 'contain',
                        }}
                      />
                      &nbsp;
                      <Typography
                        fontSize="13px"
                        pl={0.5}
                        color={settings?.themeMode === 'dark' ? '#75838f' : ''}
                      >
                        {' '}
                        {data?.referral_member?.email}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <CardMedia
                        component="img"
                        src={phone}
                        sx={{
                          height: 15,
                          width: 15,
                          objectFit: 'contain',
                        }}
                      />
                      &nbsp;
                      <Typography
                        pl={0.5}
                        fontSize="13px"
                        color={settings?.themeMode === 'dark' ? '#75838f' : ''}
                      >
                        {data?.referral_member?.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              ) : (
                <Card
                  sx={{
                    boxShadow: 'unset',
                    border: '1px solid #e3e7ea',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1.5,
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack gap={2} flex={1}>
                      <Typography variant="h6" fontWeight="bold" color="black">
                        Member referral <br />
                        link
                      </Typography>
                    </Stack>
                    <Stack width="auto">
                      <CardMedia
                        component="img"
                        src={gmail}
                        alt="wallet"
                        style={{ width: 70, height: 70, objectFit: 'cover' }}
                      />
                    </Stack>
                  </Stack>
                  {/* .. */}
                  <Typography
                    variant="caption"
                    sx={{ mb: 0.5, color: settings?.themeMode === 'dark' ? 'black' : '#637381' }}
                  >
                    Share link
                  </Typography>
                  <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
                    <Card
                      sx={{
                        px: 1,
                        py: 0.7,
                        borderRadius: '10px',
                        boxShadow: 'unset',
                        border: '1px solid #e3e7ea',
                        textAlign: 'start',
                        backgroundColor: '#f0f0f0',
                        gap: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <TextMaxLine line={1}>
                        <Typography
                          sx={{
                            color: settings?.themeMode === 'dark' ? 'black' : '#637381',
                            wordWrap: 'break-word',
                            overflowWrap: 'anywhere',
                            opacity: 0.7,
                            textAlign: 'start',
                          }}
                          variant="caption"
                        >
                          {`${frontEndUrl}${paths.auth.register}?ref_id=${memberDetails?.member_id}`}
                        </Typography>
                      </TextMaxLine>
                      <CopyToClipboard
                        // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                        text={`${frontEndUrl}${paths.auth.register}?ref_id=${memberDetails?.member_id}`}
                        onCopy={handleCopy}
                        sx={
                          {
                            // cursor: 'pointer',
                            // position: 'absolute',
                            // bottom: 16,
                            // left: 16,
                            // '&:hover': {
                            //   color: 'primary.main',
                            // },
                          }
                        }
                      >
                        <Button
                          size="small"
                          variant="contained"
                          className="custom-copy-button"
                          backgroundColor="#007867"
                          borderRadius="25px"
                          sx={{
                            position: 'absolute',
                            bottom: 16,
                            right: 16,
                            color: 'white',
                            p: 1,
                            '&:hover': { backgroundColor: '#03433a' },
                          }}
                        >
                          Copy
                        </Button>
                      </CopyToClipboard>
                    </Card>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handleClick}
                      sx={{
                        border: '1px solid #e3e7ea',
                        color: '#007867',
                        width: 'auto',
                        height: '40px',
                        borderRadius: '20px',
                        backgroundColor: '#e1efea',
                      }}
                    >
                      Share
                    </Button>
                  </Stack>
                </Card>
              )}
            </Stack>
            <Stack
              width="100%"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{ py: 0 }}
            >
              <Stack direction="row" spacing={2} width="100%" justifyContent="center">
                <Button
                  size="medium"
                  variant="contained"
                  fullWidth
                  onClick={() => setOpenModalDialog(true)}
                  sx={{
                    backgroundColor:
                      settings?.themeMode === 'dark' ? 'white' : 'rgba(145, 158, 171, 0.08)',
                    color: 'black',
                    fontSize: 13,
                    // maxWidth: '200px',
                    textAlign: 'center',
                    py: 1,
                    '&:hover': {
                      backgroundColor:
                        settings?.themeMode === 'dark' ? 'white' : 'rgba(145, 158, 171, 0.15)',
                      color: 'black',
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    src={edit}
                    sx={{
                      height: '18px',
                      width: '18px',
                      objectFit: 'cover',
                    }}
                  />
                  &nbsp; Update Profile
                </Button>

                <Button
                  size="medium"
                  variant="contained"
                  fullWidth
                  onClick={() => setOpenModalPasswordDialog(true)}
                  sx={{
                    backgroundColor:
                      settings?.themeMode === 'dark' ? 'white' : 'rgba(145, 158, 171, 0.08)',
                    color: 'black',
                    // maxWidth: '200px',
                    textAlign: 'center',
                    fontSize: 13,
                    py: 1,
                    '&:hover': {
                      backgroundColor:
                        settings?.themeMode === 'dark' ? 'white' : 'rgba(145, 158, 171, 0.08)',
                      color: 'black',
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    src={lock}
                    sx={{
                      height: '18px',
                      width: '18px',
                      objectFit: 'cover',
                    }}
                  />
                  &nbsp; Change Password
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}

      {role === 'admin' && (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{
            ...bgGradient({
              // color: alpha('#0a0909cc', 0.7),
              // imgUrl: coverUrl,
            }),

            height: '100%',

            width: '100%',
            // color: 'common.white',
            borderRadius: '15px',
          }}
        >
          {/* {!memberDetails?.current_club?.club?.title ? (
            <Card
              sx={{
                boxShadow: 'unset',
                border: '1px solid #e3e7ea',
                backgroundColor: '#f0f0f0',
                display: 'flex',
                flexDirection: 'column',
                p: 2,
              }}
            >
              <Stack direction="row" gap={1}>
                <Stack direction="row" flex={8} gap={1.5}>
                  <Stack
                    p={1}
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid #e3e7ea"
                    width="50px"
                  >
                    <CardMedia
                      component="img"
                      src={refer}
                      alt="wallet"
                      style={{ width: '50px', height: '25px', objectFit: 'cover' }}
                    />
                  </Stack>

                  <Stack>
                    <Typography fontSize="14px" color="#75838f">
                      Referred by
                    </Typography>
                    <Typography fontSize="14px" fontWeight="bold">
                      {data?.referral_member?.name}{' '}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flex={4}>
                  <Card
                    boxShadow="unset"
                    sx={{
                      borderRadius: '2px',
                      borderLeft: '2px solid #004b50',
                      px: 1,
                      backgroundColor: '#d8d9f5',
                      width: 'fit-content',
                    }}
                  >
                    <Typography variant="caption" fontWeight="bold">
                      ID : {data?.referral_member?.member_id}
                    </Typography>
                  </Card>
                </Stack>
              </Stack>
              <Stack ml={6.5} mt={1}>
                <Stack direction="row" alignItems="center">
                  <CardMedia
                    component="img"
                    src={msg}
                    sx={{
                      height: 15,
                      width: 15,
                      objectFit: 'contain',
                    }}
                  />
                  &nbsp;
                  <Typography fontSize="13px" pl={0.5}>
                    {' '}
                    {data?.referral_member?.email}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <CardMedia
                    component="img"
                    src={phone}
                    sx={{
                      height: 15,
                      width: 15,
                      objectFit: 'contain',
                    }}
                  />
                  &nbsp;
                  <Typography pl={0.5} fontSize="13px">
                    {data?.referral_member?.phone}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          ) : ( */}
          <Stack flex={6}>
            <Box
              display="flex"
              alignItems="center"
              p={3}
              sx={{
                borderBottom: '1px solid rgba(223, 227, 232, 1)',
              }}
            >
              <Stack
                p={1.5}
                backgroundColor="#f0f0f0"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '60px',
                  width: '60px',
                }}
              >
                <CardMedia
                  component="img"
                  src={wallet}
                  alt="wallet"
                  style={{ width: '30px', height: '30px', objectFit: 'contain' }}
                />
              </Stack>
              <Box pl={1.5}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                  }}
                >
                  {memberDetails?.buks_wallet_balance?.toFixed(decimalLimit) || 0} {USDT}
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.6, pt: 0, lineHeight: '22px' }}>
                  Bucks Wallet
                </Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap={0.5} my="auto" px={2}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap={0.5}>
                  <CardMedia
                    component="img"
                    src={user}
                    sx={{
                      height: 15,
                      width: 15,
                      objectFit: 'contain',
                    }}
                  />
                  <Typography fontSize="13px" color="text.primary" pl={0.5}>
                    {memberDetails?.user?.name}
                  </Typography>
                </Box>
                {/* <Label
                  size="small"
                  color={handleIsPrimaryChecking() === 'Primary' ? 'info' : 'success'}
                >
                  {handleIsPrimaryChecking()}
                </Label> */}
                <Box
                  sx={{
                    backgroundColor:
                      handleIsPrimaryChecking() === 'Primary'
                        ? 'rgba(238, 223, 246, 1)'
                        : 'rgba(225, 246, 223, 1)',
                    color:
                      handleIsPrimaryChecking() === 'Primary'
                        ? 'rgba(79, 29, 104, 1)'
                        : 'rgba(0, 132, 88, 1)',
                    borderRadius: '3px',
                    px: '4px',
                    py: '2px',
                  }}
                >
                  <Typography fontSize="10px" fontWeight="bold">
                    {handleIsPrimaryChecking() === 'Primary' ? 'Primary' : 'Secondary'}
                  </Typography>
                </Box>
              </Box>

              {/* Email */}
              <Box display="flex" alignItems="center" gap={0.5}>
                <CardMedia
                  component="img"
                  src={msg}
                  sx={{
                    height: 15,
                    width: 15,
                    objectFit: 'contain',
                  }}
                />
                <Typography fontSize="13px" color="text.primary" pl={0.5}>
                  {memberDetails?.user?.email}
                </Typography>
              </Box>

              {/* Phone Number */}
              <Box display="flex" alignItems="center" gap={0.5}>
                <CardMedia
                  component="img"
                  src={phone}
                  sx={{
                    height: 15,
                    width: 15,
                    objectFit: 'contain',
                  }}
                />
                <Typography fontSize="13px" color="text.primary" pl={0.5}>
                  {memberDetails?.user?.phone}
                </Typography>
              </Box>
            </Box>
          </Stack>
          {/* )} */}
          <Stack
            flex={7}
            sx={{
              borderLeft:
                settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea ',
              p: 1.5,
              gap: 1.5,
            }}
          >
            <Stack
              flexDirection=""
              sx={{
                color: 'common.white',
              }}
            >
              {memberDetails?.referral_member ? (
                <Card
                  sx={{
                    boxShadow: 'unset',
                    border: '1px solid #e3e7ea',
                    backgroundColor: '#F5F5F5',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                  }}
                >
                  <Stack direction="row" gap={1}>
                    <Stack direction="row" flex={8} gap={1.5}>
                      <Stack
                        p={1}
                        borderRadius="50%"
                        justifyContent="center"
                        alignItems="center"
                        border="1px solid #e3e7ea"
                        width="50px"
                      >
                        <CardMedia
                          component="img"
                          src={refer}
                          alt="wallet"
                          style={{ width: '50px', height: '25px', objectFit: 'cover' }}
                        />
                      </Stack>

                      <Stack>
                        <Typography fontSize="14px" color="#75838f">
                          Referred by
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color={settings?.themeMode === 'dark' ? '#75838f' : ''}
                        >
                          {data?.referral_member?.name}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack flex={4}>
                      <Card
                        boxShadow="unset"
                        sx={{
                          borderRadius: '2px',
                          borderLeft: '2px solid #004b50',
                          px: 1,
                          backgroundColor: '#d8d9f5',
                          width: 'fit-content',
                        }}
                      >
                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          color={settings?.themeMode === 'dark' ? '#75838f' : ''}
                        >
                          ID : {data?.referral_member?.member_id}
                        </Typography>
                      </Card>
                    </Stack>
                  </Stack>
                  <Stack ml={6.5} mt={1}>
                    <Stack direction="row" alignItems="center">
                      <CardMedia
                        component="img"
                        src={msg}
                        sx={{
                          height: 15,
                          width: 15,
                          objectFit: 'contain',
                        }}
                      />
                      &nbsp;
                      <Typography
                        fontSize="13px"
                        pl={0.5}
                        color={settings?.themeMode === 'dark' ? '#75838f' : ''}
                      >
                        {' '}
                        {data?.referral_member?.email}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <CardMedia
                        component="img"
                        src={phone}
                        sx={{
                          height: 15,
                          width: 15,
                          objectFit: 'contain',
                        }}
                      />
                      &nbsp;
                      <Typography
                        pl={0.5}
                        fontSize="13px"
                        color={settings?.themeMode === 'dark' ? '#75838f' : ''}
                      >
                        {data?.referral_member?.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              ) : (
                <Card
                  sx={{
                    boxShadow: 'unset',
                    border: '1px solid #e3e7ea',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1.5,
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack gap={2} flex={1}>
                      <Typography variant="h6" fontWeight="bold" color="black">
                        Member referral <br />
                        link
                      </Typography>
                    </Stack>
                    <Stack width="auto">
                      <CardMedia
                        component="img"
                        src={gmail}
                        alt="wallet"
                        style={{ width: 70, height: 70, objectFit: 'cover' }}
                      />
                    </Stack>
                  </Stack>
                  <Typography
                    variant="caption"
                    sx={{ mb: 0.5, color: settings?.themeMode === 'dark' ? 'black' : '#637381' }}
                  >
                    Share link
                  </Typography>
                  <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
                    <Card
                      sx={{
                        px: 1,
                        py: 0.7,
                        borderRadius: '10px',
                        boxShadow: 'unset',
                        border: '1px solid #e3e7ea',
                        textAlign: 'start',
                        backgroundColor: '#f0f0f0',
                        gap: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <TextMaxLine line={1}>
                        <Typography
                          sx={{
                            color: settings?.themeMode === 'dark' ? 'black' : '#637381',
                            wordWrap: 'break-word',
                            overflowWrap: 'anywhere',
                            opacity: 0.7,
                            textAlign: 'start',
                          }}
                          variant="caption"
                        >
                          {`${frontEndUrl}${paths.auth.register}?ref_id=${memberID}`}
                        </Typography>
                      </TextMaxLine>
                      <CopyToClipboard
                        // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                        text={`${frontEndUrl}${paths.auth.register}?ref_id=${memberID}`}
                        onCopy={handleCopy}
                        sx={
                          {
                            // cursor: 'pointer',
                            // position: 'absolute',
                            // bottom: 16,
                            // left: 16,
                            // '&:hover': {
                            //   color: 'primary.main',
                            // },
                          }
                        }
                      >
                        <Button
                          size="small"
                          variant="contained"
                          className="custom-copy-button"
                          backgroundColor="#007867"
                          borderRadius="25px"
                          sx={{
                            position: 'absolute',
                            bottom: 16,
                            right: 16,
                            color: 'white',
                            p: 1,
                            '&:hover': { backgroundColor: '#03433a' },
                          }}
                        >
                          Copy
                        </Button>
                      </CopyToClipboard>
                    </Card>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handleClick}
                      sx={{
                        border: '1px solid #e3e7ea',
                        color: '#007867',
                        width: 'auto',
                        height: '40px',
                        borderRadius: '20px',
                        backgroundColor: '#e1efea',
                      }}
                    >
                      Share
                    </Button>
                  </Stack>
                </Card>
              )}
            </Stack>
            <Stack
              width="100%"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{ py: 0 }}
            >
              <Stack direction="row" spacing={2} width="100%" justifyContent="center">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setOpenModalDialog(true)}
                  sx={{
                    backgroundColor:
                      settings?.themeMode === 'dark' ? ' white' : 'rgba(145, 158, 171, 0.08)',
                    color: 'black',
                    fontSize: 13,
                    // maxWidth: '200px',
                    textAlign: 'center',
                    py: 1,
                    '&:hover': {
                      backgroundColor:
                        settings?.themeMode === 'dark' ? ' white' : 'rgba(145, 158, 171, 0.15)',
                      color: 'black',
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    src={edit}
                    sx={{
                      height: '18px',
                      width: '18px',
                      objectFit: 'cover',
                    }}
                  />
                  &nbsp; Update Profile
                </Button>

                <Button
                  size="medium"
                  variant="contained"
                  fullWidth
                  onClick={() => setOpenModalPasswordDialog(true)}
                  sx={{
                    backgroundColor:
                      settings?.themeMode === 'dark' ? 'white' : 'rgba(145, 158, 171, 0.08)',
                    color: 'black',
                    // maxWidth: '200px',
                    textAlign: 'center',
                    fontSize: 13,
                    py: 1,
                    '&:hover': {
                      backgroundColor:
                        settings?.themeMode === 'dark' ? 'white' : 'rgba(145, 158, 171, 0.08)',
                      color: 'black',
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    src={lock}
                    sx={{
                      height: '18px',
                      width: '18px',
                      objectFit: 'cover',
                    }}
                  />
                  &nbsp; Change Password
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}

      <ConfirmDialog
        open={confirm.value}
        // open='true'
        onClose={confirm.onFalse}
        title={isBlocked ? 'Unblock Member' : 'Block Member'}
        content={<>Are you sure want to {isBlocked ? 'unblock member' : 'block member'}?</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              Block_Unblock_Member();
              confirm.onFalse();
            }}
          >
            {isBlocked ? 'Unblock' : 'Block'}
          </Button>
        }
      />

      <FlagDialog
        open={openFlagDialog}
        setOpen={setOpenFlagDialog}
        flagMessage={memberDetails?.note}
        is_flagged={memberDetails?.is_flagged}
      />
      <SuspendDialog
        open={openSuspendDialog}
        setOpen={setOpenSuspendDialog}
        isSuspented={isSuspented}
      />
    </>
  );
}

MemberRefiredCardNew.propTypes = {
  memberDetails: PropTypes.string,
  setOpenModalPasswordDialog: PropTypes.func,
  setOpenModalDialog: PropTypes.func,
  data: PropTypes.object,
  memberID: PropTypes.string,
  setOpenUpdateMemberEmail: PropTypes.any,
};
