import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

import Upload from './upload';
// import UploadCopy from './upload copy';
// import ImageAltTextPopup from './ImageAltTextPopup';

function OspImageUploader({ imagesPreview, setImagesPreview, single, autoWidth, folder, isGroup }) {
  // const [openAltTextPopup, setOpenAltTextPopup] = useState({
  //   data: {},
  //   imgData: {},
  //   status: false,
  // });

  // const handleAddAltTextToImage = (altTextData) => {
  //   if (!imagesPreview || !openAltTextPopup) return;

  //   const { imgData } = openAltTextPopup;
  //   const currentData = imagesPreview.find((item) => item.public_id === imgData?.public_id);

  //   if (!currentData) return;

  //   const modifiedImageData = { ...currentData, ...altTextData };

  //   setImagesPreview(
  //     imagesPreview.map((item) =>
  //       item.public_id === imgData?.public_id ? modifiedImageData : item
  //     )
  //   );
  // };

  // console.log(handleAddAltTextToImage());

  return (
    <Stack spacing={0} width="100%" direction="column">
      <Upload
        autoWidth
        setImagesPreview={setImagesPreview}
        imagesPreview={imagesPreview}
        // extraFunction={setOpenAltTextPopup}

        single={single}
        folder={folder}
      />

      {/* {!single && imagesPreview?.length >= 1 && (
        <Typography variant="caption" display="flex" alignItems="center">
          <InfoRoundedIcon fontSize="small" sx={{ mx: 1 }} color="disabled" /> 
          Click on images to add Alt Text
        </Typography>
      )} */}

      {/* <ImageAltTextPopup
        handleAddAltTextToImage={handleAddAltTextToImage}
        setOpen={setOpenAltTextPopup}
        open={openAltTextPopup.status}
        isUpdate={openAltTextPopup?.imgData}
        isGroup={isGroup}
      /> */}
    </Stack>
  );
}

OspImageUploader.propTypes = {
  imagesPreview: PropTypes.array,
  setImagesPreview: PropTypes.func,
  single: PropTypes.bool,
  autoWidth: PropTypes.bool,
  folder: PropTypes.any,
  isGroup: PropTypes.any,
};

export default OspImageUploader;
