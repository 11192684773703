import { useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { USDT } from 'src/hooks/use-USDT';

import { fDate, fTime } from 'src/utils/format-time';

import { decimalLimit } from 'src/server/api/http';

import Label from 'src/components/label/label';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

import TransactionViewModal from './transactions-singleview-modal';

// ----------------------------------------------------------------------

export default function ManualTransactionTableRow({ row, selected, memberSingle }) {
  const [isOpen, setIsOpen] = useState();

  const {
    createdAt,
    amount,
    to_member,
    from_member,
    to_company_wallet,
    to_wallet_type,
    status,
    from_company_wallet,
    from_wallet_type,
  } = row;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getStatusDetails = () => {
    switch (status) {
      case 'PROCESSING':
        return { label: 'Processing', color: 'warning' };
      case 'SUCCESS':
        return { label: 'Success', color: 'success' };
      case 'FAILED':
        return { label: 'Failed', color: 'error' };

      default:
        return { label: '', color: '' };
    }
  };

  const getWalletTypeLabel = (type) => {
    if (type === 'MEMBER') return 'Member';
    if (type === 'COMPANY') return 'Company';
    return type;
  };

  const handleType = () =>
    getWalletTypeLabel(to_wallet_type) || getWalletTypeLabel(from_wallet_type);

  const getCompanyLabel = (wallet, type) => {
    const labels = {
      FUTURE_PROJECTS: 'Future Projects',
      MAINTENANCE: 'Maintenance',
      COMPANY: 'Company',
    };

    return labels[wallet] ? { label: labels[wallet], sub: type } : wallet;
  };

  const handleCompanyNameFrom = () => getCompanyLabel(from_company_wallet, handleType());
  const handleCompanyNameTo = () => getCompanyLabel(to_company_wallet, handleType());

  const getMemberDetails = (member, type) =>
    member ? { name: member.name, id: `${type} (${member.member_id})` } : null;

  const handleMemberFrom = () => getMemberDetails(from_member, handleType());
  const handleMemberTo = () => getMemberDetails(to_member, handleType());

  return (
    <>
      {isOpen && <TransactionViewModal open={isOpen} setOpen={setIsOpen} data={row} />}

      <TableRow hover selected={selected}>
        <TableCell>
          <ListItemText
            primary={fDate(createdAt)}
            secondary={fTime(createdAt)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>
          <ListItemText
            primary={handleCompanyNameFrom()?.label || handleMemberFrom()?.name || '--'}
            secondary={handleCompanyNameFrom()?.sub || handleMemberFrom()?.id || '--'}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>
        <TableCell>
          <ListItemText
            primary={handleCompanyNameTo()?.label || handleMemberTo()?.name || '--'}
            secondary={handleCompanyNameTo()?.sub || handleMemberTo()?.id || '--'}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell sx={{ wordWrap: 'break-word' }}>
          <TextMaxLine line={2}>
            <Typography fontWeight={400} fontSize="14px">
              {amount?.toFixed(decimalLimit)} {USDT}
            </Typography>
          </TextMaxLine>
        </TableCell>
        {/* {role === 'admin' && (
          <TableCell variant="subtitle2" sx={{ wordWrap: 'break-word' }}>
            <TextMaxLine line={2}>
              <Typography variant="subtitle2">
                {deduction_amount?.toFixed(2)} {USDT}
              </Typography>
            </TextMaxLine>
          </TableCell>
        )}
        {/* {role === 'admin' && (
          <TableCell>
            <TextMaxLine line={2} variant="body2" sx={{ maxWidth: 300 }}>
              {remarks || '--'}
            </TextMaxLine>
          </TableCell>
        )} */}

        {/* <TableCell>{status}</TableCell> */}
        <TableCell>
          <Label variant="soft" color={getStatusDetails()?.color}>
            {getStatusDetails()?.label}
          </Label>
        </TableCell>
        <TableCell>
          <Stack flexDirection="row" alignItems="center">
            <Tooltip title="View Details" onClick={() => setIsOpen(true)}>
              <VisibilityIcon sx={{ fontSize: '20px', cursor: 'pointer', ml: 2 }} />
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}

ManualTransactionTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  memberSingle: PropTypes.bool,
};
