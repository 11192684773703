import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, post } from './http';
import { activeSnack } from '../store/common';

export const getAllTransactions = createAsyncThunk(
  'transaction/list',
  async ({ page, limit, transaction_type, date, dispatch, wallet_type }) => {
    try {
      const response = await get(
        `/wallet-transaction/member/all?page=${page && page}&limit=${limit || 5}&transaction_type=${
          transaction_type || ''
        }&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&wallet_type=${wallet_type || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllClubIncomeTransactions = createAsyncThunk(
  'club-income-transaction/list',
  async ({ page, limit, date, sort, dispatch }) => {
    try {
      const response = await get(
        `/club-income-transaction/member/all?page=${page && page}&limit=${limit || 5}&dateFrom=${
          date.from || ''
        }&dateTo=${date.to || ''}&sort=${sort || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllTransactionsAdmin = createAsyncThunk(
  'transaction/list',
  async ({
    page,
    limit,
    transaction_type,
    date,
    dispatch,
    member,
    wallet_type,
    selectedCategory,
  }) => {
    // console.log('member =>', member);
    try {
      const response = await get(
        `/wallet-transaction/admin/all?page=${page && page}&limit=${limit || 5}&transaction_type=${
          transaction_type || ''
        }&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&member=${
          member || ''
        }&wallet_type=${wallet_type || ''}&category=${selectedCategory || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return '';
    } catch (error) {
      // dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// manual wallet transactions

export const createManualTransactions = createAsyncThunk(
  'manual-transaction/create',
  async ({ state, dispatch, handleClose, reset }) => {
    try {
      const URL = `manual-wallet-transaction/admin/new`;
      const response = await post(URL, state);

      console.log(response);

      if (response) {
        dispatch(
          activeSnack({ type: 'success', message: 'Manual Transactions Created successfully' })
        );
        handleClose();
        dispatch(
          getAllManualTransactions({
            page: 1,
            limit: sessionStorage.getItem(`rowsPerPage_manual-trns`),
            dispatch,
          })
        );

        reset();
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);

      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
// export const updateNotificationDetails = createAsyncThunk(
//   'notification/update',
//   async ({ state, notificationId, dispatch, handleClose, reset }) => {
//     try {
//       const URL = `notification/admin/${notificationId}`;

//       const response = await put(URL, state);
//       if (response) {
//         handleClose();
//         reset();
//         dispatch(activeSnack({ type: 'success', message: 'Notification Updated Successfully' }));
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );
export const getAllManualTransactions = createAsyncThunk(
  'manual-transactions/list',
  async ({ page, search, dispatch, limit, sort, company, member, date }) => {
    try {
      const URL = `/manual-wallet-transaction/admin/all?page=${page || 1}&search=${search || ''}&limit=${
        limit || 10
      }&status=${sort || ''}&company=${company || ''}&member=${member || ''}&dateFrom=${date?.from || ''}&dateTo=${date?.to || ''}`;

      const URL_DROPDOWN = `/manual-wallet-transaction/admin/all`;

      const response = await get(page ? URL : URL_DROPDOWN);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// export const deleteNotification = createAsyncThunk(
//   'notification/delete',
//   async ({ notificationId, dispatch, handleCloseDeleteDialog }) => {
//     try {
//       const response = await del(`/notification/admin/${notificationId}`);
//       if (response) {
//         handleCloseDeleteDialog();
//         dispatch(activeSnack({ type: 'success', message: 'Notification Deleted Successfully' }));
//         return response?._id;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );
