import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function MemberSearch({ onSearch, setPage }) {
  return (
    <TextField
      sx={{ width: '100%' }}
      onChange={(e) => {
        onSearch(e.target.value);
        setPage();
      }}
      placeholder="Search Member ID,Transaction Hash, Name, Phone, Email"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}

MemberSearch.propTypes = {
  onSearch: PropTypes.func,
  setPage: PropTypes.any,
};
