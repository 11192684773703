import * as Yup from 'yup';
import { useEffect } from 'react';
import { parseInt } from 'lodash';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
import { Stack, Button, CardMedia, Typography } from '@mui/material';

// import { useBoolean } from 'src/hooks/use-boolean';
import useResponsive from 'src/hooks/useResponsive';

import { verifyOtpApi } from 'src/server/api/auth';
import emailSent from 'src/assets/other/ic-email-sent.png';
import { getMemberProfileDetails } from 'src/server/api/member';
import { createMemberClubWithdrawalRequest } from 'src/server/api/memberClub';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
// import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

export default function WalletTransferContent({
  activeStep,
  handleNextStep,
  setOpenWalletTransferSuccess,
  handleClose,
  openClubId,
  setActiveStep,
}) {
  const isMobile = useResponsive('down', 'md');
  const { enqueueSnackbar } = useSnackbar();

  // const password = useBoolean();
  // const navigate = useNavigate();

  // const { token } = useGetToken(TOKEN_PREFIX);
  // console.log(token);
  // const [selectedCountryCode, setSelectedCountryCode] = useState('');
  // const [userNameCheck, setUserNameCheck] = useState('');
  const { loading, MemberProfileDetails, memberClubLoading, memberClubWithdrawalRequest } =
    useSelector((state) => ({
      user: state.auth.User,
      loading: state.auth.loginLoading,
      memberClubLoading: state.memberClub.loading,

      settingsDetails: state.settings.settingsDetails,
      MemberProfileDetails: state.member.memberProfileDetails.member,
      memberClubWithdrawalRequest: state.memberClub.memberClubWithdrawalRequests,
    }));
  // console.log(user);
  // console.log(settingsDetails);
  // console.log(memberClubWithdrawalRequest);
  // console.log(MemberProfileDetails);
  // const router = useRouter();
  // const [passwordMatch, setPasswordMatch] = useState(true);
  // const [ImagePreview, setImagePreview] = useState([]);

  const dispatch = useDispatch();

  const registerSchema = Yup.object().shape({
    amount: Yup.number(),
  });

  const OtpSchema = Yup.object().shape({
    otp: Yup.string().min(4, 'OTP must be at least 4 digits').required('OTP is required'),
  });

  const defaultValues = {
    // name: account?.name || '',
    // email: account?.email || '',
    // phone: account?.phone || '',
  };
  // console.log(selectedCountryCode);
  const methods = useForm({
    resolver: yupResolver(registerSchema || OtpSchema),
    defaultValues,
  });

  const {
    // setValue,
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        // ...data,
        memberClub: openClubId,
        amount: parseInt(data?.amount),
      },
      // club: MemberProfileDetails?.current_club?._id,
      // club: openClubId,
      dispatch,
      handleClose,

      setOpenWalletTransferSuccess,
      setActiveStep,
      // handleNextStep,
    };
    dispatch(createMemberClubWithdrawalRequest(credentials));
  });
  useEffect(() => {
    if (memberClubWithdrawalRequest?._id) {
      const credential = {
        dispatch,
      };
      dispatch(getMemberProfileDetails(credential));
    }
  }, [dispatch, memberClubWithdrawalRequest?._id]);
  const onSubmitOTP = handleSubmit(async (loginFormData) => {
    const otpCrud = {
      state: {
        otp: loginFormData?.otp?.toString(),
        userId: MemberProfileDetails?.user?._id,
      },
      dispatch,
      enqueueSnackbar,

      handleNextStep,
    };
    dispatch(verifyOtpApi(otpCrud));
  });

  const renderFormOtp = (
    <>
      <Stack p={3}>
        <Stack flexDirection="row" mt={4} mb={2} alignItems="center">
          <CardMedia
            component="img"
            src={emailSent}
            alt="qr-code"
            sx={{ width: '13.6px', mr: 1 }}
          />
          <Typography variant="caption" color="#637381">
            An OTP will be sent to the email you provided. enter the OTP on the below box to
            complete the verification process.
          </Typography>
        </Stack>
        <RHFTextField type="number" name="otp" mb={3} />
      </Stack>
      <Stack
        p={3}
        flexDirection="row"
        gap={2}
        justifyContent="end"
        sx={{ borderTop: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
      >
        <Button variant="outlined" sx={{ height: '28px' }} onClick={handleClose}>
          Close
        </Button>
        <LoadingButton
          fullWidth
          size="small"
          type="submit"
          variant="contained"
          color="success"
          sx={{ backgroundColor: 'success.main', width: '80px', height: '28px' }}
          loading={isSubmitting || loading}
        >
          Verify
        </LoadingButton>
      </Stack>
    </>
  );
  return (
    <>
      {activeStep === 0 && (
        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={2}>
          <FormProvider methods={methods} onSubmit={onSubmitOTP}>
            {renderFormOtp}
          </FormProvider>
        </Stack>
      )}
      {activeStep === 1 && (
        <Stack mt={2}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack p={3} mb={1}>
              <RHFTextField
                sx={{ width: '100%' }}
                name="amount"
                required
                label="Amount"
                placeholder="Enter Amount.."
                InputLabelProps
              />
              <Stack flexDirection="row" mt={2}>
                <Iconify
                  width={15}
                  icon="eva:info-fill"
                  sx={{
                    mr: 0.5,
                    // color: '#637381',
                  }}
                />
                <Typography fontSize="10px" fontWeight={400}>
                  You can Transfer minimum of 10 USDT and a maximum amount equal to your club limit{' '}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              p={3}
              flexDirection="row"
              gap={2}
              justifyContent="end"
              sx={{ borderTop: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
            >
              <Button variant="outlined" sx={{ height: '28px' }} onClick={handleClose}>
                Close
              </Button>
              <LoadingButton
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                color="success"
                sx={{ backgroundColor: 'success.main', width: '80px', height: '28px' }}
                loading={isSubmitting || memberClubLoading}
              >
                {' '}
                Continue
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Stack>
      )}
    </>
  );
}

WalletTransferContent.propTypes = {
  activeStep: PropTypes.any,
  handleNextStep: PropTypes.any,
  setOpenWalletTransferSuccess: PropTypes.any,
  handleClose: PropTypes.any,
  openClubId: PropTypes.any,
  setActiveStep: PropTypes.any,
};
