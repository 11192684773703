import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Box, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useResponsive } from 'src/hooks/use-responsive';

import { getSettingsDetails, updateSettingsDetails } from 'src/server/api/settings';

import OspImageUploader from 'src/components/ImageUploader';
// import OspImageUploader from 'src/components/ImageUploader';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function NewSettingsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMobile = useResponsive('down', 'md');

  const { settingsDetails, loading } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails,
    loading: state.settings.loading,
  }));

  const [logoImagePreview, setLogoImagePreview] = useState([]);

  // const NewJobSchema = Yup.object().shape({
  //   company: Yup.object().shape({
  //     name: Yup.string(),
  //     description: Yup.string(),
  //     company_logo: [],
  //     address: Yup.string(),
  //   }),
  //   account_credentials: Yup.object().shape({
  //     wallet_id: Yup.string(),
  //     qr_code: [],
  //   }),
  //   contact_details: Yup.object().shape({
  //     phone: Yup.string()
  //       .required('Phone required')
  //       .min(10, 'Phone must be at least 10 characters')
  //       .max(15, 'Phone must not exceed 15 characters'),
  //     email: Yup.string().email(),
  //     whatsapp_number: Yup.string(),
  //     website: Yup.string(),
  //   }),
  //   social_links: Yup.object().shape({
  //     whatsapp: Yup.number(),
  //     instagram: Yup.string(),
  //     facebook: Yup.string(),
  //     x: Yup.string(),
  //   }),
  //   member_id_next_value: Yup.string(),
  //   member_id_tag: Yup.string(),
  //   membership_amount: Yup.string(),
  //   currency: Yup.string(),
  // });

  const TAB_OPTIONS = [
    // { value: 'company', label: 'Company ' },
    // { value: 'contact', label: 'Contact' },
    { value: 'payment', label: 'Payment' },
    { value: 'social_links', label: 'Social Links' },
    { value: 'configuration', label: 'Configuration' },
  ];

  const [tabChange, setTabChange] = useState({
    value: 'payment',
    badge: '0',
  });
  const handleFilterStatus = (e, value) => {
    setTabChange({
      value,
    });
  };

  useEffect(() => {
    setLogoImagePreview(settingsDetails?.company?.company_logo);
    setImagesPreview(settingsDetails?.account_credentials?.qr_code);
  }, [settingsDetails]);

  const defaultValues = useMemo(
    () => ({
      company: {
        name: settingsDetails?.company?.name,
        description: settingsDetails?.company?.description,
        address: settingsDetails?.company?.address,
        company_logo: logoImagePreview,
      },
      account_credentials: {
        wallet_id: settingsDetails?.account_credentials?.wallet_id,
        // qr_code: qrImagePreview,
        wallet_name: settingsDetails?.account_credentials?.wallet_name,
      },
      contact_details: {
        phone: settingsDetails?.contact_details?.phone,
        email: settingsDetails?.contact_details?.email,
        whatsapp_number: settingsDetails?.contact_details?.whatsapp_number,
        website: settingsDetails?.contact_details?.website,
      },
      social_links: {
        whatsapp: settingsDetails?.social_links?.whatsapp,
        instagram: settingsDetails?.social_links?.instagram,
        facebook: settingsDetails?.social_links?.facebook,
        x: settingsDetails?.social_links?.x,
        whatsApp_channel: settingsDetails?.social_links?.whatsApp_channel,
      },
      currency: settingsDetails?.currency,
      member_id_next_value: settingsDetails?.member_id_next_value,
      member_id_tag: settingsDetails?.member_id_tag,
      membership_amount: settingsDetails?.membership_amount,

      club_income_distribution_amount: settingsDetails?.club_income_distribution_amount,
      mint_wallet_distribution_amount: settingsDetails?.mint_wallet_distribution_amount,
      trip_wallet_distribution_amount: settingsDetails?.trip_wallet_distribution_amount,
      total_club_wallet_limit_amount: settingsDetails?.total_club_wallet_limit_amount,
      mint_club_wallet_limit_amount: settingsDetails?.mint_club_wallet_limit_amount,
      trip_club_wallet_limit_amount: settingsDetails?.trip_club_wallet_limit_amount,
      line_income_distribution_amount: settingsDetails?.line_income_distribution_amount,
      future_project_distribution_amount: settingsDetails?.future_project_distribution_amount,
      maintenance_distribution_amount: settingsDetails?.maintenance_distribution_amount,
      refferal_distribution_amount: settingsDetails?.refferal_distribution_amount,
    }),
    [settingsDetails, logoImagePreview]
  );

  const methods = useForm({
    // resolver: yupResolver(NewJobSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    if (settingsDetails) {
      reset(defaultValues);
    }
  }, [settingsDetails, defaultValues, reset]);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);

  const onSubmit = handleSubmit(async (data) => {
    const settingsId = settingsDetails?._id;
    const credentials = {
      state: {
        ...data,
        team_distribution_amount: settingsDetails?.team_distribution_amount,
        account_credentials: {
          ...(data.account_credentials || {}), // Preserve existing values if any
          qr_code: imagesPreview, // Add or overwrite the qr_code field
        },
      },

      settingsId,
      dispatch,
      navigate,
    };
    dispatch(updateSettingsDetails(credentials));
  });

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];

  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });

  //     if (file) {
  //       setValue('image', newFile, { shouldValidate: true });
  //     }
  //   },
  //   [setValue]
  // );

  // const companyDetails = (
  //   <>
  //     {/* {mdUp && (
  //       <Grid md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Company Details
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Modify the Company details in this section
  //         </Typography>
  //       </Grid>
  //     )} */}

  //     <Grid xs={12} md={8}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <Stack spacing={3} sx={{ p: 3 }}>
  //           <Stack spacing={1.5}>
  //             <RHFTextField InputLabelProps={{ shrink: true }} name="company.name" label="Name" />
  //           </Stack>
  //           <Stack spacing={1.5}>
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="company.address"
  //               label="Address"
  //             />
  //           </Stack>
  //           <Stack spacing={1.5}>
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="company.description"
  //               multiline
  //               rows={4}
  //               label="Description"
  //             />
  //           </Stack>
  //           <Typography variant="body2">Company Logo</Typography>
  //           <FileUploadCustom
  //             uploadUrl="settings"
  //             folder="logo"
  //             setImagesPreview={setLogoImagePreview}
  //             imagesPreview={logoImagePreview}
  //             title="Upload Image"
  //             isMultiple
  //             imageSize={4}
  //             theme={{
  //               type: 'standard',
  //               name: 'image',
  //               handleDrop,
  //             }}
  //           />
  //         </Stack>
  //       </Card>
  //     </Grid>
  //   </>
  // );

  const [imagesPreview, setImagesPreview] = useState([]);
  const paymentDetails = (
    <Stack p={2}>
      <Typography variant="body2" color="text.secondary" py={1}>
        QR Code
      </Typography>
      <OspImageUploader
        folder="settings"
        imagesPreview={imagesPreview}
        setImagesPreview={setImagesPreview}
        single
      />
      <Grid container spacing={2} pt={3}>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <Stack spacing={1.5}>
            <RHFTextField
              InputLabelProps={{ shrink: true }}
              name="account_credentials.wallet_id"
              label="Wallet Id"
            />
          </Stack>
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <Stack spacing={1.5}>
            <RHFTextField
              InputLabelProps={{ shrink: true }}
              name="account_credentials.wallet_name"
              label="Wallet Name"
            />
          </Stack>
        </Grid>
      </Grid>
      {/* <Stack spacing={3} sx={{ p: 3 }}>
        <Stack spacing={1.5}>
          <RHFTextField
            InputLabelProps={{ shrink: true }}
            name="account_credentials.wallet_id"
            label="Wallet Id"
          />
        </Stack>
        <Stack spacing={1.5}>
          <RHFTextField
            InputLabelProps={{ shrink: true }}
            name="account_credentials.wallet_name"
            label="Wallet Name"
          />
        </Stack> */}
      {/* <FileUploadCustom
          uploadUrl="settings"
          folder="settings"
          setImagesPreview={setQrImagePreview}
          imagesPreview={qrImagePreview}
          title="Upload Image"
          isMultiple
          imageSize={4}
          theme={{
            type: 'standard',
            name: 'image',
            handleDrop,
          }}
        /> */}
      {/* </Stack> */}
    </Stack>
  );

  // const contactDetails = (
  //   <Box p={3}>
  //     <Grid container spacing={2}>
  //       <Grid item md={6} lg={6} sm={12}>
  //         <Stack spacing={1.5}>
  //           <RHFTextField
  //             name="contact_details.phone"
  //             label="Phone"
  //             InputLabelProps={{ shrink: true }}
  //           />
  //         </Stack>
  //       </Grid>
  //       <Grid item md={6} lg={6} sm={12}>
  //         <Stack spacing={1.5}>
  //           <RHFTextField
  //             name="contact_details.email"
  //             label="Email"
  //             InputLabelProps={{ shrink: true }}
  //           />
  //         </Stack>
  //       </Grid>
  //     </Grid>

  //     <Grid container spacing={2} pt={2}>
  //       <Grid item md={6} lg={6} sm={12}>
  //         <Stack spacing={1.5}>
  //           <RHFTextField
  //             name="contact_details.whatsapp_number"
  //             InputLabelProps={{ shrink: true }}
  //             label="Whatsapp Number"
  //           />
  //         </Stack>
  //       </Grid>
  //       <Grid item md={6} lg={6} sm={12}>
  //         <Stack spacing={1.5}>
  //           <RHFTextField
  //             name="contact_details.website"
  //             InputLabelProps={{ shrink: true }}
  //             label="Website"
  //           />
  //         </Stack>
  //       </Grid>
  //     </Grid>
  //   </Box>
  // );

  const configurations = (
    <Stack spacing={3} sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="club_income_distribution_amount"
            label="Total Club Distribution Amount"
            disabled
          />
        </Grid>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="mint_wallet_distribution_amount"
            label="Mint Wallet Distribution Amount"
          />
        </Grid>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="trip_wallet_distribution_amount"
            label="Trip Wallet Distribution Amount"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="total_club_wallet_limit_amount"
            label="Total Club Wallet Limit Amount"
          />
        </Grid>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="mint_club_wallet_limit_amount"
            label="Mint Club Wallet Limit Amount"
          />
        </Grid>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="trip_club_wallet_limit_amount"
            label="Trip Club Wallet Limit Amount"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="refferal_distribution_amount"
            label="Referral Distribution Amount"
            disabled
          />
        </Grid>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="line_income_distribution_amount"
            label="Line Distribution Amount (static)"
            disabled
          />
        </Grid>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="membership_amount"
            label="Total Membership Amount"
            disabled
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="future_project_distribution_amount"
            label="Future Projects Wallet Distribution Amount"
            disabled
          />
        </Grid>
        <Grid item md={4} lg={4} sm={12} xs={12}>
          <RHFTextField
            type="number"
            InputLabelProps={{ shrink: true }}
            name="maintenance_distribution_amount"
            label="Maintenance Wallet Distribution Amount"
            disabled
          />
        </Grid>
      </Grid>
    </Stack>
  );

  const socialDetails = (
    <Stack spacing={3} sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Stack spacing={1.5}>
            <RHFTextField
              // InputLabelProps={{ shrink: true }}
              name="social_links.whatsApp"
              label="Whatsapp"
            />
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack spacing={1.5}>
            <RHFTextField
              // InputLabelProps={{ shrink: true }}
              name="social_links.whatsApp_channel"
              label="Whatsapp Channel"
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Stack spacing={1.5}>
            <RHFTextField
              // InputLabelProps={{ shrink: true }}
              name="social_links.instagram"
              label="Instagram"
            />
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack spacing={1.5}>
            <RHFTextField
              // InputLabelProps={{ shrink: true }}
              name="social_links.facebook"
              label="Facebook"
            />
          </Stack>
        </Grid>
        <Grid item md={6} pt={2} xs={12}>
          <Stack spacing={1.5}>
            <RHFTextField name="social_links.x" label="X" />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );

  // const commenDetails = (
  //   <>
  //     {mdUp && (
  //       <Grid md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Common
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Common Details
  //         </Typography>
  //       </Grid>
  //     )}

  //     <Grid xs={12} md={8}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <Stack spacing={3} sx={{ p: 3 }}>
  //           <Grid container>
  //             <Grid item md={6} xs={12}>
  //               <Stack spacing={1.5}>
  //                 <RHFTextField
  //                   InputLabelProps={{ shrink: true }}
  //                   name="currency"
  //                   label="Currency"
  //                 />
  //               </Stack>
  //             </Grid>
  //             <Grid item md={6} xs={12}>
  //               <Stack spacing={1.5} pl={!isMobile && 1} pt={isMobile && 2}>
  //                 <RHFTextField
  //                   InputLabelProps={{ shrink: true }}
  //                   name="membership_amount"
  //                   label="Membership Amount"
  //                 />
  //               </Stack>
  //             </Grid>
  //           </Grid>

  //           <Grid container>
  //             <Grid item md={6} xs={12}>
  //               <Stack spacing={1.5}>
  //                 <RHFTextField
  //                   InputLabelProps={{ shrink: true }}
  //                   name="member_id_tag"
  //                   label="Member Id Tag"
  //                 />
  //               </Stack>
  //             </Grid>
  //             <Grid item md={6} pl={!isMobile && 1} xs={12} pt={isMobile && 2}>
  //               <Stack spacing={1.5}>
  //                 <RHFTextField
  //                   InputLabelProps={{ shrink: true }}
  //                   name="member_id_next_value"
  //                   label="Member Id Next"
  //                 />
  //               </Stack>
  //             </Grid>
  //           </Grid>
  //         </Stack>
  //       </Card>
  //     </Grid>
  //   </>
  // );

  // const examDetails = (
  //   <>
  //     {mdUp && (
  //       <Grid md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Exam
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Exam Details
  //         </Typography>
  //       </Grid>
  //     )}

  //     <Grid xs={12} md={8}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <Stack direction="column" spacing={3} sx={{ p: 3 }}>
  //           <Stack spacing={1.5} width="100%">
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="exam_introduction"
  //               label="Exam Introduction"
  //               multiline={4}
  //             />
  //           </Stack>
  //           <Stack spacing={1.5}>
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="question_limit"
  //               label="Questions per page"
  //             />
  //           </Stack>
  //         </Stack>
  //       </Card>
  //     </Grid>
  //   </>
  // );

  const renderActions = (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid rgba(145, 158, 171, 0.08)"
        p={1}
      >
        <Tabs
          className="settings-tabs"
          value={tabChange.value}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: 'none',
            width: '100%',
          }}
        >
          {TAB_OPTIONS.map((tab, key) => (
            <Tab key={key} iconPosition="end" value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        {!isMobile && (
          <Box display="flex" alignItems="center" justifyContent="end">
            <LoadingButton
              type="submit"
              variant="contained"
              size="medium"
              loading={loading}
              sx={{
                ml: 2,
                width: '120px',
                backgroundColor: '#106E58',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#0b4e3e',
                },
              }}
            >
              Save Changes
            </LoadingButton>
          </Box>
        )}
      </Box>
      {isMobile && (
        <Box pt={1} pr={1} display="flex" alignItems="center" justifyContent="end">
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={loading}
            sx={{
              ml: 2,
              width: '120px',
              backgroundColor: '#106E58',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0b4e3e',
              },
            }}
          >
            Save Changes
          </LoadingButton>
        </Box>
      )}
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Card
          variant="outlined"
          sx={{
            width: '100%',
            mx: 1,
            mt: 2,
          }}
        >
          {renderActions}
          {/* {tabChange?.value === 'company' && companyDetails} */}
          {/* {tabChange.value === 'contact' && contactDetails} */}
          {tabChange.value === 'payment' && paymentDetails}
          {tabChange.value === 'social_links' && socialDetails}
          {tabChange.value === 'configuration' && configurations}
        </Card>

        {/* {commenDetails} */}

        {/* {examDetails} */}
      </Grid>
    </FormProvider>
  );
}
