/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { m, AnimatePresence } from 'framer-motion';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop, DndProvider } from 'react-dnd';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
// import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { Tooltip, CardMedia, CircularProgress } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetToken } from 'src/hooks/useHandleSessions';

import close from 'src/assets/other/close.png';
import { activeSnack } from 'src/server/store/common';
import { post, DEV_BASE_URL, TOKEN_PREFIX } from 'src/server/api/http';

import Iconify from 'src/components/iconify';
import { varFade } from 'src/components/animate';

import ImageDeleteError from './ImageDeleteError';

// import ImageCropPopup from './ImageCropPopup';

// ----------------------------------------------------------------------

export default function Upload({
  imagesPreview,
  setImagesPreview,
  extraFunction,
  autoWidth,
  single,
  folder,
}) {
  const dispatch = useDispatch();

  const uploadBaseUrl = `${DEV_BASE_URL}file/admin/upload/${folder}`;
  const deleteBaseUrl = `${DEV_BASE_URL}file/admin/delete/`;
  const imageSize = 2;

  const { token } = useGetToken(TOKEN_PREFIX);

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (bytes === 0) return '0 Byte';
    return {
      size: `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`,
      sizeInKb: Math.round(bytes / 1024),
    };
  }
  const onCropImagePopup = useBoolean(false);
  // const [selectedEditImage, setSelectedEditImage] = useState(null);
  const [fileSizeIssue, setFileSizeIssue] = useState('');
  const [deleteErrorPopup, setDeleteErrorPopup] = useState({
    status: false,
    imageId: '',
  });
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState({
    status: false,
    id: '',
  });

  const handleFileSelect = (acceptedFiles) => {
    // setImageUploadLoading(true);

    const selectedFiles = acceptedFiles;

    const { size, sizeInKb } = bytesToSize(selectedFiles[0]?.size);

    if (sizeInKb >= imageSize * 1024) {
      setFileSizeIssue(`${size} file size is too large - Max Limit ${imageSize}MB`);
    } else {
      setFileSizeIssue('');
      uploadingImage(selectedFiles, 'file');
    }

    // setImageUploadLoading(false);
  };

  const uploadingImage = async (files) => {
    try {
      setImageUploadLoading(true);

      const uploadPromises = Array.from(files).map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async () => {
              if (reader.readyState === 2) {
                try {
                  const base64String = reader.result; // This includes the full "data:image/png;base64," prefix

                  const payload = {
                    file: base64String, // Include the Base64-encoded string
                  };

                  const response = await axios.post(uploadBaseUrl, payload, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json', // Ensure JSON headers
                    },
                  });

                  if (response.data) {
                    resolve(response.data); // Resolve with the response data
                  } else {
                    console.error('Unexpected response:', response);
                    reject(new Error('Upload failed (unexpected response)'));
                  }
                } catch (error) {
                  console.error('Upload error:', error);
                  reject(error); // Reject the promise with the error
                }
              }
            };

            reader.onerror = (error) => {
              console.error('File reading error:', error);
              reject(error); // Reject on file reading error
            };

            reader.readAsDataURL(file); // Read the file as a Base64-encoded data URL
          })
      );

      Promise.all(uploadPromises)
        .then((results) => {
          console.log('All files uploaded:', results);
        })
        .catch((error) => {
          console.error('Error during file upload:', error);
        });

      // Wait for all uploads to complete
      const uploadedImages = await Promise.all(uploadPromises);

      // Update state after successful uploads
      setImagesPreview((prev) => [...prev, ...uploadedImages]);
      dispatch(activeSnack({ type: 'success', message: 'Image uploaded successfully' }));
    } catch (error) {
      console.error('Upload failed:', error);
      dispatch(activeSnack({ type: 'error', message: 'Upload Failed' }));
    } finally {
      setImageUploadLoading(false); // Ensure loading state is reset even on errors
    }
  };

  // const handleReplaceAction = async (imageFile, newImageData) => {
  //   setImagesPreview([...imagesPreview, newImageData]);

  //   const deleteRes = await del(`/resource/${imageFile?.public_id}`, {}, 'resource');

  //   dispatch(activeSnack({ type: 'success', message: 'Uploaded Successfully' }));

  //   if (deleteRes?.isSuccess) {
  //     setTimeout(() => {
  //       setImagesPreview((prev) => prev.filter((img) => img.public_id !== imageFile?.public_id));
  //       onCropImagePopup.onFalse();
  //     }, 1000);
  //   }
  // };

  // const addNewImage = (newImageData) => {
  //   setImagesPreview((prev) => [...(prev || []), newImageData]);
  //   dispatch(activeSnack({ type: 'success', message: 'Uploaded Successfully' }));
  //   onCropImagePopup.onFalse();
  // };

  // const handleError = (error) => {
  //   dispatch(activeSnack({ type: 'error', message: 'File Upload Failed' }));
  //   console.error(error);
  // };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: true,
    onDrop: handleFileSelect,
  });

  const hasFiles = !!imagesPreview && !!imagesPreview.length;

  const hasError = isDragReject;

  const handleRemove = async (id) => {
    try {
      setDeleteLoad({
        status: true,
        id,
      });
      const res = await post(deleteBaseUrl, {
        public_id: id,
      });
      if (
        res?.result === 'ok' ||
        res?.result === 'not found' ||
        res?.message === 'File deleted successfully'
      ) {
        setImagesPreview(imagesPreview?.filter((img) => img.public_id !== id));
        dispatch(activeSnack({ type: 'success', message: 'Image removed' }));
      } else {
        dispatch(activeSnack({ type: 'error', message: 'Image delete fail, try again' }));
      }
      setDeleteLoad({
        status: false,
        id,
      });
      // if (isUpdate && onChangeUpdate) {
      //   onChangeUpdate(id, 'remove');
      // }
    } catch (error) {
      setDeleteLoad({
        status: false,
        id,
      });
      dispatch(activeSnack({ type: 'error', message: 'Image delete fail, try again' }));
    }
  };

  const handleDeleteFromLocal = () => {
    setImagesPreview(imagesPreview?.filter((img) => img.public_id !== deleteErrorPopup?.imageId));
    setDeleteErrorPopup({
      status: false,
      imageId: '',
    });
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...imagesPreview];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImagesPreview(updatedImages);
  };

  const onHandleSelectedEditImage = (image) => {
    // setSelectedEditImage(image);
    onCropImagePopup.onTrue();
  };

  return (
    <Stack>
      {deleteErrorPopup?.status && (
        <ImageDeleteError
          setOpen={setDeleteErrorPopup}
          open={deleteErrorPopup}
          handleDeleteFromLocal={handleDeleteFromLocal}
        />
      )}

      {/* {onCropImagePopup.value && (
        <ImageCropPopup
          open={onCropImagePopup.value}
          onClose={() => onCropImagePopup.onFalse()}
          image={selectedEditImage}
          uploadingImage={uploadingImage}
          uploadLoad={imageUploadLoading}
        />
      )} */}

      <Stack
        // spacing={2}
        sx={{
          mt: single && 0,
          width: 1,
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {hasFiles && (
          <AnimatePresence initial={false}>
            <DndProvider backend={HTML5Backend}>
              <div style={{ display: 'flex', gap: '10px' }}>
                {imagesPreview?.map((image, index) => (
                  <ImageItem
                    key={index}
                    image={image}
                    index={index}
                    moveImage={moveImage}
                    single={single}
                    autoWidth={autoWidth}
                    extraFunction={extraFunction}
                    deleteLoad={deleteLoad}
                    handleRemove={handleRemove}
                    onHandleSelectedEditImage={onHandleSelectedEditImage}
                  />
                ))}
              </div>
            </DndProvider>
          </AnimatePresence>
        )}

        {single && imagesPreview?.length === 1 ? (
          ''
        ) : (
          <Box
            {...getRootProps()}
            sx={{
              p: 5,
              width: autoWidth ? 'auto' : '180px',
              height: autoWidth ? '193px' : '180px',
              outline: 'none',
              borderRadius: 1,
              cursor: 'pointer',
              overflow: 'hidden',
              position: 'relative',
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
              border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
              transition: (theme) => theme.transitions.create(['opacity', 'padding']),
              '&:hover': {
                opacity: 0.72,
              },
              ...(isDragActive && {
                opacity: 0.72,
              }),
              // ...(disabled && {
              //   opacity: 0.48,
              //   pointerEvents: 'none',
              // }),
              ...(hasError && {
                color: 'error.main',
                borderColor: 'error.main',
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
              }),
            }}
          >
            <input {...getInputProps()} />

            <Stack
              height="100%"
              spacing={3}
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              <Stack spacing={0.5} alignItems="center" sx={{ color: 'text.disabled' }}>
                <Iconify icon="eva:cloud-upload-fill" width={40} />
                <Typography variant="body2" textAlign="center">
                  Drop files or click to browse
                </Typography>
                {imageUploadLoading && <CircularProgress size={14} sx={{ mx: 1 }} />}
              </Stack>
            </Stack>
          </Box>
        )}

        {/* <RejectionFiles fileRejections={fileRejections} /> */}
      </Stack>

      <Typography variant="caption" color="red">
        {fileSizeIssue}
      </Typography>
    </Stack>
  );
}

Upload.propTypes = {
  imagesPreview: PropTypes.any,
  setImagesPreview: PropTypes.any,
  extraFunction: PropTypes.any,
  autoWidth: PropTypes.any,
  single: PropTypes.bool,
  folder: PropTypes.any,
};

const ItemType = 'IMAGE';

const ImageItem = ({
  image,
  index,
  moveImage,
  single,
  autoWidth,
  extraFunction,
  deleteLoad,
  handleRemove,
  onHandleSelectedEditImage,
}) => {
  const [,] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [,] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <Box
      // ref={(node) => ref(drop(node))}
      sx={{
        // p: 1,
        // backgroundColor: '#f7f7f8',
        borderRadius: 0,
        p: 0,
      }}
    >
      <Stack
        key={index}
        component={m.div}
        {...varFade().inUp}
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          // my: single ? 0 : 1,
          // py: 1,
          // px: 1.5,
          width: autoWidth ? 'auto' : '180px',
          height: autoWidth ? '193px' : '180px',
          position: 'relative',
          // borderRadius: 1,
          // backgroundColor: '#f7f7f8',
          // cursor: 'grab',
        }}
      >
        <img
          alt={image?.public_id}
          src={image?.url}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            // borderRadius: '25px',
            filter:
              deleteLoad?.status && deleteLoad?.id === image?.public_id ? 'grayscale(1)' : 'none',
          }}
        />

        <Stack
          sx={{
            position: 'absolute',
            top: -8,
            right: -8,
          }}
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          spacing={0.6}
        >
          {/* <Tooltip title="Crop Image">
            <IconButton
              onClick={() => onHandleSelectedEditImage(image)}
              sx={{
                color: '#fff',
                backdropFilter: 'blur( 4px )',
                backgroundColor: 'rgba(0, 0, 0, 0.48)',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.48)' },
              }}
              size="small"
            >
              <CropRotateIcon fontSize="small" sx={{ height: '16px', width: '16px' }} />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title="Edit alt text">
            <IconButton
              onClick={() =>
                extraFunction({
                  imgData: image,
                  status: true,
                })
              }
              sx={{
                color: '#fff',
                backdropFilter: 'blur( 4px )',
                backgroundColor: 'rgba(0, 0, 0, 0.48)',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.48)' },
              }}
              size="small"
            >
              <Iconify icon="solar:pen-bold" width={16} />
            </IconButton>
          </Tooltip> */}
          {deleteLoad?.status && deleteLoad?.id === image?.public_id ? (
            <CircularProgress size={14} sx={{ mx: 1 }} />
          ) : (
            <Tooltip title="Remove image">
              {/* <IconButton
                onClick={() => handleRemove(image?.public_id)}
                sx={{
                  color: '#fff',
                  border: '1px solid rgba(196, 205, 213, 1)',
                  backdropFilter: 'blur( 4px )',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
                }}
                size="small"
              > */}
              {/* <Iconify
                  icon="material-symbols:close"
                  width={25}
                  sx={{
                    color: 'rgba(255, 47, 47, 1)',
                  }}
                /> */}
              <CardMedia
                onClick={() => handleRemove(image?.public_id)}
                src={close}
                alt
                component="img"
                height={40}
                width={40}
                sx={{
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
              />
              {/* </IconButton> */}
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

ImageItem.propTypes = {
  image: PropTypes.any,
  index: PropTypes.any,
  moveImage: PropTypes.any,
  single: PropTypes.any,
  autoWidth: PropTypes.any,
  extraFunction: PropTypes.any,
  deleteLoad: PropTypes.any,
  handleRemove: PropTypes.func,
  onHandleSelectedEditImage: PropTypes.func,
};
