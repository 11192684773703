import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import TableContainer from '@mui/material/TableContainer';
import {
  Box,
  Grid,
  Paper,
  Alert,
  TableRow,
  TableCell,
  CardMedia,
  Typography,
  CircularProgress,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import img1 from 'src/assets/other/W2W-img1.png';
import img3 from 'src/assets/other/W2W-img2.png';
import img2 from 'src/assets/other/W2W-img3.png';
import { getBeneficiaryMembers } from 'src/server/api/member';
import { getAllMemberW2WTransfer } from 'src/server/api/W2WTransfer';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import MemberW2WToolbar from 'src/sections/W2WTransfer/member/MemberW2WTransferToolbar';
import ActivateNewMember from 'src/sections/W2WTransfer/member/Activate-new-member-modal';
import W2WTransferCreateModal from 'src/sections/W2WTransfer/member/create-transfer-modal';
import W2WTransferTableRow from 'src/sections/W2WTransfer/member/MemberW2WTransferTableRow';
import W2WTransferSuccessModal from 'src/sections/W2WTransfer/member/transfer-success-modal';
import ActivateNewMemberSuccessModal from 'src/sections/W2WTransfer/member/Activate-member-success-modal';

// ----------------------------------------------------------------------

export default function W2WTransferListMember({ memberSingle }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const role = useGetRoles()?.role;
  const router = useRouter();
  const { themeMode } = useSettingsContext();

  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: role === 'admin' ? 25 : 10,
  });

  const confirm = useBoolean();
  // const isMobile = useResponsive('down', 'md');
  const [sort] = useState('');
  const [date, setDate] = useState('');

  const {
    loading,
    allW2WTransfer,
    membersList,
    // memberWalletAddress: memberWalletAddressDetails,
    // memberWalletAddressList,
  } = useSelector((state) => ({
    loading: state.W2WTransfer.loading,
    allW2WTransfer: state?.W2WTransfer?.w2wTransferMember,
    memberProfileDetails: state.member.memberProfileDetails.member,
    settingsData: state.settings.settingsDetails,
    memberWalletAddress: state.walletAddress?.walletAddresses?.memberWalletAddresses,
    memberWalletAddressList: state.withdrawalRequest?.memberWalletAddressList,
    membersList: state.member?.beneficiaryMembers?.members,
  }));

  const identifier = 'transfer';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
  const [searchText, setSearchText] = useState();
  const [tableData, setTableData] = useState([]);
  const totalPage = allW2WTransfer?.total;
  const [selectedMember, setSelectedMember] = useState('');
  const [searchMember, setSearchMember] = useState('');

  const TABLE_HEAD = [
    { id: 'created', label: 'Date' },
    { id: 'member_id', label: 'Transfer ID' },
    // { id: 'name', label: 'Member' },
    { id: 'name', label: 'Beneficiary Details' },
    { id: 'amount', label: 'Amount' },
    { id: 'particulars', label: 'Particulars' },
    // { id: 'remarks', label: 'Remarks' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: '' },
  ];

  // const handleChangeSortOrder = (value) => {
  //   setSortOrder(value);
  // };

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: memberSingle ? 5 : storedRowsPerPage || table.rowsPerPage,
      sort,
      date,
      search: searchText || '',
      dispatch,
      beneficiary_member: selectedMember?.value,
      // member: memberSingle,
      // sort_order: sortOrder,
    };

    dispatch(getAllMemberW2WTransfer(credentials));
    // }
  }, [
    date,
    dispatch,
    searchText,
    sort,
    role,
    storedRowsPerPage,
    table.page,
    table.rowsPerPage,
    memberSingle,
    selectedMember?.value,
  ]);
  // console.log(searchText);
  useEffect(() => {
    setTableData(allW2WTransfer?.walletTransfers);
  }, [allW2WTransfer]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  // const getWidth = () => {
  //   if (memberSingle) {
  //     return '100%';
  //   }
  //   if (isMobile) {
  //     return '100%';
  //   }
  //   return '90%';
  // };

  const setPage = () => {
    table.setPage(0);
  };

  useEffect(() => {
    const data = {
      page: '',
      search: searchMember,
      dispatch,
      limit: '',
      sort: 'VERIFIED',
    };
    dispatch(getBeneficiaryMembers(data));
  }, [dispatch, role, searchMember]);

  const optionMembers = [
    {
      label: 'All',
      value: '',
      member_id: '', // Provide an empty string to avoid "undefined"
      name: 'All', // Set a name for "All"
      username: '', // Provide an empty string to avoid "undefined"
    },
    ...(membersList || []).map((item) => ({
      name: item?.name,
      member_id: item?.member_id,
      email: item?.user?.email,
      phone: item?.user?.phone,
      username: item?.user?.username,
      label: item?.name,
      // label: `${item?.name} - ${item?.member_id}`,
      value: item?._id,
    })),
  ];
  const handleTextFieldChangeMember = (event) => {
    setSearchMember(event.target.value);
    setPage();
  };

  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedMember(newValue || '');
    setPage();
  };
  const [openActivateMemberDialog, setOpenActivateMemberDialog] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [openTransferCreateDialog, setOpenTransferCreateDialog] = useState(false);
  const [openTransferCreateSuccessModal, setOpenTransferCreateSuccessModal] = useState(false);

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    const credentials = {
      page: table.page + 1,
      limit: table.rowsPerPage || 10,
      sort,
      date,
      search: searchText || '',
      dispatch,
      beneficiary_member: selectedMember?.value,
      // member: memberSingle,
      // sort_order: sortOrder,
    };

    dispatch(getAllMemberW2WTransfer(credentials));
  };
  const handleCloseCreateSuccessModal = () => {
    setOpenTransferCreateSuccessModal(false);
    const credentials = {
      page: table.page + 1,
      limit: 10 || table.rowsPerPage,
      sort,
      date,
      search: searchText || '',
      dispatch,
      beneficiary_member: selectedMember?.value,
      // member: memberSingle,
      // sort_order: sortOrder,
    };

    dispatch(getAllMemberW2WTransfer(credentials));
  };

  return (
    <>
      {openSuccessModal && (
        <ActivateNewMemberSuccessModal
          setOpen={() => setOpenSuccessModal()}
          open={openSuccessModal}
          handleClose={handleCloseSuccessModal}
        />
      )}
      {openActivateMemberDialog && (
        <ActivateNewMember
          open={openActivateMemberDialog}
          setOpen={() => setOpenActivateMemberDialog()}
          setOpenSuccessModal={setOpenSuccessModal}
        />
      )}
      {openTransferCreateSuccessModal && (
        <W2WTransferSuccessModal
          setOpen={() => setOpenTransferCreateSuccessModal()}
          open={openTransferCreateSuccessModal}
          handleClose={handleCloseCreateSuccessModal}
        />
      )}
      {openTransferCreateDialog && (
        <W2WTransferCreateModal
          open={openTransferCreateDialog}
          setOpen={() => setOpenTransferCreateDialog()}
          setOpenSuccessModal={setOpenTransferCreateSuccessModal}
        />
      )}
      <CustomBreadcrumbs
        heading="W2W Transfers"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'W2W Transfer',
          },

          {
            name: 'List',
          },
        ]}
        action={
          <Button
            variant="outlined"
            // color="success"
            onClick={() => setOpenActivateMemberDialog(true)}
            sx={{
              color: '#007867',
              boxShadow: '0px 1px 2px 0px #00000040',
            }}
          >
            Activate New Member
          </Button>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack
        spacing={3}
        // width={isMobile ? '100%' : '80%'}
        // direction={role !== 'admin' ? 'row' : 'column'}
        // direction={role === 'admin' || (role !== 'admin' && isMobile) ? 'column' : 'row'}
        // width={
        //   role === 'admin'
        //     ? getWidth()
        //     : '100%                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         '
        // }
      >
        <Box sx={{ p: 0 }}>
          <Grid container spacing={3}>
            {/* {loading ? (
              <Skeleton variant="rectangular" width="100%" height={200} sx={{ borderRadius: 2 }} />
            ) : ( */}
            <>
              <Grid item md={2.55} lg={2.55} xs={12}>
                <Stack
                  sx={{
                    backgroundColor: themeMode === 'dark' ? '#212B36' : '#00A76F14',
                    borderRadius: '10px',
                    p: 2,
                  }}
                >
                  <Typography
                    fontSize="14px"
                    color={themeMode === 'dark' ? '' : '#212B36'}
                    mb={1}
                    pt={2}
                  >
                    Total Transfers
                  </Typography>
                  <Typography
                    fontSize="30px"
                    fontWeight={700}
                    color={themeMode === 'dark' ? '' : '#212B36'}
                    mb={3}
                  >
                    {allW2WTransfer?.total || 0}
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="center">
                    <Button
                      onClick={() => setOpenTransferCreateDialog(true)}
                      sx={{
                        backgroundColor: '#007867',
                        color: 'white',
                        width: '100%',
                        borderRadius: '25px',
                        py: 1,
                        '&:hover': {
                          backgroundColor: '#005f4c',
                        },
                      }}
                      startIcon={<SyncAltIcon />}
                    >
                      Transfer
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              {[
                {
                  title: 'Transfer made easy',
                  description: 'Enable seamless transactions within Winbuks community members.',
                  image: img1,
                },
                {
                  title: 'Secure Transfers',
                  description: 'Safety you can trust, anytime, anywhere.',
                  image: img2,
                },
                {
                  title: 'Faster transfers, always',
                  description: 'Experience lightning-fast transfers that save you time.',
                  image: img3,
                },
              ].map((feature, index) => (
                <Grid item md={3.15} lg={3.15} xs={12} key={index} pr={4}>
                  <Stack alignItems="">
                    <CardMedia
                      component="img"
                      src={feature.image}
                      sx={{
                        height: '90px',
                        width: '90px',
                        objectFit: 'contain',
                        mb: 1.5,
                      }}
                    />
                    <Typography
                      fontSize="18px"
                      fontWeight={700}
                      color={themeMode === 'dark' ? '' : '#212B36'}
                      pb={1}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      fontSize="13px"
                      fontWeight={400}
                      color={themeMode === 'dark' ? '' : '#212B36'}
                      lineHeight="22px"
                      pt={0}
                    >
                      {feature.description}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </>
            {/* )} */}
          </Grid>
        </Box>

        <Card variant="outlined" sx={{ boxShadow: 'unset', flex: 7 }}>
          <Stack pt={2} px={2}>
            <Typography variant="h6">Transfer History</Typography>
          </Stack>
          {/* {!memberSingle && (
            <Stack p={2}>
              <Typography variant="h6">
                Withdrawal requests ({dataList?.withdrawalRequestCounts?.allWithdrawalRequestCount})
              </Typography>
            </Stack>
          )} */}
          {/* {role === 'admin' && (
            <WithdrawalRequestSort
              setPage={setPage}
              sort={sort}
              setSort={setSort}
              dataList={dataList}
            />
          )} */}

          <Stack mx={2} my={3}>
            <MemberW2WToolbar
              setPage={setPage}
              onSearch={handleSearch}
              date={date}
              setDate={setDate}
              optionMembers={optionMembers}
              handleAutocompleteChangeMember={handleAutocompleteChangeMember}
              handleTextFieldChangeMember={handleTextFieldChangeMember}
            />
          </Stack>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {(tableData || []).map((row, index) => (
                      <W2WTransferTableRow
                        index={index}
                        page={table.page + 1}
                        rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                        key={row.id}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        loading={loading}
                        colSpan={TABLE_HEAD?.length}
                        memberSingle={memberSingle}
                      />
                    ))}
                  </TableBody>
                )}

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            customRowsPerPage={role === 'admin' && !memberSingle && 10}
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

W2WTransferListMember.propTypes = {
  memberSingle: PropTypes.string,
};

const WithdrawalNotification = ({ color, message }) => (
  <Stack mx={2} mb={2}>
    <Alert
      className="alert-custom"
      icon={<Iconify icon="material-symbols:info" sx={{ color: color || '#7A4100' }} />}
      sx={{
        bgcolor: '#F8F6F2',
        color: '#7A4100',
        borderRadius: '10px',
        display: 'inline-flex',
        alignItems: 'center',
        p: 0.5,
        px: 1,
        mb: 1,
        width: '100%',
      }}
    >
      <Typography variant="caption" color="#7A4100" sx={{ fontWeight: 500, lineHeight: '1px' }}>
        {message}{' '}
      </Typography>
    </Alert>
  </Stack>
);

WithdrawalNotification.propTypes = {
  color: PropTypes.string,
  message: PropTypes.string,
};
