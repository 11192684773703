import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { Box, Stack } from '@mui/system';
import { Alert, Button, Tooltip, Typography, CircularProgress } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import Iconify from 'src/components/iconify';

import TimerComponent from 'src/sections/Member/timerComponent';

const WithdrawalSection = ({ setConfirmDialog }) => {
  const isMobile = useResponsive('down', 'md');

  const [dateCheck, setDateCheck] = useState(false);
  console.log('dateCheck', dateCheck);

  const { memberProfileDetails, memberWalletAddressList } = useSelector((state) => ({
    memberWalletAddressList: state.withdrawalRequest?.memberWalletAddressList,
    memberProfileDetails: state.member.memberProfileDetails.member,
  }));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleShowButton = () => {
    if (
      memberProfileDetails?.buks_wallet_balance > 0 &&
      dateCheck &&
      memberWalletAddressList?.memberWalletAddresses?.length &&
      !memberProfileDetails?.is_withdrawal_suspended
    ) {
      return true;
    }
    return false;
  };

  return (
    <Stack sx={{ position: 'relative' }}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 9,
            background: '#fff',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size="30px" color="success" />
        </Box>
      )}

      {handleShowButton() ? (
        <Stack
          mx={2}
          mb={4}
          justifyContent="end"
          alignItems="center"
          flexDirection={isMobile ? 'row' : 'column'}
        >
          <Tooltip
            title={
              memberProfileDetails?.buks_wallet_balance === 0 && 'Wallet empty. Request denied.'
            }
          >
            <Button
              onClick={() => {
                setConfirmDialog(true);
              }}
              size="large"
              variant="contained"
              sx={{
                width: '100%',
                backgroundColor: '#007867',
                color: 'white',
                '&:hover': { backgroundColor: '#007867', color: 'white' },
              }}
            >
              Create withdrawal request{' '}
            </Button>
          </Tooltip>
        </Stack>
      ) : (
        ''
      )}

      {memberProfileDetails?.buks_wallet_balance <= 0 && (
        <WithdrawalNotification message="Withdrawal requests cannot be made with 0 wallet balance." />
      )}

      {memberProfileDetails?.is_withdrawal_suspended && (
        <WithdrawalNotification message="Withdrawal has been suspended. Please contact the admin." />
      )}

      {!dateCheck && memberWalletAddressList?.memberWalletAddresses?.length > 0 && (
        <WithdrawalNotification
          message={
            <>
              Since you&apos;ve updated your wallet address, withdrawals will be blocked for the
              next{' '}
              <TimerComponent
                setLoading={setLoading}
                setDateCheck={setDateCheck}
                updatedAt={moment(
                  memberProfileDetails?.wallet_address_last_updated_at
                ).toISOString()}
              />{' '}
            </>
          }
        />
      )}

      {memberWalletAddressList?.memberWalletAddresses?.length <= 0 && (
        <WithdrawalNotification message="Withdrawal requests cannot be made without a wallet address." />
      )}
    </Stack>
  );
};

export default WithdrawalSection;

WithdrawalSection.propTypes = {
  setConfirmDialog: PropTypes.string,
};

const WithdrawalNotification = ({ color, message }) => (
  <Stack mx={2} mb={2}>
    <Alert
      className="alert-custom"
      icon={<Iconify icon="material-symbols:info" sx={{ color: color || '#7A4100' }} />}
      sx={{
        bgcolor: '#F8F6F2',
        color: '#7A4100',
        borderRadius: '10px',
        display: 'inline-flex',
        alignItems: 'center',
        p: 0.5,
        px: 1,
        mb: 1,
        width: '100%',
      }}
    >
      <Typography variant="caption" color="#7A4100" sx={{ fontWeight: 500, lineHeight: '1px' }}>
        {message}{' '}
      </Typography>
    </Alert>
  </Stack>
);

WithdrawalNotification.propTypes = {
  color: PropTypes.string,
  message: PropTypes.string,
};
